export default {
  data() {
    return {
      validHtmlTypes: ['.html'],
      validImageTypes: ['.jpg', '.jpeg', '.png', '.gif'],
      validDocTypes: ['.doc', '.xls', '.docx', '.xlsx', '.pdf', '.msg', '.txt', '.ppt', '.pptx', '.tiff', '.csv', '.xlsm', '.zip'],
      validVideoTypes: ['.mov', '.mp4'],
    };
  },
  methods: {
    isAllowedFileType(file) {
      return [
        ...this.validHtmlTypes,
        ...this.validImageTypes,
        ...this.validDocTypes,
        ...this.validVideoTypes,
      ].includes(this.getFileExtension(file));
    },
    getFileExtension(file) {
      const validType = `.${file.name.split('.').pop()}`;
      return validType.toLowerCase();
    },
  },
};
