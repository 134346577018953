<template>
  <div class="area position-relative">
    <div
      class="drop-area"
      :class="{'drop-area--active': isDragActive}"
      data-tc-attachment
      @click.stop="onChange"
      @dragleave="onDragLeave"
      @dragover.prevent="onDragEnter"
      @drop="onDrop"
    >
      <label class="drop__label text-center not-as-small">
        Drop files here or click to upload
        <span
          v-if="showLabel"
          class="drop__sub-label mt-3 d-block"
        >Max file size 25mb.</span>
      </label>
      <input
        ref="fileInput"
        :key="componentKey"
        v-validate="'required'"
        type="file"
        class="drop__file-input"
        name="file"
        multiple
        :accept="acceptedExt"
        :disabled="disabled"
        data-tc-uploading-input-attachments
        @change="onFileChange"
      >
    </div>
    <div
      v-if="isLoading"
      class="bg-themed-box-bg d-flex flex-column p-3 position-absolute text-center top-0 w-100 border upload-state"
    >
      <h6>Please wait a few moments while we process the file.</h6>
      <clip-loader
        :is-loading="isLoading"
        class="mt-1"
        color="#0d6efd"
        size="2rem"
      />
    </div>
  </div>
</template>

<script>
  import fileValidator from "mixins/file_validator";
  import permissionsHelper from "mixins/permissions_helper";
  import { mapGetters, mapActions } from 'vuex';
  import ClipLoader from "./clip_loader_smooth.vue";

  export default {
    components: {
      ClipLoader,
    },
    mixins: [
      fileValidator,
      permissionsHelper,
    ],
    props: {
      showLabel: Boolean,
      accept: {
        type: Array,
        default() {
          return [];
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      fetchAllowedTypes: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        files: [],
        inputError: null,
        isDragActive: false,
        acceptedExt: [],
        componentKey: 0,
      };
    },
    computed: {
      ...mapGetters(['validExtensions']),
    },
    methods: {
      ...mapActions(['fetchValidExtensions']),
      onWorkspaceChange() {
        if (this.fetchAllowedTypes && !this.validExtensions.length) {
          this.fetchValidExtensions().then((ext) => this.acceptedFiles(ext));
        } else {
          this.acceptedFiles(this.accept);
        }
      },
      onDrop(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.disabled) { return; }

        this.processFiles(e.dataTransfer.files);
      },
      onChange() {
        this.$refs.fileInput.click();
        this.inputError = null;
      },
      onFileChange(e) {
        if (!e.currentTarget || this.disabled) { return; }

        this.processFiles(this.$refs.fileInput.files);

        this.$refs.fileInput.value = null;
      },
      onDragEnter() {
        this.isDragActive = true;
      },
      onDragLeave() {
        this.isDragActive = false;
      },
      processFiles(files) {
        Array.from(files).forEach((file) => {
          if (this.acceptedExt?.length === 0 || this.acceptedExt.includes(this.getFileExtension(file))) {
            this.$emit('input', file);
          } else {
            this.emitError('Invalid file format.');
          }
        });
      },
      acceptedFiles(accept) {
        if (this.fetchAllowedTypes) {
          this.acceptedExt.push(...this.validExtensions);
          this.componentKey += 1;
          return;
        }

        if (accept.includes('images')) {
          this.acceptedExt.push(...this.validImageTypes);
        }
        if (accept.includes('docs')) {
          this.acceptedExt.push(...this.validDocTypes);
        }
        if (accept.includes('html')) {
          this.acceptedExt.push(...this.validHtmlTypes);
        }
        if (accept.includes('videos')) {
          this.acceptedExt.push(...this.validVideoTypes);
        }

        this.componentKey += 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #dropzone {
    span {
      cursor: pointer;
      color: $cyan;
    }
  }

  .dz-details {
    background: $themed-box-bg;
    border: 1px dotted #e7e7e7;
    border-radius: $border-radius;
    font-style: italic;
    padding: 5px;
  }

  .drop__file-input {
    visibility: hidden;
  }

  .drop__label {
    color: $themed-secondary;
    cursor: pointer;
    left: 50%;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85%;

    > a {
      text-decoration: underline;
    }
  }

  .drop__sub-label {
    font-size: 80%;
    text-transform: none;
  }

  .drop__img {
    height: 100%;
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  .drop-area {
    background-color: $themed-box-bg;
    border: 2px dashed $themed-fair;
    border-radius: $border-radius;
    color: white;
    cursor: pointer;
    height: inherit;
    width: inherit;
    margin: auto;
    overflow: hidden;
    position: relative;
    transition: $transition-base;
    transition-property: border-color;
    vertical-align: middle;

    &:hover {
      border-color: $gray-500;
    }
  }

  .drop-area--active {
    border-color: $themed-link;
  }

  .area {
    height: 6.25rem;
    .upload-state {
      top: 0;
      border-radius: 0.5rem;
    }
  }
</style>
