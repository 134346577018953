
const defautStates = {
  mfaSettings() {
    return {
      timeoutPeriod: null,
      companyId: null,
      mfaEnabled: null,
      rememberDevice: null,
      enabledForSelfUser: null,
      enabledForAllAdmins: null,
      enabledForAllUsers: null,
      enabledForGroups: null,
      emailAuth: false,
      msgAuth: true,
      restrictUserAccess: false,
    };
  },
  qrCodeSettings() {
    return {
      printAssetTag: true,
      printAssetName: true,
      printCompanyName: true,
      printCompanyLogo: false,
      labelText: '',
      labelFontSize: '28',
      labelHeight: '2.5',
      labelWidth: '7.0',
      labelMarginTop: '0.25',
      labelMarginLeft: '0.0',
      pageSize: 'A4',
      pageMarginTop: '0.15',
      pageMarginLeft: '0.15',
      pageOrientation: 'portrait',
      numberOfColumns: '1',
    };
  },
};

export default defautStates;
