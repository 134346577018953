export default {
  data() {
    return {
      telecom: {
        DISABLED_DRAG: ['name'],
        DISABLED_PRIVATE: ['name'],
        DISABLED_PERMISSIONS: ['name'],
        DISABLED_DELETE: ['name'],
        HEADER_SECTION: ['name'],
        COMPULSORY_FIELDS: ['name'],
        DISABLED_REQUIRED: [],
      }
    }
  },
  computed: {
    isTelecomModule() {
      return this.companyModule == 'telecom';
    },
  },
};
