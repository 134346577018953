<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="articleObj">
    <div
      class="row feed-item"
      :class="{'mb-2': compact, 'mb-3': !compact}"  
    >
      <div class="col-12">
        <div
          v-if="!hideTitle"
          class="d-flex align-items-center"
        >
          <h5
            class="text-themed-base mb-1 mr-4"
            :class="{
              'truncate article-title-width-restricted': truncateTitle,
              'h4': !compact,
            }"
            :data-tc-view-article="articleObj.title"
          >
            {{ articleObj.title }}
          </h5>
        </div>
        <span
          v-for="tag in articleObj.tags"
          :key="tag"
          class="badge badge-bg text-white mr-2"
          :data-tc-view-article-tags="tag"
        >
          {{ tag }}
        </span>
      </div>
    </div>
    <p
      class="mt-1 p--responsive text-secondary text-wrap article-description"
      :class="{
        'truncate-body': truncateBody,
        'not-as-small': compact,
        'base-font-size': !compact,
      }"
      :data-tc-otp-article-body="articleObj.body"
      v-html="sanitizeHTML(articleObj.body)"
    />
  </div>
</template>

<script>
import strings from 'mixins/string';

export default {
  mixins: [strings],
  props: {
    articleObj: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    truncateTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    truncateBody: {
      type: Boolean,
      default: false,
      required: false,
    },
    compact: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .article-title-width-restricted {
    max-width: 600px;
  }

  .truncate-body {
    max-height: 3.75rem;
    overflow: hidden;
  }

  .badge-bg {
    background-color: $info;
    background-color: rgba($info, .8);
    border: 1px solid $info;
  }

  .handle {
    cursor: move;
    font-size: 1.125rem;
    opacity: 0;
    visibility: hidden;
  }

  .feed-item {
    &:hover {
      .handle {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .article-description {
    :deep(ul) {
      list-style-type: disc;
      padding-left: 15px;
    }
  }
</style>
