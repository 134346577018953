
export default {
  computed: {
    rootDomain() {
      const { hostname } = document.location;
      const idx = hostname.indexOf(".");
      return hostname.substring(idx + 1);
    },
  },
  methods: {
    currentDomain() {
      const company = getCompanyFromStorage();
      if (!company) { return null; }

      return `${company.subdomain}.${this.rootDomain}`;
    },
    parseUsername(email) {
      const idx = email.indexOf("@");
      return email.substring(0, idx);
    },
    parseDomain(email) {
      const idx = email.indexOf("@");
      return email.substring(idx + 1);
    },
  },
};
