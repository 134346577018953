<template>
  <div>
    <input
      ref="input"
      class="hidden"
      type="list"
      name="list"
      :required="required"
      :value="valueStr"
    >
    <multi-select
      v-model="valueStr"
      :required="required"
      :options="options"
      :disabled="disabled"
      @select="emitValue"
      @remove="deleteListValue"
    />
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import _get from 'lodash/get';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import MultiSelect from 'vue-multiselect';

export default {
  components: {
    MultiSelect,
  },
  mixins: [fieldViewerInput, permissionsHelper],
  data() {
    return {
      errors: null,
      valueStr: _get(this, "value", ""),
    };
  },
  computed: {
    options() {
      return this.field.options || [];
    },
  },
  methods: {
    onWorkspaceChange() {
      this.setValueStr();
    },
    emitValue(value) {
      this.errors = null;
      this.valueStr = value;
      this.$emit("input", this.valueStr);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.valueStr) {
        msg = `Please select a ${this.field.label}`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
    deleteListValue(value) {
      this.$emit("input", null);
      this.$emit('delete', value);
    },
  },
};
</script>
