<template>
  <div 
    v-show="loading" 
    class="v-spinner"
  >
    <div
      class="v-clip"
      :style="clipStyle"
    />
  </div>
</template>

<script>
export default {
  
  name: 'ClipLoaderSmooth',

  props: {
    loading: {
      type: Boolean,
      default: true
    },
  	color: { 
      type: String,
      default: '#0d6efd'
    },
  	size: {
      type: String,
      default: '32px'
    },
    radius: {
      type: String,
      default: '100%'
    },
    borderWidth: {
      type: Number,
      default: 2
    },
  },
  computed: {
    clipStyle () {
      return {
        background: 'transparent',
        borderColor: this.color + ' ' + this.color + ' transparent',
        borderStyle: 'solid',
        borderRadius: this.radius,
        borderWidth: `${this.borderWidth}px`,
        height: this.size,
        width: this.size
      }
    }
  }
}
</script>

<style scoped>
.v-spinner
{
  text-align: center;
}

.v-spinner-right {
  right: 0.5rem;
}

.v-spinner .v-clip
{
  display: inline-block;

  -webkit-animation: v-clipDelay 1.25s 0s infinite linear;
          animation: v-clipDelay 1.25s 0s infinite linear;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes v-clipDelay
{
  0%
  {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  
  100%
  {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes v-clipDelay
{
  0%
  {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100%
  {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
</style>
