<template>
  <div>
    <div :class="{ 'mt-3': !isStaffNewPage }">
      <div class="pl-0">
        <div class="container pl-0 mt-2 mb-n2 pr-0">
          <div
            v-if="canManage"
            class="d-flex"
          >
            <groups-select
              name="name"
              :value="selectedGroup"
              :excludes="addedGroupIds"
              compact
              @select="groupSelected"
              @remove="groupRemoved"
            />
            <div
              v-if="!isStaffNewPage"
              class="group-buttons ml-3"
            >
              <sync-loader
                v-if="isSaving"
                :loading="true"
                class="float-left"
                color="#0d6efd"
                size="0.5rem"
              />
              <button
                class="btn btn-primary"
                :disabled="noGroupsSelected"
                @click="submitGroup"
              >
                <i class="nulodgicon-plus-round mr-2" />Add
              </button>
            </div>
          </div>
          <div
            v-if="groups && groups.length > 0"
            class="row mt-3 ml-0"
          >
            <div
              v-for="group in groups"
              :key="group.id"
              class="col-sm-12 col-lg-6 col-xl-4 mb-3 rounded"
            >
              <a 
                :href="`/company/groups/${group.id}/edit`"
                class="row mr-0 box box--with-hover box--flat box--natural-height p-2"
              >
                <div
                  v-if="group.name"
                  class="col-sm-1"
                >
                  <avatar
                    :size="25"
                    :username="group.name"
                    class="d-inline-block float-left "
                  />
                </div>
                <span class="col ml-1 p-3 d-inline-block text-truncate">
                  {{ group.name }}
                </span>
                <a
                  v-if="canManage"
                  v-tooltip="`Remove user from group`"
                  href="#"
                  class="action-link col-auto mt-3 mb-3"
                  @click.stop.prevent="removeGroup(group)"
                >
                  <i class="nulodgicon-trash-b text-danger-light" />
                </a>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Avatar } from 'vue-avatar';
  import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
  import GroupsSelect from 'components/shared/groups_select.vue';

  export default {
    components: { 
      Avatar ,
      SyncLoader,
      GroupsSelect,
    },
    props: {
      groups: {
        required: true,
        type: Array,
        default: () => [],
      },
      canManage: {
        required: false,
        type: Boolean,
        default: false,
      },
      isSaving: {
        required: false,
        type: Boolean,
        default: false,
      },
      selectedGroup: {
        required: false,
        type: Object,
        default: () => {},
      },
      isStaffNewPage: {
        required: false,
        type: Boolean,
        default: false,
      }
    },
    computed: {
      noGroupsSelected() {
        return !this.selectedGroup.id;
      },
      checkUserExistInGroup() {
        return this.groups && this.groups.some(grp => grp.id === this.selectedGroup.id);
      },
      addedGroupIds() {
        return this.groups.map(group => group.id);
      },
    },

    methods: {
      submitGroup() {
        if (this.checkUserExistInGroup) {
          this.emitError(`Teammate already exists in group ${this.selectedGroup.name}`);
        } else {
          this.$emit("add-to-group");
        }
      },
      groupSelected(group) {
        this.$emit("group-selected", group);
      },
      groupRemoved() {
        this.$emit("group-removed");
      },
      removeGroup(group) {
        if (group.name === "Admins" && group.memberCount === 1) {
          this.emitError('Sorry, you cannot remove the last admin.');
        } else {
          this.$emit('open-remove-modal', group);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .group {
    background-color: $themed-box-bg;
    border-radius: 0.25rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 
                0 2px 1px -1px rgba(0, 0, 0, 0.12), 
                0 1px 3px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 
                  0 3px 14px 2px rgba(0, 0, 0, 0.12), 
                  0 5px 5px -3px rgba(0, 0, 0, 0.20);
    }

    a {
      color: $themed-base;
    }

    .vue-avatar--wrapper {
      margin-top: 15px;
    }
  }
  .group-buttons {
    min-width: 80px;
  }
</style>
