var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.options),function(opt,index){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
      content: opt,
      boundariesElement: 'body',
      show: (opt && opt.length > 20 && _vm.hoveredIndex == index),
      trigger: 'manual',
      classes: 'tooltip-width',
    }),expression:"{\n      content: opt,\n      boundariesElement: 'body',\n      show: (opt && opt.length > 20 && hoveredIndex == index),\n      trigger: 'manual',\n      classes: 'tooltip-width',\n    }",modifiers:{"right":true}}],key:index,staticClass:"d-flex",on:{"mouseover":function($event){_vm.hoveredIndex = index},"mouseleave":function($event){_vm.hoveredIndex = null}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueStr),expression:"valueStr"}],ref:"input",refInFor:true,attrs:{"id":`radiobtn-${index}`,"type":"radio"},domProps:{"value":opt,"checked":_vm._q(_vm.valueStr,opt)},on:{"change":[function($event){_vm.valueStr=opt},_vm.emitValue]}}),_vm._v(" "),_c('label',{staticClass:"readable-length--x-small ml-1 mb-0 truncate",attrs:{"for":`radiobtn-${index}`}},[_vm._v(_vm._s(opt))])])}),_vm._v(" "),(_vm.errors)?_c('span',{staticClass:"form-text small text-danger"},[_vm._v("\n    "+_vm._s(_vm.errors)+"\n  ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }