import { parsePhoneNumber } from "libphonenumber-js";

export default {
  methods: {
    processPhoneNumber(number, countryCode, countryCodeNumber) {
      if (!number) {
        return "";
      }
      const code = countryCode || "US";
      const countryNumber = `+${(countryCodeNumber || 1)}`;
      const phoneNumber = parsePhoneNumber(`${countryNumber}${number}`, code);
      if (code === "US") {
        return phoneNumber.formatNational();
      }
      return phoneNumber.formatInternational();
    },
    formatPhoneNumber(number, countryCode, countryCodeNumber) {
      if (countryCode == "US") {
        return this.processPhoneNumber(number, countryCode, countryCodeNumber);
      }
      return number;
    },
    stripFormattedNumber(phoneNumber) {
      return Number(phoneNumber.replace(/\D/g, ""));
    },
  },
};
