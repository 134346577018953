var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.faqObj)?_c('div',[_c('div',{staticClass:"row",class:{'mb-2': _vm.compact, 'mb-3': !_vm.compact}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"text-themed-base mb-1 mr-4",class:{
            'truncate article-title-width-restricted': _vm.truncateTitle,
            'h4': !_vm.compact,
          },attrs:{"data-tc-view-article":_vm.faqObj.questionBody},domProps:{"innerHTML":_vm._s(_vm.sanitizeHTML(_vm.faqObj.questionBody))}})])])]),_vm._v(" "),_c('p',{staticClass:"mt-1 p--responsive text-secondary text-wrap article-description",class:{
      'truncate-body article-title-width-restricted': _vm.truncateBody,
      'not-as-small': _vm.compact,
      'base-font-size': !_vm.compact,
    },attrs:{"data-tc-otp-article-body":_vm.faqObj.answerBody},domProps:{"innerHTML":_vm._s(_vm.sanitizeHTML(_vm.faqObj.answerBody))}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }