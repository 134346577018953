<template>
  <div v-show="isLoaded">
    <header
      v-if="logoLoaded && !isBasicUserHelpdesk"
      class="site-header clearfix pl-4"
    >
      <div class="float-left company-drawer mt-2">
        <a 
          href="/help_center"
          class="d-flex align-items-center big hc-text-header hc-text-header--hover"
        >
          <div
            height="40"
            class="company-drawer__logo logo-outline pl-4"
            :style="backgroundImageCss"
          />
          <span class="ml-2.5 font-weight-bold">
            {{ companyName }}
          </span>
          <span class="ml-2 opacity-80">
            | Help Center
          </span>
        </a>
      </div>
      <a
        v-if="!isLoggedIn && isGenuityDomain"
        href="/users/sign_in" 
        class="btn btn-white btn-shadow float-right mt-3 mr-3"
      >
        Sign In
      </a>
      <router-link 
        v-if="showSubmitRequest"
        v-tooltip="{
          content: toolTipContent,
          placement: 'left',
        }"
        class="btn btn-shadow hc-bg-button hc-bg-button--hover mr-3 float-right mt-3 px-3"
        :to="isSubmitRequestRouteDisabled ? '' : submitRequestRoute"
        :disabled="isSubmitRequestRouteDisabled"
      >
        Submit a Request
      </router-link>
    </header>
    <div
      class="py-7 text-center position-relative"
      :style="{
        'background-color' : helpCenterBGColor,
      }"
    >
      <svg 
        v-if="helpCenterBGColor && svgLoaded"
        class="cover-image" 
        viewBox="0 0 1440 693" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg" 
        preserveAspectRatio="none"
      >
        <path 
          d="M1257.19 305.987c8.07-121.237 185.25-191.459 185.25-191.459l-.35-129.218L-1.698-10.258.2 686.995c56.497-44.092 80.032-80.805 144.671-112.904 155.056-77.001 257.236 49.517 416.484-18.817 116.47-49.978 106.539-252.869 366.966-185.132 260.429 67.737 320.809 57.081 328.869-64.155Z" 
          fill="url(#a)"
        />
        <defs>
          <linearGradient 
            id="a" 
            x2="787.328" 
            y2="539.843" 
            x1="550.312" 
            y1="-189.617" 
            gradientUnits="userSpaceOnUse"
          >
            <stop 
              stop-color="white" 
              stop-opacity=".25"
            />
            <stop 
              offset="1" 
              :stop-color="helpCenterBGColor" 
              stop-opacity=".12"
            />
          </linearGradient>
        </defs>
      </svg>
      <img
        v-if="helpCenterBGColor && svgLoaded"
        src="~/images/waves.svg"
        class="cover-image cover-image--waves"
      >
      <slot/>
    </div>
  </div>
</template>

<script>
  import http from 'common/http';
  import permissionsHelper from 'mixins/permissions_helper';
  import customDomainHelper from 'mixins/custom_domain_helper';
  import _get from 'lodash/get';
  import { mapGetters } from 'vuex';
  import loggedIn from 'mixins/logged_in';
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import wavesImage from 'images/waves.svg';

  export default {
    mixins: [permissionsHelper, loggedIn, helpCenterPortalHelper, customDomainHelper],
    props: {
      showSubmitRequest: {
        default: true,
      },
      isBasicUserHelpdesk: {
        type: Boolean,
        default: false,
      },
      selectedWorkspace: {
        type: Object,
        default: null,
      },
      isSubmitRequestRouteDisabled: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        logoLoaded: false,
        svgLoaded: false,
        isLoaded: false,
        helpCenterLogo: {},
      };
    },
    computed: {
      ...mapGetters([
        'helpCenterBGColor',
        'helpCenterSubmitRequestRoute',
      ]),
      company() {
        return getCompanyFromStorage() || this.helpCenterLogo?.company;
      },
      logoPresent() {
        return this.company && this.company.url && this.company.url.length > 0;
      },
      backgroundImageCss() {
        return { "background-image": `url("${this.getLogoUrl()}")` };
      },
      companyName() {
        return _get(this, 'company.name');
      },
      toolTipContent() {
        if (this.isSubmitRequestRouteDisabled && this.selectedWorkspace) {
          return `No forms are available for the workspace '${this.selectedWorkspace.name}'`;
        }
        return null;
      },
      submitRequestRoute() {
        return `/workspaces/${this.selectedWorkspace.id}/forms/${this.selectedWorkspace.firstPublicCustomFormId}`;
      },
    },
    mounted() {
      this.fetchHelpCenterLogo();
    },
    methods: {
      preloadImage(url) {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
        });
      },
      checkIfAllLoaded() {
        if (this.logoLoaded && this.svgLoaded) {
          this.isLoaded = true;
          this.$emit('header-loaded');
        }
      },
      fetchHelpCenterLogo() {
        http
          .get('/help_center_logos.json')
          .then(res => {
            this.helpCenterLogo = res.data.helpCenterLogo;
            this.preloadCompanyLogo();
            this.updateFavicon();
          })
          .catch(() => {
            this.emitError('There was an error fetching your help center logo. Please refresh the page and try again.');
          });
      },
      getLogoUrl() {
        if (this.helpCenterLogo && this.helpCenterLogo.logoUrl) {
          return this.helpCenterLogo.logoUrl;
        }
        return this.company?.url;
      },
      updateFavicon() {
        if (this.helpCenterLogo && this.helpCenterLogo.faviconUrl) {
          const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'icon';
          link.href = this.helpCenterLogo.faviconUrl;
          document.head.appendChild(link);
        }
      },
      preloadCompanyLogo() {
        Promise.all([
          this.preloadImage(this.company.url),
          this.preloadImage(wavesImage),
        ]).then(() => {
          this.logoLoaded = true;
          this.svgLoaded = true;
          this.checkIfAllLoaded();
        }).catch(error => {
          console.error("Error preloading images:", error);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .site-header {
    background: transparent;
    box-shadow: none;
    position: absolute;
    width: 100%;
  }

  .cover-image {
    height: 100%;
    left: 0;
    mix-blend-mode: luminosity;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .cover-image--waves {
    height: auto;
    mix-blend-mode: overlay;
    top: 1.5rem;
  }
</style>
