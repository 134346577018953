import http from 'common/http';
import _get from 'lodash/get';

export default {
  data() {
    return {
      search: null,
      showResultCounts: false,
    };
  },
  computed: {
    showBack() {
      return this.workspaces?.length > 1;
    },
    searchPresent() {
      return this.search?.length;
    },
    workspace() {
      return getWorkspaceFromStorage();
    },
    company() {
      return getCompanyFromStorage();
    },
    companyName() {
      return _get(this, 'company.name');
    },
    selectedWorkspaceItem() {
      return this.workspaces.find(w => w.id === this.selectedWorkspace?.id);
    },
    helpCenterSubmitRequestRoute() {
      if (this.workspaces && this.workspaces.length) {
        const workspace = this.workspaces.find(w => w.id === this.selectedWorkspace.id);
        return `/workspaces/${workspace.id}/forms/${workspace.firstPublicCustomFormId}`;
      }
      return '';
    },
    hasNoFormInWorkspace() {
      const workspace = this.workspaces.find(w => w.id === this.selectedWorkspace?.id);
      return !workspace?.publicFormsEnabled;
    },
  },
  methods: {
    fetchBGColor() {
     return http
        .get(`/help_center.json`)
        .then((res) => {
          this.helpCenterBGColor = res.data.bgColor;
        })
        .catch(() => {
          this.emitError('Sorry, there was an error in loading company info. Please try again later.');
        });
    },
  },
};
