import Helpdesk from 'components/shared/public_workspaces/show.vue';
import router from 'routers/help_center';
import store from 'stores/help_tickets_store';

// eslint-disable-next-line no-unused-vars
const helpdeskApp = new Vue({
  router,
  store,
  el: '#helpdesk',
  components: { Helpdesk },
  template: '<helpdesk />',
});
