import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters('GlobalStore', [
      'contributorOptionsWithGuests',
      'contributorOptions',
    ]),
    isContributorOptionsUrl() {
      return this.optionsUrl === '/contributor_options.json';
    },
    isContributorOptionsGuestUrl() {
      return this.optionsUrl === '/contributor_options_with_guests.json';
    },
  },
  methods: {
    ...mapMutations('GlobalStore', [
      'setContributorOptionsWithGuests',
      'resetContributorOptionsWithGuests',
      'setContributorOptions',
      'resetContributorOptions',
    ]),
    resetStoreOptions() {
      if (this.isContributorOptionsGuestUrl) {
        this.resetContributorOptionsWithGuests();
      } else if (this.isContributorOptionsUrl) {
        this.resetContributorOptions();
      }
    },
    updateSelectedIdsAndLoadMore() {
      if (this.isContributorOptionsGuestUrl || this.isContributorOptionsUrl) {
        this.loadMore();
      }
    },
    setupPusherListeners() {
      // eslint-disable-next-line no-underscore-dangle
      const event = this.$pusher?.channels?.channels[this.$currentCompanyGuid]?.callbacks?._callbacks?._contributor_options;
      if (event) return;
      
      this.$store.dispatch('GlobalStore/setupOptionsPusher', { type: 'contributorOptions' });
    },
  },
};
