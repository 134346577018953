import http from 'common/http';
import { titleCase } from "title-case";
import moment from 'moment-timezone';
import { metrics,allMetrics } from 'mixins/hd_analytics_helper';

const TICKET_SOURCES = ["Manually Added", "Email", "Slack", "Auto Generated", "Microsoft Teams", "Split", "Mobile App"];
const mixinState = () => ({
    sources: [],
    categoryOptions: [],
    priorities: [],
    statuses: [],
    groups: [],
    tags: [],
    agents: [],
    cardData: null,
    reportLoading: true,
    totalAgent: null,
    loading: false,
    summaryLoading: false,

    // filters data
    group: null,
    tag: null,
    category: null,
    priority: null,
    agent: null,
    status: null,
    source: null,
    response: null,
    timePeriod: null,
    insightsSelectedChartTypes: [
      {
        id: 'status',
        type: 'bar',
      },
    ],
    isActive: 'all_tickets',
    isAllStatuses: true,
    widgetsCardData: [],
    priorityCardData: null,
    statusCardData: null,
    sourceCardData: null,
    categoryCardData: null,
    firstResponseCardData: null,
    timeOpenCardData: null,
    agentCardData: null,
    requesterCardData: null,
    resolutionCardData: null,
    surveyCardData: null,
    responseTimeCardData: null,
    ticketsFirstResponseCardData: null,
    ticketsAvgResponseCardData: null,
    ticketsResponseTimeCardData: null,
    resolvedWithinSlaCardData: null,
    firstResponseDueCardData: null,
    ticketAgeCardData: null,
    timesReopenedCardData: null,
    ticketsCreatedCardData: null,
    ticketsClosedCardData: null,
    ticketsReopenedCardData: null,
    lastUpdatedCardData: null,
    firstAssignTimeCardData: null,
    avgResponseCardData: null,
    closedUnresolvedTickets: null,
    timeSpentCardData: [],
    totalTimeSpentsCount: 0,
    timeSpents: null,
    timeSpentPage: 0,
    timeSpentPerPage: 25,
    timeSpentPageCount: 0,
    timeSpentLoading: false,
    selectedWidgets: JSON.parse(localStorage.getItem('newSelectedWidgets')) || [...metrics],
    allWidgets: JSON.parse(localStorage.getItem('allWidgets')) || [...allMetrics.slice(0, 16)],
  });

const mixinMutations = {
  setGroups: (state, groups) => {
    state.groups = groups;
  },
  setSources: (state, sources) => {
    state.sources = sources;
  },
  setAgents: (state, agents) => {
    state.agents = state.agents.concat(agents);
  },
  setTotalAgent(state, totalAgent) {
    state.totalAgent = totalAgent;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setSummaryLoading(state, summaryLoading) {
    state.summaryLoading = summaryLoading;
  },
  setCategoryOptions: (state, categoryOptions) => {
    state.categoryOptions = categoryOptions;
  },
  setPriorities: (state, priorities) => {
    state.priorities = priorities;
  },
  setStatuses: (state, statuses) => {
    state.statuses = statuses;
  },
  setTags: (state, tags) => {
    state.tags = tags;
  },
  setGroup: (state, group) => {
    state.group = group;
  },
  setSource: (state, source) => {
    state.source = source;
  },
  setTag: (state, tag) => {
    state.tag = tag;
  },
  setPriority: (state, priority) => {
    state.priority = priority;
  },
  setCategory: (state, category) => {
    state.category = category;
  },
  setAgent: (state, agent) => {
    state.agent = agent;
  },
  setStatus: (state, status) => {
    state.status = status;
  },
  setResponse: (state, response) => {
    state.response = response;
  },
  setTimePeriod: (state, timePeriod) => {
    state.timePeriod = timePeriod;
  },
  clearReportFilter: (state, filter) => {
    state[filter.filterName] = null;
  },
  clearDateFilter: (state) => {
    state.timePeriod = null;
  },
  setCardData: (state, data) => {
    state.cardData = data;
  },
  setReportLoading: (state, loading) => {
    state.reportLoading = loading;
  },
  setInsightsSelectedChartTypes: (state, chartType) => {
    state.insightsSelectedChartTypes = [...state.insightsSelectedChartTypes.filter(v => v.id !== chartType.id), chartType];
  },
  setActiveMetric: (state, isActive) => {
    state.isActive = isActive;
  },
  setIsAllStatuses: (state, isAllStatuses) => {
    state.isAllStatuses = isAllStatuses;
  },
  setWidgetsCardData: (state, widgetsData) => {
    state.widgetsCardData = widgetsData;
  },
  setPriorityCardData: (state, priorityData) => {
    state.priorityCardData = priorityData.map((priority) => ({
        name: titleCase(priority.name),
        value: priority.value,
      }));
  },
  setStatusCardData: (state, statusData) => {
    state.statusCardData = statusData.map((status) => (
      {
        name: status.name,
        value: status.value,
      }
    ));
  },
  setSourceCardData: (state, sourceData) => {
    state.sourceCardData = sourceData.map((source) => (
      {
        name: TICKET_SOURCES[source.name],
        value: source.value,
      }
    ));
  },
  setCategoryCardData: (state, categoryData) => {
    state.categoryCardData = categoryData;
  },
  setFirstResponseCardData: (state, firstResponseData) => {
    state.firstResponseCardData = firstResponseData;
  },
  setTimeOpenCardData: (state, timeOpenData) => {
    state.timeOpenCardData = timeOpenData;
  },
  setAgentCardData: (state, agentData) => {
    state.agentCardData = agentData;
  },
  setRequesterCardData: (state, requesterData) => {
    state.requesterCardData = requesterData;
  },
  setResolutionCardData: (state, resolutionData) => {
    state.resolutionCardData = resolutionData;
  },
  setSurveyCardData: (state, surveyData) => {
    state.surveyCardData = surveyData;
  },
  setResponseTimeCardData: (state, responseTimeData) => {
    state.responseTimeCardData = responseTimeData;
  },
  setTicketsFirstResponseCardData: (state, ticketsFirstResponseData) => {
    state.ticketsFirstResponseCardData = ticketsFirstResponseData;
  },
  setTicketsAvgResponseCardData: (state, ticketsAvgResponseData) => {
    state.ticketsAvgResponseCardData = ticketsAvgResponseData.map((avgResponse) => (
      {
        name: avgResponse.name,
        value: avgResponse.value,
        count: avgResponse.count,
        totalTime: avgResponse.totalTime,
      }
    ));
  },
  setTicketsResponseTimeCardData: (state, ticketsResponseTimeData) => {
    state.ticketsResponseTimeCardData = ticketsResponseTimeData;
  },
  setResolvedWithinSlaCardData: (state, resolvedWithinSlaData) => {
    state.resolvedWithinSlaCardData = resolvedWithinSlaData;
  },
  setFirstResponseDueCardData: (state, firstResponseDueData) => {
    state.firstResponseDueCardData = firstResponseDueData;
  },
  setTicketAgeCardData: (state, ticketAgeData) => {
    state.ticketAgeCardData = ticketAgeData;
  },
  setTimesReopenedCardData: (state, timesReopenedData) => {
    state.timesReopenedCardData = timesReopenedData;
  },
  setTicketsCreatedCardData: (state, ticketsCreatedData) => {
    state.ticketsCreatedCardData = ticketsCreatedData;
  },
  setTicketsClosedCardData: (state, ticketsClosedData) => {
    state.ticketsClosedCardData = ticketsClosedData;
  },
  setTicketsReopenedCardData: (state, ticketsReopenedData) => {
    state.ticketsReopenedCardData = ticketsReopenedData;
  },
  setLastUpdatedCardData: (state, lastUpdatedData) => {
    state.lastUpdatedCardData = lastUpdatedData;
  },
  setFirstAssignTimeCardData: (state, firstAssignTimeData) => {
    state.firstAssignTimeCardData = firstAssignTimeData;
  },
  setAvgResponseCardData: (state, avgResponseData) => {
    state.avgResponseCardData = avgResponseData.map((avgResponse) => (
      {
        name: avgResponse.name,
        value: avgResponse.value,
        count: avgResponse.count,
        totalTime: avgResponse.totalTime,
      }
    ));
  },
  setClosedUnresolvedTickets: (state, closedUnresolvedData) => {
    state.closedUnresolvedTickets = closedUnresolvedData;
  },
  setTimeSpentCardData: (state, timeSpentData) => {
    state.timeSpentCardData = timeSpentData.map((timeSpent) => (
      {
        name: timeSpent.name,
        value: timeSpent.value,
        id: timeSpent.id,
        totalUsers: timeSpent.totalUsers,
      }
    ));
  },
  setTotalTimeSpentsCount: (state, count) => {
    state.totalTimeSpentsCount = count;
  },
  setTimeSpents: (state, timeSpents) => {
    state.timeSpents = timeSpents;
  },
  setTimeSpentPage: (state, page) => {
    state.timeSpentPage = page;
  },
  setTimeSpentPerPage: (state, perPage) => {
    state.timeSpentPerPage = perPage;
  },
  setTimeSpentPageCount: (state, pageCount) => {
    state.timeSpentPageCount = pageCount;
  },
  setTimeSpentLoading: (state, loading) => {
    state.timeSpentLoading = loading;
  },
  setSelectedWidgets: (state, widgets) => {
    state.selectedWidgets = widgets;
    localStorage.setItem('newSelectedWidgets', JSON.stringify(widgets));
  },
  setAllWidgets: (state, widgets) => {
    state.allWidgets = widgets;
    localStorage.setItem('allWidgets', JSON.stringify(widgets));
  },
};

const mixinGetters = {
  selectedWidgets: state => state.selectedWidgets,
  allWidgets: state => state.allWidgets,
  groups: state => state.groups,
  agents: state => state.agents,
  totalAgent: state => state.totalAgent,
  loading: state => state.loading,
  summaryLoading: state => state.summaryLoading,
  status: state => state.status,
  response: state => state.response,
  sources: state => state.sources,
  priorities: state => state.priorities,
  statuses: state => state.statuses,
  tags: state => state.tags,
  group: state => state.group,
  tag: state => state.tag,
  priority: state => state.priority,
  category: state => state.category,
  agent: state => state.agent,
  source: state => state.source,
  timePeriod: state => state.timePeriod,
  categoryOptions: state => state.categoryOptions,
  totalTickets: state => state.cardData.total,
  reportLoading: state => state.reportLoading,
  isActive: state => state.isActive,
  isAllStatuses: state => state.isAllStatuses,
  baseFilterParams: (state) => {
    const params = {};
    if (state.group) {
      params.groupContId = state.group.contributor.id;
    }
    if (state.tag) {
      params.tag = state.tag.name;
    }
    if (state.category) {
      params.status = state.category.name;
    }
    if (state.status) {
      params.status = state.status.name;
    }
    if (state.response) {
      params.response = state.response.name;
    }
    if (state.agent) {
      params.agentContId = state.agent.contributorId;
    }
    if (state.source) {
      params.source = state.source.index;
    }
    if (state.priority) {
      params.priority = state.priority.id;
    }

    return params;
  },
  deepReportFiltersArray: (state) => [
    { filter: state.group, label: 'Group', filterName: 'group' },
    { filter: state.tag, label: 'Tag', filterName: 'tag' },
    { filter: state.category, label: 'Category', filterName: 'category' },
    { filter: state.status, label: 'Status', filterName: 'status' },
    { filter: state.agent, label: 'Agent', filterName: 'agent' },
    { filter: state.source, label: 'Source', filterName: 'source' },
    { filter: state.priority, label: 'Priority', filterName: 'priority' },
  ],
  activeFilters: (state, getters) => getters.deepReportFiltersArray.filter(f => f.filter !== null),
  activeFiltersCount: (state, getters) => getters.timePeriod ? getters.activeFilters.length + 1 : getters.activeFilters.length,
  insightsSelectedChartTypes: state => state.insightsSelectedChartTypes,
  widgetsCardData: state => state.widgetsCardData,
  priorityCardData: state => state.priorityCardData,
  statusCardData: state => state.statusCardData,
  sourceCardData: state => state.sourceCardData,
  categoryCardData: state => state.categoryCardData,
  firstResponseCardData: state => state.firstResponseCardData,
  timeOpenCardData: state => state.timeOpenCardData,
  agentCardData: state => state.agentCardData,
  requesterCardData: state => state.requesterCardData,
  resolutionCardData: state => state.resolutionCardData,
  surveyCardData: state => state.surveyCardData,
  responseTimeCardData: state => state.responseTimeCardData,
  avgResponseCardData: state => state.avgResponseCardData,
  timeSpentCardData: state => state.timeSpentCardData,
  totalTimeSpentsCount: state => state.totalTimeSpentsCount,
  timeSpents: state => state.timeSpents,
  timeSpentPage: state => state.timeSpentPage,
  timeSpentPerPage: state => state.timeSpentPerPage,
  timeSpentPageCount: state => state.timeSpentPageCount,
  timeSpentLoading: state => state.timeSpentLoading,
  closedUnresolvedTickets: state => state.closedUnresolvedTickets,
  ticketsFirstResponseCardData: state => state.ticketsFirstResponseCardData,
  ticketsAvgResponseCardData: state => state.ticketsAvgResponseCardData,
  ticketsResponseTimeCardData: state => state.ticketsResponseTimeCardData,
  resolvedWithinSlaCardData: state => state.resolvedWithinSlaCardData,
  firstResponseDueCardData: state => state.firstResponseDueCardData,
  ticketAgeCardData: state => state.ticketAgeCardData,
  timesReopenedCardData: state => state.timesReopenedCardData,
  ticketsCreatedCardData: state => state.ticketsCreatedCardData,
  ticketsClosedCardData: state => state.ticketsClosedCardData,
  ticketsReopenedCardData: state => state.ticketsReopenedCardData,
  lastUpdatedCardData: state => state.lastUpdatedCardData,
  firstAssignTimeCardData: state => state.firstAssignTimeCardData,
};

const mixinActions = {
  fetchAgents({ commit }, { offset, limit }) {
    commit("setLoading", true);
    http
      .get("/company_user_options.json", { params: { limit, offset } })
      .then((res) => {
        const totalUsers = res.data[0].totalAgentCount;
        const compUsers = res.data.map((user) => ({
          id: user.id,
          name: user.fullName,
          contributorId: user.contributorId,
        }));
        commit("setAgents", compUsers);
        commit("setTotalAgent", totalUsers);
        commit('setLoading', false);
      })
      .catch(() => {
        commit(
          "GlobalStore/setErrorMessage",
          "Sorry, there was an error loading available users"
        );
        commit('setLoading', false);
      });
  },
  fetchGroups({ commit }) {
    commit("setLoading", true);

    http
      .get(`/group_options.json`)
      .then((res) => {
        const groupOptions = res.data.groups.map((group) => ({
          id: group.id,
          name: group.name,
          contributorId: group.contributorId,
        }));
        commit('setGroups', groupOptions);
      })
      .catch((error) => {
        commit('GlobalStore/setErrorMessage', `Sorry, there was an error loading groups (${error.message}).`, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
  fetchCategoryOptions({ commit }) {
    commit("setLoading", true);

    http
      .get(`/category_options.json`)
      .then(res => {
        const categoriesOptions = res.data.map((category) => ({
          id: category.id,
          name: category.name,
        }));
        commit('setCategoryOptions', categoriesOptions);
      })
      .catch((error) => {
        commit('GlobalStore/setErrorMessage', `Sorry, there was an error loading catgories (${error.message}).`, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
  fetchTags({ commit }) {
    commit("setLoading", true);

    http
      .get(`/tag_options.json`)
      .then(res => {
        const tagsOptions = res.data.map((tag) => ({
          id: tag[0],
          name: tag[1],
        }));
        commit('setTags', tagsOptions);
      })
      .catch((error) => {
        commit('GlobalStore/setErrorMessage', `Sorry, there was an error loading tags (${error.message}).`, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
  fetchPriorities({ commit }) {
    commit("setLoading", true);

    http
      .get("/priority_options.json")
      .then((res) => {
        const priorityOptions = res.data.map((o) => {
          const { name } = o;
          return {
            id: name,
            name: titleCase(name),
            color: o.color,
          };
        });
        commit('setPriorities', priorityOptions);
      })
      .catch(() => {
        commit('GlobalStore/setErrorMessage', `Sorry, there was an error loading priority options.`, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
  fetchStatusOptions({ commit }) {
    const params = { company_id: this.$currentCompanyId };
    http
      .get("/status_options.json", { params })
      .then((res) => {
        this.statusOptions = res.data.map((o) => ({
            id: o.name,
            name: o.name,
          }));
        commit('setStatuses', this.statusOptions);
      })
      .catch(() => {
        commit('GlobalStore/setErrorMessage', `Sorry, there was an error loading status options.`, { root: true });
      });
  },
  fetchSources({ commit }) {
    const sourcesOptions = ['Manually Added', 'Email', 'Slack', 'Auto Generated', 'MS Teams', 'Splitted', "Mobile App"];

    const sourceObjects = sourcesOptions.map((source, index) => ({
      id: source,
      index,
      name: source,
    }));
    commit('setSources', sourceObjects);
  }, 
  fetchTicketInsightSummary({ getters, commit, state }, reportParams) {
    commit('setSummaryLoading', true);
    const params = { values: [] };
    params.companyId = Vue.prototype.$currentCompanyId;
    params.workspaceId = $workspace.id;
    params.widgetId = reportParams.widgetId;
    params.filters = reportParams.filters;
    params.type = reportParams.type;
    params.is_overview_page = reportParams.isOverviewPage;

    let dateRangeFilterIndex;
    let statusFilterIndex;
    let priorityFilterIndex;
    let agentFilterIndex;
    if (params.filters && params.filters.length > 0) {
      dateRangeFilterIndex = params.filters.findIndex(filter => filter.name === 'date_range');
      statusFilterIndex = params.filters.findIndex(filter => filter.name === 'status');
      priorityFilterIndex = params.filters.findIndex(filter => filter.name === 'priority');
      agentFilterIndex = params.filters.findIndex(filter => filter.name === 'agent');

      if (dateRangeFilterIndex !== -1 && !getters.timePeriod) {

        params.values.push({
          name: 'custom_date',
          value: {
            durationType: "createdAt",
            startDate: params.filters[dateRangeFilterIndex].values[0],
            endDate: params.filters[dateRangeFilterIndex].values[1],
            filter: 'custom_date',
          },
        });
      }

      if (statusFilterIndex !== -1 && !getters.status) {
        params.values.push({
          name: 'status',
          value: params.filters[statusFilterIndex].values[0],
          with_survey: false,
        });
      }

      if (priorityFilterIndex !== -1 && !getters.priority) {
        params.values.push({
          name: 'priority',
          value: params.filters[priorityFilterIndex].values[0],
        });
      }

      if (agentFilterIndex !== -1 && !getters.agent) {
        params.values.push({
          name: 'agent',
          value: params.filters[agentFilterIndex].contributorId[0],
        });
      }
      params.filters = [];
    }

    if (getters.timePeriod) {
      const { timePeriod } = getters;
      const { endDate } = timePeriod.filterType;
      const currentDate = moment().tz(Vue.prototype.$timezone);
      switch (endDate) {
        case "older_than_one_week": {
          const lastWeekDate = currentDate.clone().subtract(7, 'days').format();
          timePeriod.filterType.endDate = lastWeekDate;
          break;
        }
        case "older_than_one_month": {
          const lastMonthDate = currentDate.clone().subtract(1, 'month').format();
          timePeriod.filterType.endDate = lastMonthDate;
          break;
        }
        default:
          break;
      }
      params.values.push({
        name: 'custom_date',
        value: timePeriod.filterType,
      });
    }
    if (getters.group) {
      params.filters = [];
      params.values.push({
        name: 'group',
        value: getters.group.contributorId,
      });
    }
    if (getters.tag) {
      params.filters = [];
      params.values.push({
        name: 'tag',
        value: getters.tag.name,
      });
    }
    if (getters.category) {
      params.filters = [];
      params.values.push({
        name: 'category',
        value: getters.category.name,
      });
    }
    if (getters.status) {
      params.values.push({
        name: 'status',
        value: getters.status.name,
        with_survey: getters.status.withSurvey,
      });
    }
    if (getters.response) {
      params.filters = [];
      params.response_time = getters.response;
    }
    if (getters.priority) {
      params.values.push({
        name: 'priority',
        value: getters.priority.id,
      });
    }
    if (getters.agent) {
      params.values.push({
        name: 'agent',
        value: getters.agent.contributorId,
      });
    }
    if (getters.source) {
      params.filters = [];
      params.values.push({
        name: 'source',
        value: getters.source.index,
      });
    }
    if (!reportParams.singleSummaryData) {
      commit('setReportLoading', true);
    }
    return http
    .get("/insights.json", { params: { params } })
    .then((res) => {
      if(reportParams.analytics === true) {
        const newWidgetData = res.data;
        if (newWidgetData.hasOwnProperty('source')) {
          newWidgetData.source = newWidgetData.source.map((s) => (
            {
              name: TICKET_SOURCES[s.name],
              ticketIds: s.ticketIds,
              value: s.value,
            }
          ));
        }
        const updatedWidgetsCardData = [...state.widgetsCardData];
  
        const existingIndex = updatedWidgetsCardData.findIndex(
          (widget) => widget[reportParams.type] && widget.widgetId === reportParams.widgetId
        );
        if (existingIndex !== -1) {
          updatedWidgetsCardData[existingIndex] = newWidgetData;
        } else {
          updatedWidgetsCardData.push(newWidgetData);
        }
        commit('setWidgetsCardData', updatedWidgetsCardData);
      } else {
        const widgetType = reportParams.type;
        const reportData = res.data;
        switch(widgetType) {
          case 'priority':
            commit('setPriorityCardData', reportData[widgetType]);
            break;
          case 'status': 
            commit('setStatusCardData', reportData[widgetType]);
            break;
          case 'source':
            commit('setSourceCardData', reportData[widgetType]);
            break;
          case 'category':
            commit('setCategoryCardData', reportData[widgetType]);
            break;
          case 'firstResponse':
            commit('setFirstResponseCardData', reportData[widgetType]);
            break;
          case 'timeOpen':
            commit('setTimeOpenCardData', reportData[widgetType]);
            break;
          case 'agent': 
            commit('setAgentCardData', reportData[widgetType]);
            break;
          case 'requester':
            commit('setRequesterCardData', reportData[widgetType]);
            break;
          case 'resolution':
            commit('setResolutionCardData', reportData[widgetType]);
            break;
          case 'survey':
            commit('setSurveyCardData', reportData[widgetType]);
            break;
          case 'responseTime':
            commit('setResponseTimeCardData', reportData[widgetType]);
            break;
          case 'avgResponse':
            commit('setAvgResponseCardData', reportData[widgetType]);
            break;
          case 'timeSpent':
            commit('setTimeSpentCardData', reportData[widgetType]);
            break;
          case 'ticketsFirstResponse':
            commit('setTicketsFirstResponseCardData', reportData[widgetType]);
            break;
          case 'ticketsAvgResponse':
            commit('setTicketsAvgResponseCardData', reportData[widgetType]);
            break;
          case 'ticketsResponseTime':
            commit('setTicketsResponseTimeCardData', reportData[widgetType]);
            break;
          default:
            break;
        };
      }
    }).catch(() => {
        commit('GlobalStore/setErrorMessage', `Please reload page and try again.`, { root: true });
      })
      .finally(() => {
        commit('setReportLoading', false);
        commit('setSummaryLoading', false);
      });
  },
  fetchInsightsClosedTickets({ commit }, type) {
    commit('setSummaryLoading', true);
    const params= { type, total_ticket_summary: true };

    return http
      .get("/insights.json", { params: { params } })
      .then((res) => {
        commit('setClosedUnresolvedTickets', res.data);
      })
      .catch(() => {
        commit('GlobalStore/setErrorMessage', `Please reload page and try again.`, { root: true });
      })
      .finally(() => {
        commit('setSummaryLoading', false);
      });
  },
  fetchInsightsTimeSpentsData({ getters, commit }, timeSpentParams) {
    const timeSpentParamsCopy = timeSpentParams;
    timeSpentParamsCopy.per_page = getters.timeSpentPerPage;
    timeSpentParamsCopy.page = getters.timeSpentPage;
    timeSpentParamsCopy.company_user = timeSpentParamsCopy.companyUser;
    commit('setTimeSpentLoading', true);
    return http
      .get("/time_spents_insights.json", { params: timeSpentParamsCopy })
      .then((res) => {
        commit('setTimeSpents', res.data.timeSpents);
        commit('setTotalTimeSpentsCount', res.data.totalCount);
        commit('setTimeSpentPageCount', res.data.pageCount);
        commit('setTimeSpentLoading', false);
      })
      .catch(() => {
        commit('GlobalStore/setErrorMessage', `Please reload page and try again.`, { root: true });
        commit('setTimeSpentLoading', false);
      });
  },
};

const inDepthTicketReport = {
  state: () => mixinState(),
  getters: mixinGetters,
  mutations: mixinMutations,
  actions: mixinActions,
};

export default inDepthTicketReport;
