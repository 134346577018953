import http from 'common/http';

export default {
  data() {
    return {
      dependentTasks: [],
    };
  },
  computed: {
    usersInAutomatedTask() {
      return this.dependentTasks?.length;
    },
  },
  methods: {
    derivedCompanyModule() {
      let base = this.$router.options.base;
      if (base !== '/') {
        if (base === '/help_tickets') {
          return "HelpTicket";
        } 
      } else {
        if (document.location.pathname.startsWith("/help_tickets")) {
          return "HelpTicket";
        }
      }
      return null;
    },
    checkUserDependencies() {
      const selectedCompanyUsersIds = this.selectedCompanyUsersArr ? this.selectedCompanyUsersArr.map((user) => user['id']) : this.companyUser.id;
      http
        .get(`/company_users/${selectedCompanyUsersIds}/user_dependent_automated_task.json`, { params: { is_archive_modal: true } })
        .then(res => {
          this.dependentTasks = res.data.dependentTasks;
        });
    },
  },
};
