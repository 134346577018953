<template>
  <sweet-modal
    ref="modal"
    v-sweet-esc
    title="Select a FAQ to include"
    @close="$emit('close')"
  >
    <div>
      <div class="search-wrap">
        <input
          ref="searchInput"
          v-model="search"
          type="text"
          class="form-control search-input"
          placeholder="Search your FAQ's..."
          name="search"
          @keydown.enter.prevent.stop="fetchResults"
        >
        <i
          class="nulodgicon-ios-search-strong search-input-icon"
          @click.prevent="$refs.searchInput.focus"
        />
      </div>
      <div v-if="faqs">
        <div class="h6 mt-5">
          Results
          <span
            v-if="faqs.length > 0"
            class="ml-2 text-muted small"
          >
            Click on FAQ to add
          </span>
        </div>
        <div v-if="faqs.length > 0">
          <div
            v-for="faq in faqs"
            :key="faq.id"
            class="selectable-option"
            @click="$emit('input', faq)"
          >
            <div
              class="selectable-option"
              v-html="faq.questionBody"
            />
          </div>
          <nav v-if="pageCount > 1">
            <paginate
              ref="paginate"
              class="my-3 px-2 justify-content-center"
              :click-handler="pageSelected"
              :container-class="'pagination pagination-sm'"
              :next-class="'next-item'"
              :next-link-class="'page-link'"
              :next-text="'Next'"
              :page-class="'page-item'"
              :page-count="pageCount"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link'"
              :prev-text="'Prev'"
              :selected="indexPage"
            />
          </nav>
        </div>
        <div
          v-else
          class="h6 text-muted"
        >
          No results found.  Please modify your search.
        </div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
import http from 'common/http';
import { SweetModal } from 'sweet-modal-vue';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    SweetModal,
    Paginate,
  },
  data() {
    return {
      perPage: 25,
      faqs: null,
      pageCount: 0,
      indexPage: 0,
      search: null,
    };
  },
  methods: {
    fetchFaqs() {
      const params = { 'page': this.indexPage + 1, 'per_page': this.perPage, current_company: true };
      if (this.search) {
        params.search_terms = this.search;
      }
      return http
        .get(`/helpdesk_faqs.json`, { params} )
        .then(res => {
          this.faqs = res.data.questions;
          this.pageCount = res.data.pageCount;
        });
    },
    open() {
      this.search = null;
      this.fetchResults();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    fetchResults() {
      this.fetchFaqs();
    },
    pageSelected(p) {
      this.indexPage = p - 1;
      this.fetchFaqs();
    },
  },
};
</script>

<style lang="scss" scoped>
.selectable-option {
  padding: 8px;
  &:hover {
    background-color: $themed-light;
  }
}
</style>