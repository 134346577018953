<template>
  <span v-if="product">
    <img
      v-if="logoUrl"
      class="rounded-circle"
      :src="logoUrl"
      :width="width"
      :height="height"
    >
    <icon-link
      v-else-if="name"
      :name="name"
      :size="width"
      :url="companyUrl"
      :background-color="backgroundColor"
    />
  </span>
</template>

<script>
import IconLink from 'components/shared/icon_link';

export default {
  components: { IconLink },
  components: {
    IconLink
  },
  props: {
    product: {
      type: Object,
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
  },
  computed: {
    companyUrl() {
      if (this.product && this.product.url) {
        return this.product.url;
      }

      return null;
    },
    logoUrl() {
      if (this.product) {
        return this.product.logoUrl || this.product.avatarUrl;
      }

      return null;
    },
    name() {
      if (!this.product) {
        return null;
      }

      if (this.product.vendor) {
        if (this.product.vendor.logoUrl) {
          return this.product.vendor.logoUrl;
        } else {
          return this.product.vendor.name;
        }
      }

      if (this.product.name) {
        return this.product.name
      }

      return this.product.productName;
    },
  }
}
</script>
