export default {
  data() {
    return {
      assetPalleteItems: {
        paletteItems: [],
        smartPaletteItems: ["people_list", "location_list"],
      },
      asset: {
        DISABLED_DRAG: ['asset_name', 'asset_type'],
        DISABLED_PRIVATE: ['asset_name', 'asset_type'],
        DISABLED_PERMISSIONS: ['asset_name', 'asset_type'],
        DISABLED_DELETE: ['asset_name', 'asset_type', 'machine_serial_number', 'mac_addresses'],
        HEADER_SECTION: ['asset_name', 'asset_type'],
        COMPULSORY_FIELDS: ['asset_name', 'asset_type', 'machine_serial_number', 'mac_addresses'],
        DISABLED_REQUIRED: [],
      }
    }
  },
  computed: {
    isAssetModule() {
      return this.companyModule == 'asset';
    },
  },
};
