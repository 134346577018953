export default {
  methods: {
    isMultiWorkspace() {
      const companyJson = sessionStorage.getItem("company");
      let company = null;
      if (companyJson) { 
        try {
          company = JSON.parse(companyJson);
        } catch (err) {
          // Purposely do nothing
        }
      }
      const workspaceJson = sessionStorage.getItem("workspace");
      let workspace = null;
      if (workspaceJson) {
        try {
          workspace = JSON.parse(workspaceJson);
        } catch (err) {
          // Purposely do nothing
        }
      }
      return !company || (company.workspaceIds && company.workspaceIds.length > 1 && !workspace);
    },

    showFirstTimeHelpdesk(page) {
      const cookieData = document.cookie.split('; ').find(row => row.startsWith(`user_first_time`));
      const wideContentToggle = document.querySelector('.wide-theme-toggle');

      if (window.innerWidth > 1800 && !cookieData && this.pathMatches(page) && wideContentToggle) {
        if (document.body.classList.contains('content-wide-theme') && (page === 'show' || page === 'create')) {
          document.body.classList.remove('content-wide-theme');
          wideContentToggle.classList.remove('active');
        } else if (!document.body.classList.contains('content-wide-theme') && page === 'index') {
          document.body.classList.add('content-wide-theme');
          wideContentToggle.classList.add('active');
        }
      }
    },
    pathMatches(page) {
      const pathPatterns = { show: /\/help_tickets\/(\d+)/, create: /\/help_tickets\/new/, index: /\/help_tickets\/$/ };
      const curretPathName = document.location.pathname;
      return pathPatterns[page].test(curretPathName);
    },
    verticalContainerClass() {
      const verticalNavContainer = document.getElementsByClassName('vertical-nav-container');
      if (verticalNavContainer && this.$route.name === "help-ticket-show") {
        verticalNavContainer[0]?.classList.toggle('vertical-nav-index');
      }
    },
  },
};
