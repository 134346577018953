<template>
  <Teleport to="body">
    <sweet-modal
      ref="tasksListModal"
      v-sweet-esc
      title="Tasks included in selected checklists"
      width="40%"
    >
      <template v-if="descriptions">
        <div class="row">
          <div
            v-for="description in descriptions"
            :key="description.id"
            class="col-6"
          >
            <p>
              <input
                type="checkbox"
                :checked="true"
                :disabled="true"
              >
              {{ description }}
            </p>
          </div>
        </div>
      </template>
    </sweet-modal>
  </Teleport>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue';

  export default {
    components: {
      SweetModal,
    },
    props: ['descriptions', 'checklists', 'selectedChecklists'],
    methods: {
      open() {
        this.$refs.tasksListModal.open();
      },
      close() {
        this.$refs.tasksListModal.close();
      },
    },
  };
</script>
