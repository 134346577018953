<template>
  <Teleport to="body">
    <sweet-modal
      ref="categoryModal"
      v-sweet-esc
      title="Add a new Category"
      width="40%"
      data-tc-title="add new category"
      @close="nestedModalClosed"
    >
      <span class="single-column-form-wrap mx-auto mt-5">
        <div class="form-group">
          <label
            for="category"
            data-tc-label="category"
          >Category</label>
          <input
            ref="newCategory"
            v-model="newCategory"
            v-validate="newCategoryValidations"
            type="text"
            class="form-control"
            name="newCategory"
            data-vv-as="category name"
            data-tc-new-category
            @keyup.enter="addNewCategory"
          >
          <span
            v-if="errors.has('newCategory')"
            class="form-text small text-danger"
          >
            {{ errors.first("newCategory") }}
          </span>
        </div>
        <div class="form-group mb-0 position-relative d-flex justify-content-end">
          <submit-button
            id="saveNewCategory"
            :is-saving="isSaving"
            :is-validated="!!newCategory"
            btn-content="Save Category"
            saving-content="Saving Category"
            data-tc-btn="save category"
            @submit="addNewCategory"
          />
        </div>
      </span>
    </sweet-modal>
  </Teleport>
</template>

<script>
  import { mapActions } from 'vuex';
  import { SweetModal } from 'sweet-modal-vue';
  import http from "common/http";
  import nestedSweetModal from 'mixins/nested_sweet_modal';
  import permissionsHelper from 'mixins/permissions_helper';
  import SubmitButton from "./submit_button.vue";

  export default {
    components: {
      SweetModal,
      SubmitButton,
    },
    mixins: [nestedSweetModal, permissionsHelper],
    $_veeValidate: {
      validator: "new",
    },
    props: {
      inputLength: {
        type: String,
        default: "50",
      },
      isMspForm: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        newCategory: null,
        isSaving: false,
      };
    },
    computed: {
      newCategoryValidations() {
        return `required|max:${this.inputLength}`;
      },
      isHelpdeskOrMSP() {
        return this.moduleName === 'HelpTicket' || this.isMspForm;
      },
      currentModule() {
        return this.isHelpdeskOrMSP ? 'helpdesk_categories' : 'categories';
      },
    },
    methods: {
      ...mapActions('GlobalStore', ['fetchCategories']),
      closeCategoryModal(newCategoryId) {
        this.$refs.categoryModal.close();
        this.$emit('close');
        this.emitValues(newCategoryId);
        this.fetchModuleCategories();
      },
      emitValues(newCategoryId) {
        this.$emit('set-category-id', newCategoryId);
        this.$emit('set-category-name', this.newCategory);
        this.$emit('set-category', { name: this.newCategory, id: newCategoryId });
      },
      fetchModuleCategories() {
        this.isHelpdeskOrMSP ? this.$emit('fetch-categories') : this.fetchCategories();
      },
      open() {
        this.$refs.categoryModal.open();
        this.nestedModalOpened();
      },
      addNewCategory() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.isSaving = true;
            http
              .post(`/${this.currentModule}`, { name: this.newCategory })
              .then((res) => {
                this.emitSuccess(
                  `Successfully added ${this.newCategory} to your categories`
                );
                const newCategoryId = res.data.categories.find(
                  (category) => category.name === this.newCategory
                ).id;
                this.closeCategoryModal(newCategoryId);
                this.newCategory = null;
                this.isSaving = false;
              })
              .catch((error) => {
                this.emitError(
                  `Sorry, there was an error submitting your new category: ${error.response.data.message}`
                );
                this.isSaving = false;
              });
          }
        });
      },
    },
  };
</script>
