<template>
  <div ref="parent">
    <date-picker
      v-model="tempValue"
      :input-name="name"
      :disabled="disabled"
      lang="en"
      :clearable="isClearable"
      :placeholder="placeholder"
      :input-class="cssClasses"
      :format="setFormat"
      confirm
      value-type="format"
      data-tc-dates-input
      :data-tc-dates="placeholder"
      @input="onInput"
    >
      <div
        slot="header"
        class="friendly-date"
      >
        <div
          v-if="headerText"
          class="not-as-small font-weight-semi-bold mb-3"
        >
          {{ headerText }}
        </div>
        <div
          v-if="year && isYearRequired"
          class="h6 text-themed-fair"
        >
          {{ year }}
        </div>
        <div
          v-if="day && date"
          class="h2"
        >
          {{ day }},<br>
          {{ date }}
        </div>
      </div>
    </date-picker>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import dates from 'mixins/dates';
  import permissionsHelper from 'mixins/permissions_helper';
  import moment from 'moment-timezone';
  import VueMoment from 'vue-moment';

   Vue.use(VueMoment, {
    moment,
  });

  export default {
    components: {
      DatePicker,
    },
    mixins: [dates, permissionsHelper],
     $_veeValidate: {
      value() {
        return this.value;
      },
    },
    props: {
      'id': String,
      'name': String,
      'value': [String, Date],
      'headerText': String,
      'placeholder': String,
      'disabled': Boolean,
      'inputClass': {
          type: Object,
          required: false,
          default: () => ({required: false}),
      },
      'disabledBgWhite': {
        type: Boolean,
        default: () => false,
      },
      'isClearable': {
        type: Boolean,
        default: () => false,
      },
      'isYearRequired': {
        type: Boolean,
        default: () => true,
      },
      'setFormat': {
        type: String,
        default: () => 'YYYY-MM-DD',
      },
    },
    data() {
      return {
        tempValue: !this.value ? null : (moment.parseZone(this.value).format("YYYY-MM-DD")),
        isOpen: false,
      };
    },
    computed: {
      year() {
        if (!this.tempValue) {
          return null;
        }
        return moment(this.currentDateObject).format("YYYY");
      },
      day() {
        if (!this.tempValue) {
          return null;
        }
        return moment(this.currentDateObject).format("ddd");
      },
      date() {
        if (!this.tempValue) {
          return null;
        }
        return moment(this.currentDateObject).format("MMM D");
      },
      currentDateObject() {
        if (typeof this.tempValue === "object") {
          return this.tempValue;
        }
        return moment(this.tempValue).format("YYYY-MM-DD");
      },
      cssClasses() {
        let classes = [];
        if (this.inputClass['is-invalid']) {
          classes = ['form-control is-invalid'];
        } else if (this.inputClass.warning) {
          classes = ['form-control border-warning'];
        } else {
          classes = ['form-control'];
        }
        if (this.disabledBgWhite) {
          classes.push('disabled--bg-white');
        }
        return classes;
      },
      areDatesEqual() {
        return this.tempValue.toString() !== this.value && this.tempValue !== this.tempValue.toString();
      },
    },
    watch: {
      value() {
        this.tempValue = this.value ? moment(this.value).format("YYYY-MM-DD") : null;
      },
      tempValue() {
        if (this.tempValue) {
          const newSelectedYear = this.$refs.parent.querySelector('.mx-current-year').text;
          const newSelectedMonth = this.$refs.parent.querySelector('.mx-current-month').text;
          const currentDay = moment(this.tempValue).format('DD');
          const selectedMonth = moment().month(newSelectedMonth).format("MM");
          const newDate = new Date(moment({ year: newSelectedYear, month: selectedMonth - 1, day: currentDay }));
          const newDateToCheck = `${newSelectedYear}-${selectedMonth}-${currentDay}`;

          if (this.areDatesEqual && newDateToCheck !== this.value) {
            this.$emit('input', newDate);
          }
        }
      },
    },
    methods: {
      onWorkspaceChange() {
        this.watchPreConfirmSelection();
        this.assignId();
      },
      onInput(val) {
        this.$emit('input', val);
        this.$emit('change');
      },
      watchPreConfirmSelection() {
        // A fairly janky (and potentially brittle) hack since vue2-datepicker's change event has a bug in it.
        // After making any change, double check that the left-hand date info updates when choosing a date.
        if (!this.$refs.parent || !this.$refs.parent.querySelector('.mx-calendar-content')) {
          return;
        }
        this.$refs.parent.querySelector('.mx-calendar-content').addEventListener('click', () => {
          setTimeout(() => {
            this.tempValue = this.$children[0].$children[0].$children[0].value;
          });
        });
      },
      assignId() {
        this.$refs.parent.querySelector('input').id = this.id;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mx-datepicker {
    color: inherit;
    font: inherit;
    width: 100%;
  }

  .friendly-date {
    background-color: $themed-link;
    color: white;
    height: 100%;
    left: 0;
    padding: 1rem;
    position: absolute;
    top: 0;
    width: 9rem;
  }

  .h2 {
    line-height: 1;
  }

  .mx-datepicker {
    :deep(.form-control) {
      padding-left: 2.5rem;
      font-size: 14px;
    }

    :deep(.mx-input-append) {
      background-color: transparent;
      left: 0.5rem;
      padding: 0;
      width: 1.5rem;
      @media($max: $medium) {
        width: 1rem;
      }
    }

    :deep(.mx-datepicker-popup) {
      border: 0;
      border-radius: $border-radius;
      box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
      padding-left: 9rem;
    }

    :deep(.mx-calendar) {
      color: inherit;
      font: inherit;
      padding: 0.5rem;
    }

    :deep(.mx-calendar-header) {
      padding: 0 0.25rem;
      height: 2.5rem;
      line-height: 2.5rem;
    }

    :deep(.mx-calendar-content) {
      height: 14rem;
      width: 19.25rem;
    }

    :deep(.mx-current-month),
    :deep(.mx-current-year) {
      font-size: 0.8125rem;
    }

    :deep(.mx-current-month) {
      text-transform: uppercase;
    }

    :deep(.mx-panel-date td),
    :deep(.mx-panel-date th) {
      height: 1.75rem;
      width: 2.75rem;
      border: 0;
    }

    :deep(.mx-datepicker-footer) {
      border-top: 0;
      padding: 0.5rem;
      padding-top: 0;
    }

    :deep(.mx-datepicker-btn) {
      border: 0;
      border-radius: $border-radius;
      color: $themed-link;
      font: inherit;
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0.375rem 1rem;
      margin: 0;
      transition: $transition-base;

      &:hover {
        background-color: $themed-light;
      }
    }

    :deep(.mx-calendar-icon) {
      color: $blue-gray;

      text {
        color: transparent;
      }
    }
  }
</style>
