import http from 'common/http';

export default {
  computed: {
    isGenuityDomain() {
      return window.location.host.includes('genuity.com') || window.location.host.includes('genuity-staging.com');
    },
  },
  methods: {
    getLogoUrl() {
      if (this.helpCenterLogo?.logoUrl) {
        return this.helpCenterLogo.logoUrl;
      }
      return this.company?.url;
    },
    updateFavicon() {
      if (this.helpCenterLogo?.faviconUrl) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = this.helpCenterLogo.faviconUrl;
        document.head.appendChild(link);
      }
    },
    fetchHelpCenterLogo() {
      http
        .get('/help_center_logos.json')
        .then(res => {
          this.setHelpCenterLogo(res.data.helpCenterLogo);
          this.updateFavicon();
        })
        .catch(() => {
          this.emitError('There was an error fetching your help center logo. Please refresh the page and try again.');
        });
    },
  },
};
