<template>
  <div>
    <div
      v-if="workspaces && workspaces.length > 1"
      class="row justify-content-center"
    >
      <div class="col-md-8 col-lg-6 mt-6">
        <div class="box px-5">
          <div class="box__inner">
            <h2 class="my-3">
              {{ formName }}
            </h2>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column jusify-items-center pl-0 mb-4">
                <div class="d-flex align-items-center">
                  <img
                    src="~images/company-icon.svg"
                    class="company-select__icon mr-1"
                    alt="Company Icon"
                    height="16"
                    width="16"
                  >
                  <span class="not-as-small text-muted d-inline-block">Company:</span>
                  <span class="text-secondary font-weight-semi-bold not-as-small ml-1">{{ $companyName }}</span>
                </div>
              </div>
            </div>
            <div class="workspace-selection-header font-weight-normal text-dark not-as-small mb-3 mt-2 pt-1">
              <div class="d-flex align-items-center">
                <span>Select a Workspace</span>
              </div>
            </div>
            <div v-if="workspaces">
              <div
                v-for="workspace in workspaces"
                :key="workspace.id"
              >
                <a
                  href="#"
                  class="btn btn-sm btn-link text-secondary d-flex align-items-center text-left py-2 mb-2 border border-light rounded workspace-link w-100"
                  :data-tc-view-select-space="workspace.name"
                  @click="selectWorkspace(workspace)"
                >
                  <img
                    src="~images/workspaces-icon.svg"
                    class="align-bottom workspace-header__label--image d-inline-block workspace-select__icon mr-2"
                    alt="Workspaces Icon"
                    height="16"
                    width="16"
                  >
                  {{ workspace.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="isHelpCenter"
      class="d-flex flex-row justify-content-center"
    >
      <div class="w-50 d-flex flex-row justify-content-end mt-2">
        <a
          class="col-md-3 col-sm-4 box box--flat box--with-hover justify-content-center"
          href="/knowledge_base"
        >
          <img
            :src="require('images/article.png')"
            height="30"
            class="mt-n1 mr-2"
          >
          Visit Knowledge Base
        </a>
        <a 
          class="col-md-3 col-sm-4 box box--flat box--with-hover justify-content-center ml-2"
          href="/faqs"
        >
          <img
            :src="require('images/faq.png')"
            height="30"
            class="mt-n1 mr-2"
          >
          Visit FAQs
        </a>
      </div>
    </div>
    <div
      v-if="workspaces && workspaces.length == 0"
      class="row justify-content-md-center"
    >
      <div class="col-6">
        <div class="box mt-2">
          <div class="box__inner">
            <h6>Sorry, but this portal is not currently available.</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import permissionsHelper from 'mixins/permissions_helper';

  export default {
    mixins: [permissionsHelper],
    props: ['workspaces'],
    computed: {
      formName() {
        const title = {
          'faqs': 'Help Desk FAQ',
          'knowledge_base': 'Knowledge Base Articles',
          'help_center': 'Help Center Portal',
        };

        return title[this.modulePrefix];
      },
      isHelpCenter() {
        return window.location.pathname.startsWith('/help_center');
      },
    },
    methods: {
      selectWorkspace(workspace) {
        setWorkspaceToStorage(workspace);
        this.$router.push(`/workspaces/${workspace.id}`);
      },
    },
  };
</script>
