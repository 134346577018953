<template>
  <div>
    <div v-if="ticketShowPage">
      <a
        href="#"
        class="text-secondary float-right"
        @click.stop.prevent="toggleNewChecklistForm"
      >
        <i
          class="nulodgicon-arrow-left-c white mr-2"
          data-tc-back-tasks-btn
        />
        <span>Back to <strong>all tasks</strong></span>
      </a>
    </div>
    <label for="checklist">
      Assign Checklists
    </label>
    <multi-select
      name="checklist"
      placeholder="Select option"
      :allow-empty="true"
      :multiple="true"
      :options="tasksChecklists"
      :value="checklistNames"
      @select="selectChecklist"
      @remove="removeChecklist"
    />
    <div v-if="renderShowButton">
      <a
        href="#"
        class="small mt-1 ml-2"
        @click="openTasksListModal"
      >
        Show Tasks
      </a>
      <tasks-list-modal
        ref="tasksListModal"
        :descriptions="descriptions"
      />
    </div>
    <div
      v-else-if="selectedChecklists && selectedChecklists.length && !ticketShowPage"
      class="mt-3 bg-lighter p-3 row mx-0"
    >
      <span class="text-cyan col-12 mb-2">
        {{ labelText }}
      </span>
      <div
        v-for="description in descriptions"
        :key="description.id"
        class="col-6 text-secondary not-as-small"
      >
        <p class="mb-0">
          <input
            type="checkbox"
            :checked="true"
            :disabled="true"
          >
          {{ description }}
        </p>
      </div>
    </div>
    <div
      v-if="ticketShowPage"
      class="mt-2 d-flex justify-content-end"
    >
      <submit-button
        btn-content="Save"
        saving-content="Saving"
        :is-saving="isSaving"
        :btn-classes="'px-4'"
        @submit="addChecklistsToTicket"
      />
    </div>
  </div>
</template>

<script>
  import http from 'common/http';
  import { mapGetters } from 'vuex';
  import MultiSelect from 'vue-multiselect';
  import pluralize from 'pluralize/pluralize';
  import permissionsHelper from 'mixins/permissions_helper';
  import TasksListModal from 'components/tasks_list_modal.vue';
  import SubmitButton from 'components/shared/submit_button.vue';

  export default {
    components: {
      MultiSelect,
      TasksListModal,
      SubmitButton,
    },
    mixins: [permissionsHelper],
    props: ['ticketChecklists', 'ticketShowPage'],
    data() {
      return {
        checklists: [],
        isSaving: false,
        descriptions: [],
        selectedChecklists: [],
        showNewChecklistModal: false,
      };
    },
    computed: {
      ...mapGetters(['currentHelpTicket']),
      tasksChecklists() {
        return this.checklists?.map(checklist => checklist.name);
      },
      renderShowButton() {
        return this.ticketShowPage ? this.selectedChecklists?.length : this.descriptions?.length > 8;
      },
      labelText() {
        return `${pluralize('Task', this.descriptions?.length)} from Selected ${pluralize('Checklist', this.selectedChecklists?.length)}`;
      },
      noChecklistPresent() {
        return !this.ticketChecklists?.length && !this.checklists?.length;
      },
      helpCenter() {
        return this.$router.options.base === '/help_center';
      },
      checklistNames() {
        return this.checklists
          .filter(checklist => this.selectedChecklists.includes(checklist.id))
          .map(checklist => checklist.name);
      },
    },
    methods: {
      onWorkspaceChange() {
        if (this.ticketShowPage) {
          this.selectedChecklists = this.ticketChecklists;
        }
        this.fetchTotalChecklists();
      },
      openNewChecklistModal() {
        this.showNewChecklistModal = true;
        this.$nextTick(() => {
          if (this.$refs.newChecklistModal) {
            this.$refs.newChecklistModal.open();
          }
        });
      },
      toggleNewChecklistForm() {
        this.$emit('toggle-new-checklist-form');
      },
      selectChecklist(selectedChecklist) {
        const selectedId = this.checklists.find(checklist => checklist.name === selectedChecklist)?.id;
        if (selectedId) {
          this.selectedChecklists.push(selectedId);
          this.setDescriptions();
        }
      },
      removeChecklist(selectedChecklist) {
        const selectedId = this.checklists.find(checklist => checklist.name === selectedChecklist)?.id;
        const idx = this.selectedChecklists.indexOf(selectedId);
        if (idx > -1) {
          this.selectedChecklists.splice(idx, 1);
        }
        this.setDescriptions();
      },
      setDescriptions() {
        const selectedChecklistsTasks = this.checklists
          .filter(checklist => this.selectedChecklists.includes(checklist.id))
          .flatMap(checklist => checklist.tasks);
        this.descriptions = selectedChecklistsTasks.map(task => task.description);
      },
      openTasksListModal() {
        this.$refs.tasksListModal.open();
      },
      fetchTotalChecklists() {
        http
          .get('/task_checklists.json')
          .then(res => {
            this.checklists = res.data.taskChecklists;
            this.setDescriptions();
          })
          .catch(() => {
            this.emitError('Sorry, there was an error fetching task checklists.');
          });
      },
      addChecklistsToTicket(ticket) {
        if (this.selectedChecklists?.length || this.ticketShowPage) {
          this.isSaving = true;
          const params = {
            ticket_id: ticket?.id || this.currentHelpTicket.id,
            checklists_ids: this.selectedChecklists,
            is_ticket_show_page: this.ticketShowPage,
          };
          http
            .post('/create_project_tasks.json', params)
            .then(() => {
              this.isSaving = false;
              this.toggleNewChecklistForm();
              this.$emit('checklists-updated');
            })
            .catch(() => {
              this.isSaving = false;
              this.emitError('Sorry, there was an error creating task checklist.');
            });
        }
      },
      saveNewChecklist(checklist) {
        const params = {
          name: checklist.name,
          task_ids: checklist.taskIds,
          show_page: true,
        };
        http
          .post('/task_checklists.json', params)
          .then(res => {
            this.checklists.push(res.data.data);
            this.selectedChecklists.push(res.data.data.id);
            this.setDescriptions();
            this.$emit('close-modal');
            this.emitSuccess('Task Checklist created successfully.');
          })
          .catch(error => {
            this.emitError(`Sorry there was an error creating the Task Checklist. ${error.response.data.errors}`);
          });
      },
    },
  };
</script>
