import http from "common/http";
import _get from "lodash/get";

const mixinState = () => ({
  assets: null,
  contracts: null,
  vendors: null,
  contributors: null,
  categoryOptions: null,
  departmentOptions: [],
  externalUsers: null,
  weightedContributors: null,
  telecoms: null,
  helpTickets: null,
  customForms: null,
  userCompaniesCustomForms: null,
  templates: null,
  smartLists: [
    "location_list",
    "contract_list",
    "vendor_list",
    "telecom_list",
    "asset_list",
    "people_list",
  ],
  statusFilterValue: 'active',
});

const mixinMutations = {
  setStatusFilterValue: (state, value) => {
    state.statusFilterValue = value;
  },
  setAssets: (state, assets) => {
    state.assets = assets;
  },
  setContracts: (state, contracts) => {
    state.contracts = contracts;
  },
  setVendors: (state, vendors) => {
    state.vendors = vendors;
  },
  setHelpTickets: (state, helpTickets) => {
    state.helpTickets = helpTickets;
  },
  setContributors: (state, contributors) => {
    state.contributors = contributors;
  },
  setExternalUsers: (state, externalUsers) => {
    state.externalUsers = externalUsers;
  },
  setWeightedContributors: (state, weightedContributors) => {
    state.weightedContributors = weightedContributors;
  },
  setTelecoms: (state, telecoms) => {
    state.telecoms = telecoms;
  },
  setCustomForms: (state, customForms) => {
    state.customForms = customForms;
  },
  setUserCompaniesCustomForms: (state, customForms) => {
    state.userCompaniesCustomForms = customForms;
  },
  setCategoryOptions: (state, categoryOptions) => {
    state.categoryOptions = categoryOptions;
  },
  setDepartmentOptions: (state, departmentOptions) => {
    state.departmentOptions = departmentOptions;
  },
  setTemplates: (state, templates) => {
    state.templates = templates;
  },
};

const mixinGetters = {
  statusFilterValue: (state) => state.statusFilterValue,
  assets: (state) => state.assets,
  contracts: (state) => state.contracts,
  vendors: (state) => state.vendors,
  helpTickets: (state) => state.helpTickets,
  externalUsers: (state) => state.externalUsers,
  contributors: (state) => state.contributors,
  telecoms: (state) => state.telecoms,
  customForms: (state) => state.customForms,
  templates: (state) => state.templates,
  smartLists: (state) => state.smartLists,
  weightedContributors: (state) => state.weightedContributors,
  categoryOptions: (state) => state.categoryOptions,
  departmentOptions: (state) => state.departmentOptions,
  userCompaniesCustomForms: (state) => state.userCompaniesCustomForms,
};

const mixinActions = {
  fetchAssets({ commit }, params = {}) {
    http
      .get("/managed_asset_options.json", { params })
      .then((res) => {
        commit("setAssets", res.data.assets);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error loading available assets. ${msg}`, { root: true });
      });
  },

  fetchHelpTickets({ commit }, params = {}) {
    http
      .get("/help_ticket_options.json", { params })
      .then((res) => {
        commit("setHelpTickets", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error loading help tickets. ${msg}`, { root: true });
      });
  },

  fetchExternalUsers({ commit }, params = {}) {
    http
      .get("/external_user_options.json", { params })
      .then((res) => {
        commit("setExternalUsers", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error loading available users. ${msg}`, { root: true });
      });
  },

  fetchContributors({ commit }, params = {}) {
    http
      .get("/contributor_options.json", { params })
      .then((res) => {
        if (params.weighted && params.weighted === true) {
          commit("setWeightedContributors", res.data);
        } else {
          commit("setContributors", res.data);
        }
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error loading available people. ${msg}`, { root: true });
      });
  },

  fetchVendors({ commit }) {
    http
      .get("/vendor_options.json")
      .then((res) => {
        commit("setVendors", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error getting vendors. ${msg}`, { root: true });
      });
  },

  fetchContracts({ commit }) {
    http
      .get("/contract_options.json")
      .then((res) => {
        commit("setContracts", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error getting contracts. ${msg}`, { root: true });
      });
  },

  fetchTelecomServices({ commit }) {
    http
      .get("/telecom_service_options.json")
      .then((res) => {
        commit("setTelecoms", res.data.services);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error getting telecom services. ${msg}`, { root: true });
      });
  },

  fetchCompaniesCustomFormsOptions({ commit }) {
    const params = {
      msp_flag: true,
      companies_ids: sessionStorage.getItem('selectedCompanies') || [],
    };

    return http
      .get("/msp/custom_forms.json", { params })
      .then((res) => {
        commit("setUserCompaniesCustomForms", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `We encountered an error getting the custom forms. ${msg}`, { root: true });
      });
  },

  fetchCustomForms({ commit,rootState }, attrs = {}) {
    const { currentCompany, currentWorkspace } = rootState;
    const params = {
      company_module: attrs.companyModule,
      status: attrs.status,
      privilege_name: attrs.privilegeName,
      fetch_fields: attrs.fetchFields,
      workspace_id: currentWorkspace,
      company_id: currentCompany,
    };
    const url = `/abbreviated_custom_forms.json`;

    return http
      .get(url, { params })
      .then((res) => {
        commit("setCustomForms", res.data);
        return res;
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `We encountered an error getting the custom forms. ${msg}`, { root: true });
      });
  },

  fetchCustomFormTemplates({ commit }, companyModule) {
    const params = { company_module: companyModule };
    return http
      .get("/custom_form_templates.json", { params })
      .then((res) => {
        commit("setTemplates", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `We encountered an error getting the form templates. ${msg}`, { root: true });
      });
  },

  fetchCategoryOptions({ commit }) {
    http
      .get("/category_options.json")
      .then((res) => {
        commit("setCategoryOptions", res.data);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error getting categories. ${msg}`, { root: true });
      });
  },
  fetchDepartmentOptions({ commit }) {
    http
      .get('/departments.json')
      .then((res) => {
        commit("setDepartmentOptions", res.data.companyDepartments);
      })
      .catch((error) => {
        const msg = _get(error, "response.data.message", "");
        commit("GlobalStore/setErrorMessage", `Sorry, there was an error getting departments. ${msg}`, { root: true });
      });
  },
};

const customForms = {
  state: () => mixinState(),
  getters: mixinGetters,
  mutations: mixinMutations,
  actions: mixinActions,
};
export default customForms;
