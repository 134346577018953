<template>
  <div>
    <attachment-input
      v-if="!disabled"
      fetch-allowed-types
      :show-label="true"
      :is-loading="isLoading"
      @input="addFile"
    />
    <input
      ref="input"
      type="text"
      class="hidden"
      name="attachment"
      :required="required"
      :value="values"
    >
    <div class="form-group mb-1">
      <div
        v-for="file in values"
        :key="file.id"
      >
        <img
          :src="getFileType(file)"
          width="20"
        >
        <p class="d-inline-block my-1 text-secondary small align-middle font-weight-semi-bold">
          {{ file.attachmentFileName }}
        </p>
        <a
          v-if="!disabled"
          class="file-delete float-right"
          @click="deleteFile(file)"
        >
          <i class="nulodgicon-trash-b" />
        </a>
      </div>
    </div>
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import AttachmentInput from 'components/shared/attachment_input.vue';
import http from 'common/http';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import attachmentHelper from "mixins/attachment_helper";

export default {
  components: {
    AttachmentInput,
  },
  mixins: [
    fieldViewerInput,
    attachmentHelper,
    permissionsHelper,
  ],
  data() {
    return {
      errors: null,
      values: [],
      isLoading: false,
    };
  },
  methods: {
    onWorkspaceChange() {
      if (this.value) {
        this.fetchAttachments();
      }
    },
    uploadFile(file) {
      const serializedForm = new FormData();
      if (file) {
        serializedForm.append('custom_form_attachment_attribute[attachment]', file, file.filename);
        serializedForm.append('module_type', this.getModuleType());
      }
      this.$emit("loading", true);
      this.isLoading = true;
      http
        .post(`/custom_form_attachments.json`, serializedForm)
        .then((res) => {
          if(res.data.message){
            this.emitError(res.data.message);
          } else {
            this.values.push(res.data.file);
            this.field.files = this.values;
            this.$emit("input", this.values);
          }
        })
        .catch(() => {
          this.emitError(`Sorry, there was an error uploading the file.`);
        })
        .finally(() => {
          this.$emit("loading", false);
          this.isLoading = false;
        });
    },
    fetchAttachments() {
      const params = {
        values: this.value,
      };
      http
        .get('/custom_form_attachments/fetch_attachments', { params })
        .then(res => {
          if (res.data.attachments) {
            this.values = res.data.attachments;
          }
        })
        .catch(() => {
          this.emitError('Sorry, there was an error getting the files.');
        });
    },
    addFile(file) {
      this.errors = null;
      let totalSize = 0;
      totalSize = this.values.reduce((a, b) => +a + +b.attachmentFileSize, 0);
      totalSize += file.size;
      const alreadyUploaded = this.values.find(({attachmentFileName})=> attachmentFileName === file.name.replace(/ /g,"_"));
      if (alreadyUploaded) {
        this.emitError('Sorry, you cannot upload the same file multiple times.');
      } else if (totalSize > 25000000) {
        this.emitError('Sorry, the total size of the files cannot exceed 25MB.');
      } else if (file.size < 25000000) {
        this.uploadFile(file);
      } else {
        this.emitError('Sorry, that file is too large. Please upload files under 25MB.');
      }
    },
    delete(file) {
      http
        .delete(`/custom_form_attachments/${file.id}.json`)
        .catch(() => {
          this.emitError(`Sorry, there was an error deleting attachments.`);
        });
    },
    deleteFile(file) {
      if (file.id) {
        this.delete(file);
      }
      let f;
      let idx;
      const { length } = this.values;
      for (idx = 0; idx < length; idx += 1) {
        f = this.values[idx];
        if (f.id === file.id) {
          this.values.splice(idx, 1);
          break;
        }
      }
      this.field.files = this.values;
      this.$emit("input", this.values);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.values.length) {
        msg = `Select a file`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-delete {
  font-size: 1em;
  color: $themed-fair !important;

  &:hover {
    color: $red !important;
  }
}

::v-deep .v-sync {
  background-color: #8a9197 !important;
  height: 0.3rem !important;
  width: 0.3rem !important;
}
</style>
