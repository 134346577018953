<template>
  <div class="dropdown d-inline-block align-middle">
    <div>
      <input
        ref="input"
        type="hidden"
      >
      <a
        href="#"
        class="dropdown-toggle btn btn-sm btn-text text-secondary p-0 pl-1 d-flex align-items-center"
        data-tc-priority-select
        @click.stop.prevent="togglePriorityDropdown"
      >
        <span
          v-if="value"
          class="readable-length--xx-small truncate"
        >
          <i
            v-tooltip="priorityTooltip"
            class="genuicon-flag mr-1 align-middle"
            :style="{'color': color}"
          />
          {{ titleize(value) }}
        </span>
        <span
          v-else
          class="readable-length--xx-small truncate"
        >
          Select Priority
        </span>
        <i class="nulodgicon nulodgicon-arrow-down" />
      </a>
      <div
        v-if="field.options"
        class="dropdown-menu not-as-small row dissmissible-menu"
        :class="{ hidden: !showPriorityDropdown }"
        data-tc-priority-options
      >
        <a
          v-for="(option, index) in field.options"
          :key="index"
          v-tooltip.left="{
            content: option.name,
            boundariesElement: 'body',
            show: (option.name && option.name.length > 20 && hoveredIndex == index),
            trigger: 'manual',
          }"
          href="#"
          class="dropdown-item clickable pl-3 readable-length--xx-small truncate"
          data-tc-priority-select-option
          @mouseover="hoveredIndex = index"
          @mouseleave="hoveredIndex = null"
          @click.stop.prevent="priorityChanged(option.name)"
        >
          <i
            v-tooltip="priorityTooltip"
            class="genuicon-flag mr-1"
            :style="{'color': option.color}"
          />
          {{ titleize(option.name) }}
        </a>
      </div>
    </div>
    <div
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </div>
  </div>
</template>

<script>
  import _get from 'lodash/get';
  import strings from 'mixins/string';
  import customForms from 'mixins/custom_forms';
  import fieldViewerInput from 'mixins/field_viewer_input';
  import permissionsHelper from 'mixins/permissions_helper';

  export default {
    mixins: [strings, customForms, fieldViewerInput, permissionsHelper],
    props: {
      field: {
        type: Object,
      },
      value: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showPriorityDropdown: false,
        errors: null,
        hoveredIndex: null,
      }
    },
    computed: {
      priorityTooltip() {
        if (this.value) {
          return `${this.toTitle(this.value)} Priority`;
        }
        return "";
      },
      color() {
        if (this.value) {
          const val = this.value.toLowerCase();
          let option = this.field.options.find(obj => {
            return obj.name.toLowerCase() == val;
          });
          if (option) {
            return option.color;
          }
        }
        return "";
      },
    },
    methods: {
      onWorkspaceChange() {
        if (!this.value && this.field && this.field.options) {
          this.$emit("input", this.field.options[0].name);
        } else {
          this.$emit("input", this.value);
        }
      },
      togglePriorityDropdown() {
        this.showPriorityDropdown = !this.showPriorityDropdown;
      },
      priorityChanged(priority) {
        this.togglePriorityDropdown();
        this.errors = null;
        this.$emit("input", priority);
      },
      showErrors() {
        let msg = null;
        if (!this.required && this.moduleName != "automatedTasks") {
          return false;
        }
        if (!this.value || this.value.length == 0) {
          msg = `Select a priority`;
          this.$refs.input.setCustomValidity(msg);
          this.errors = msg;
        } else {
          this.$refs.input.setCustomValidity('');
          this.errors = null;
        }
        return !this.errors;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .dropdown-toggle {
    margin-top: -10px;
  }

  .dropdown-menu {
    display: block;
    left: 0;
    right: auto;
  }
</style>
