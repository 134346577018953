import _get from "lodash/get";
import _map from "lodash/map";
import customFormActions from "./actions";

const REGEX_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  mixins: [customFormActions],
  computed: {
    ids() {
      return _map(_get(this, "value", []), "valueInt");
    },
    entityValues() {
      if (this.entities) {
        return this.entities.filter((entity) => this.ids.includes(entity.id));
      }
      return null;
    },
    isExternalUser() {  
      return this.field.fieldAttributeType === "people_list" && this.field.audience === "guests" && this.query && this.query.indexOf("@") > 0 && REGEX_PATTERN.exec(this.query);
    },
    filteredOptions() {
      if (this.isExternalUser) {
        return [{ id: this.query, name: this.query }].concat(this.options);
      }
      if (this.showGroupHeaders && this.selectedMenu === 'people') {
        const excludedTypes = ['Everyone'];
        const uniqueFilters = this.getUniqueFilters(excludedTypes);
        const filteredData = this.mapToFilteredData(uniqueFilters);
        return [
          ...filteredData,
          { filterType: 'Everyone', filters: this.options.filter(x => x.linkableType === 'Everyone') },
        ];
      }
      return this.options;
    },
  },
  methods: {
    getUniqueFilters(excludedTypes) {
      return Array.from(new Set(this.options
        .map(name => name.linkableType)
        .filter(x => !excludedTypes.includes(x))));
    },
    mapToFilteredData(uniqueFilters) {
      return uniqueFilters.map(filterName => ({
        filterType: filterName,
        filters: this.options.filter(x => x.linkableType === filterName),
      }));
    },
    addValue(item) {
      this.addValueBase(item);
    },
    removeValue(item) {
      this.removeValueBase(item);
    },
  },
};
