export default {
  computed: {
    convertedText() {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
      const webRegex = /(^|[^https:\/\/])\b(www\.[^\s]+)\b/g;
  
      const textWithUrls = this.field.label.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
      const textWithEmails = textWithUrls.replace(emailRegex, '<a href="mailto:$1">$1</a>');
      const linkedText = textWithEmails.replace(webRegex, `<a href="https://$1" target="_blank">https://$1</a>`);
      
      return linkedText;
    }
  }
}
