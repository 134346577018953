export default {
  data() {
    return {
      helpdeskPalleteItems: {
        paletteItems: ["status", "priority", "attachments", "category", "department"],
        smartPaletteItems: ["people_list", "asset_list", "contract_list", "vendor_list", "telecom_list", "location_list"],
      },
      helpdesk: {
        DISABLED_DRAG: ['subject'],
        DISABLED_PRIVATE: ['subject'],
        DISABLED_PERMISSIONS: ['subject', 'created_by'],
        DISABLED_DELETE: ['subject', 'status', 'priority', 'assigned_to', 'created_by', 'description'],
        HEADER_SECTION: ['subject'],
        COMPULSORY_FIELDS: ['subject', 'status', 'priority', 'assigned_to', 'created_by', 'description'],
        DISABLED_POSITION: [],
        DISABLED_REQUIRED: ['subject', 'created_by'],
      },
    }
  },
  computed: {
    isHelpDeskModule() {
      return this.companyModule == 'helpdesk';
    },
  },
};
