import { mapMutations } from "vuex";
import http from "common/http";
import _get from "lodash/get";

export default {
  computed: {
    scopingCustomFormsPrefix() {
      if (companyModule() === 'HelpTicket' && this.$route.name !== 'summary') {
        return '/tickets';
      } else if ((companyModule() === 'CompanyUser' || companyModule() === 'HelpTicket') && (this.$route.name === 'users_show' || this.$route.name === 'summary')) {
        return '/company_users';
      } else if (companyModule() === 'CompanyUser' && this.$route.name === 'locations_show') {
        return '/locations';
      }
        return '';
    },
  },
  methods: {
    ...mapMutations(["setActionSuggestion"]),

    addValueBase(item) {
      const itemId = item.id || item;
      return this.addFormValue(this.object.id, {
        custom_form_field_id: this.field.id,
        value: itemId,
        company_id: this.object.company.id,
      });
    },
    removeValueBase(item) {
      const itemId = item.id || item;

      return this.removeFormValue(this.object.id, {
        custom_form_field_id: this.field.id,
        value: itemId,
        company_id: this.object.company.id,
      });
    },
    addFormValue(id, params) {
      const label = params.label || _get(this, "field.label", "");
      const name = _get(this, "field.name", "");
      const type = _get(this, "field.fieldAttributeType", "");
      const typeList = ['people_list', 'asset_list', 'contract_list', 'vendor_list', 'telecom_list', 'location_list', 'assigned_to', 'tag'];
      const action = typeList.includes(type) ? 'added' : 'changed';
      const companyId = params.company_id ? `?company_id=${params.company_id}` : '';
      return http
        .post(`${this.scopingCustomFormsPrefix}/${id}/custom_form_values.json${companyId}`, {
          json: JSON.stringify(params),
        })
        .then(() => {
          this.emitSuccess(`Successfully ${action} ${label}`);
          this.setActionSuggestion(name);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          const required = _get(error, "response.data.isRequired", "");
          if (required) {
            this.emitError(
              `Sorry, there was an error updating ${label}. ${msg}`
            );
          } else {
            this.emitError(
              `Sorry, there was an error updating ${label}. Please refresh the page and try again. ${msg}`
            );
          }
        });
    },
    removeFormValue(id, params) {
      const label = _get(this, "field.label", "");
      const name = _get(this, "field.name", "");
      const valueId = _get(params, "id", "X");
      const companyId = params.company_id ? `?company_id=${params.company_id}` : '';
      return http
        .delete(`${this.scopingCustomFormsPrefix}/${id}/custom_form_values/${valueId}.json${companyId}`, {
          params: { json: params },
        })
        .then(() => {
          if (name !== "created_by") {
            this.emitSuccess(`Successfully removed ${label}`);
          }
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          const required = _get(error, "response.data.isRequired", "");
          const index = this.disabledOptions?.indexOf(id);
          this.disabledOptions?.splice(index, 1);
          if (required) {
            this.emitError(
              `Sorry, there was an error updating ${label}. ${msg}`
            );
          } else {
            this.emitError(
              `Sorry, there was an error updating ${label}. Please refresh the page and try again. ${msg}`
            );
          }
        });
    },
  },
};
