<template>
  <div>
    <input
      ref="input"
      class="hidden"
      type="text"
      name="tag"
      :required="required"
      :value="tagFieldValue"
    >
    <multi-select
      v-if="options"
      placeholder="Type in a new tag"
      tag-placeholder="Add a tag"
      :multiple="true"
      :options="options"
      :taggable="true"
      :allow-empty="!required"
      :disabled="disabled"
      :value="valueStr"
      @remove="removeTag"
      @select="selectTag"
      @tag="addNewTag"
    />
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import customForms from 'mixins/custom_forms';
import permissionsHelper from 'mixins/permissions_helper';
import fieldViewerInput from 'mixins/field_viewer_input';

export default {
  components: {
    MultiSelect,
  },
  mixins: [customForms, fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
      default: Boolean,
    }, 
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      valueStr: [],
    };
  },
  computed: {
    tagFieldValue: {
      get() {
        return this.valueStr;
      },
    },
    options() {
      if (this.field.options) {
        if (typeof this.field.options === "string") {
          return JSON.parse(this.field.options);
        }
        return this.field.options;
      }
      return null;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.setTagFieldValue();
    },
    addNewTag(tag) {
      const isHelpCenterRoute = window.location.href.includes('help_center');
      if (!this.isWrite && !isHelpCenterRoute) {
        return;
      }
      const {options} = this.field;
      options.push(tag);

      const params = {
        id: this.field.id,
        options,
      };

      this.$emit('update', params);
      this.selectTag(tag);
    },
    selectTag(tag) {
      this.valueStr.push(tag);
      this.emitValue();
    },
    removeTag(tag) {
      const idx = this.valueStr.indexOf(tag);
      if (idx > -1) {
        this.$emit("delete", this.valueStr[idx]);
        this.valueStr.splice(idx, 1);
      }
      this.emitValue();
    },
    emitValue() {
      this.errors = null;
      this.$emit("input", this.valueStr);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.valueStr.length) {
        msg = `Please select a ${this.field.label}`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>
