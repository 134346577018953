export default {
  methods: {
    downcaseCount: function(searchItem, term) {
      if (searchItem) {
        return searchItem.toLowerCase().indexOf(term.toLowerCase())>=0
      } else {
        return null
      }
    }
  }
}
