const sanitizeRoute = (to, from, next) => {
  if (to.path.includes('//')) {
    const correctedPath = to.path.replace(/\/{2,}/g, '/');
    window.location.replace(correctedPath);
  } else {
    next();
  }
};

export default sanitizeRoute;
