<template>
  <div class="d-inline-block">
    <multi-select 
      v-if="companyOptions.length > 1 || workspaceOptions.length > 1 || forceGroups"
      :value="value" 
      :options="groupedOptions" 
      :multiple="false" 
      :allow-empty="false"
      group-values="forms" 
      group-label="workspace" 
      :group-select="false" 
      placeholder="Type to search" 
      track-by="id" 
      label="name"
      @open="fetchCustomFormOptions"
      @input="emitInput"
    />
    <multi-select 
      v-else
      v-model="value" 
      :options="customFormOptions" 
      :multiple="false" 
      :allow-empty="false"
      placeholder="Type to search" 
      track-by="id" 
      label="name"
      @open="fetchCustomFormOptions"
      @input="emitInput"
    />
  </div>
</template>

<script>
  import http from 'common/http';
  import companyModule from 'mixins/company_module.js';
  import MultiSelect from 'vue-multiselect';
  import _compact from 'lodash/compact';
  import permissionsHelper from 'mixins/permissions_helper';

  export default {
    components: {
      MultiSelect
    }, 
    mixins: [companyModule, permissionsHelper],
    props: { 
      value: {
        type: Object,
      },
      excludes: {
        type: Array,
        default: [],
      },
      showAll: {
        type: Boolean,
        default: false,
      },
      forceGroups: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        customFormOptions: [],
        formNames: [],
      }
    },
    computed: {
      createFormPath() {
        return "/new";
      },
      companyOptions() {
        let map = {};
        if (!this.customFormOptions) {
          return [];
        }
        this.customFormOptions.forEach(o => map[o.company.id] = o.company);
        return Object.values(map);
      },
      workspaceOptions() {
        let map = {};
        if (!this.customFormOptions) {
          return [];
        }
        this.customFormOptions.forEach(o => map[o.workspace.id] = o.workspace);
        return Object.values(map);
      },
      groupedOptions() {
        let options = [];
        let formOptions = [];
        let lastWorkspace = null;
        let name = null;
        let company = null;
        let workspace = null;
        if (!this.customFormOptions) {
          return [];
        }
        this.customFormOptions.forEach((o) => {
          company = o.company;
          workspace = o.workspace;
          if (!lastWorkspace || (lastWorkspace && workspace.id != lastWorkspace.id)) { 
            formOptions = [];
            if (this.companyOptions.length > 1) {
              name = `${company.name} / ${workspace.name}`;
            } else {
              name = `${workspace.name}`;
            }
            options.push({
              workspace: name,
              forms: formOptions,
            });
          }
          if (this.formNames && !this.formNames.includes(o.name)) {
            formOptions.push(o);
          }
          lastWorkspace = workspace;
        });
        options = options.filter(o => o.forms.length)
        return options;
      },
    },
    methods: {
      onWorkspaceChange() {
        const onCompanyChange = (e) => {
          if (!this._isMounted || this._isBeingDestroyed) {
            document.removeEventListener("company-change", onCompanyChange);
            return;
          }
          this.customFormOptions = [];
        };

        document.addEventListener("company-change", onCompanyChange);
      },
      emitInput(value) {
        this.$emit("input", value);
      },
      excludeFormIds () {
        let exclude_ids = [];
        if (this.excludes && this.excludes.length > 0) {
          this.excludes.filter((obj) => {
            if (obj.id && !exclude_ids.includes(obj.id)) {
              exclude_ids.push(obj.id);
              this.formNames.push(obj.name);
            }
          })
        }
        return exclude_ids;
      },
      fetchCustomFormOptions() {
        if (this.customFormOptions && this.customFormOptions.length > 0) {
          return;
        }
        let params = { 
          company_module: 'helpdesk',
          active: true,
        };
        let optionsUrl = `/custom_form_options.json`;
        let workspace = getWorkspaceFromStorage();
        if (workspace && !this.showAll) {
          optionsUrl = `/workspaces/${workspace.id}/custom_form_options.json`;
        }
        params['excludes'] = this.excludeFormIds();
        return http
          .get(optionsUrl, { params: params })
          .then((res) => {
            this.customFormOptions = res.data;
          })
          .catch((error) => {
            this.emitError('Sorry, there was an error loading company forms. Please try again later.');
          });
      },
    }
  };
</script>

