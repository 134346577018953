import http from 'common/http';
import { mapMutations } from 'vuex';
import _find from "lodash/find";

export default {
  data() {
    return {
      companyUserPalleteItems: {
        paletteItems: ["avatar"],
        smartPaletteItems: ["location_list"],
        lockmodifyingPhone: false,
      },
      companyUser: {
        DISABLED_DRAG: ['name'],
        DISABLED_PRIVATE: ['name', 'address'],
        DISABLED_PERMISSIONS: [],
        DISABLED_DELETE: ['first_name', 'last_name', 'email', 'mobile_phone'],
        HEADER_SECTION: ['first_name', 'last_name', 'email'],
        COMPULSORY_FIELDS: ['first_name', 'last_name', 'email', 'mobile_phone'],
        DISABLED_POSITION: [],
        DISABLED_REQUIRED: ['first_name', 'last_name', 'email'],
        HEADER_SECTION_WITH_COMPANY: ['first_name', 'last_name', 'email', 'company_name'],
        DISABLED_DELETE_WITH_COMPANY: ['first_name', 'last_name', 'email', 'mobile_phone', 'company_name'],
        COMPULSORY_FIELDS_WITH_COMPANY: ['first_name', 'last_name', 'email', 'mobile_phone', 'company_name'],
      },
      userMfaSettings: {},
      userLocked: false,
      userUnlockedValue: false,
    }
  },
  computed: {
    currentCompanyUser() {
      if (this.$store.getters['companyUser']) {
        return this.$store.getters['companyUser'];
      }
    },
    isCompanyUserModule() {
      return this.companyModule == 'company_user';
    },
    showSecuritySection() {
      return this.creatingNewStaff ? this.accessNotLimited : this.editingExistedStaff;
    },
    creatingNewStaff() {
      return this.$route.path == "/users/new";
    },
    accessNotLimited() {
      return this.mfaSettings ? this.mfaSettings.mfaEnabled && !this.mfaSettings.restrictUserAccess : false;
    },
    editingExistedStaff() {
      return this.isPermittedUser  && (this.accessNotLimited || this.userLocked);
    },
    mfaWithNumberExist() {
      return this.isMfaEnabled() && this.lockmodifyingPhone;
    },
    isPermittedUser() {
      return this.$currentCompanyAdminUser || this.$superAdminUser || !this.mfaSettings?.restrictUserAccess;
    },
  },
  methods: {
    ...mapMutations(['updateCurrentUser']),
    setUserData() {
      let user_data = {
        first_name: _find(this.object.values, { customFormFieldId: _find(this.currentForm.formFields, { name: 'first_name' }).id }),
        last_name: _find(this.object.values, { customFormFieldId: _find(this.currentForm.formFields, { name: 'last_name' }).id }),
        email: _find(this.object.values, { customFormFieldId: _find(this.currentForm.formFields, { name: 'email' }).id })
      }
      
      this.object['user_data'] = Object.entries(user_data).reduce((a,[k,v]) => (v == null ? a : (a[k]=v.valueStr, a)), {});
      this.object['mfaSettings'] = JSON.stringify(this.userMfaSettings);
    },
    fetchMfaSettings() {
      this.$store.dispatch('GlobalStore/fetchMfaSettings', this.$currentCompanyId)
      .then(() => {
        if (this.creatingNewStaff && this.mfaSettings && this.mfaSettings.enabledForAllUsers)
          this.currentCompanyUser.mfaEnabled = true;
      });
      if (this.currentCompanyUser) {
        this.userLocked = this.currentCompanyUser.locked;
        this.lockmodifyingPhone = !!this.currentCompanyUser.mobilePhone;
      }
    },
    isMfaEnabled() {
      let status = false;
      if (this.currentCompanyUser) {
        status = this.mfaSettings && this.mfaSettings.mfaEnabled && this.currentCompanyUser.mfaEnabled;
      } else {
        status = this.mfaSettings && this.mfaSettings.mfaEnabled && this.mfaSettings.enabledForAllUsers;
      }
      this.userMfaSettings['mfa_enabled'] = status;
      this.userMfaSettings['mfa_verified'] = status;
      return status;
    },
    toggleCompanyUserMfa(status) {
      this.userMfaSettings['mfa_enabled'] = status;
      this.userMfaSettings['mfa_verified'] = status;
    },
    loadCompanyUser() {
      let currentUserId;
      
      if (this.$route.params.id && this.isCompanyUserModule) {
        currentUserId = this.$route.params.id;
      } else {
        currentUserId = this.$currentCompanyUserId;
      }
      http
        .get(`/company_users/${currentUserId}.json`)
        .then((res) => {
          this.updateCurrentUser(res.data);
          this.fetchMfaSettings();
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error loading the company. ${error.response.data.message}`);
        })
    },
    userLockedStatus() {
      this.currentCompanyUser.locked = !this.userUnlockedValue;
      this.currentCompanyUser.mfaAttemptsRemaining = 5;
      this.userMfaSettings['locked'] = this.currentCompanyUser.locked;
      this.userMfaSettings['mfa_attempts_remaining'] = this.currentCompanyUser.mfaAttemptsRemaining;
    },
    updatedMfaPhone(mfaPhoneNumber) {
      window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/');
    },
  }
};
