import vClickOutside from 'v-click-outside';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      hasListOpen: false
    }
  },
  methods: {
    toggleList() {
      if (this.hasListOpen) {
        this.$refs.phoneNumberInput.$refs.CountrySelector.onBlur();
        this.hasListOpen = false;
      } else {
        this.hasListOpen = this.$refs.phoneNumberInput.$refs.CountrySelector._data.isFocus;
      }
    },
    onBlur() {
      this.hasListOpen = false;
    }
  },
}
