<template>
  <div>
    <div>
      <vue-tel-input
        :key="phoneInputKey"
        class="form-control"
        :value="phoneNumber"
        :default-country="defaultCountry"
        :input-options="{'required': required, placeholder: 'Enter phone number'}"
        :disabled="checkDisabled"
        @input="emitValue"
      />
    </div>
    <input
      ref="input"
      type="text"
      class="hidden"
      :required="required"
      :value="phoneNumber"
    >
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
    <span
      v-if="checkDisabled"
      class="text-muted small"
    >
      This phone number was registered with the <strong>two factor authentication</strong>.
      If you need to change it, please verify your new phone number by
      <a
        href="#"
        @click.prevent="openMfaPhoneModal"
      >
        clicking here.
      </a>
    </span>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import _get from 'lodash/get';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  components: {
    VueTelInput
  },
  mixins: [fieldViewerInput, permissionsHelper],
  props: {
    field: {},
    value: {},
    fieldViewerInput: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    mfaPhoneField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      phoneInputKey: false
    }
  },
  computed: {
    phoneNumber() {
      if (this.value && this.value.split(',').length > 0) {
        return this.value.split(',')[1];
      }
      return '';
    },
    defaultCountry() {
      let countryCode = parseInt(this.value);
      this.phoneInputKey = !this.phoneInputKey;
      if (this.value) {
        if (isNaN(countryCode)) {
          return this.value.split(",")[0];
        }
        return parseInt(this.value.split(",")[0]);
      }
      return 'US';
    },
    checkDisabled() {
      return (
        this.mfaPhoneField && this.field.name == "mobile_phone"
      );
    },
    checkPhoneNumber() {
      if (this.value &&
          this.value.split(',').length > 0 &&
          this.value.split(',')[1].length == 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.setPhoneFieldValue();
    },
    emitValue(number, phoneObject) {
      if(number == "" && phoneObject.formatted == "" && phoneObject.country) {
        this.$emit("input", `${phoneObject.country.iso2},`);
        this.setErrors('');
      } else if (phoneObject.valid) {
        this.setErrors('');
        this.$emit("input", `${phoneObject.countryCode},${phoneObject.formatted}`);
      } else if (!phoneObject.valid && phoneObject.formatted) {
        this.setErrors('Phone number invalid');
      } else {
        this.setErrors('');
      }
    },
    showErrors() {
      if (!this.required && this.moduleName != "automatedTasks") {
        return false;
      }
      if (!this.value || this.checkPhoneNumber) {
        this.setErrors(`Please enter a phone number`);
      } else {
        this.setErrors('');
      }
      return !this.errors;
    },
    setErrors(errorMessage) {
      let msg = null;
      msg = `${errorMessage}`;
      this.$refs.input.setCustomValidity(msg);
      if (errorMessage) {
        this.errors = msg;
      } else {
        this.errors = null;
      }
    },
    openMfaPhoneModal() {
      this.$emit('open-modal');
    },
  },
}
</script>
