<template>
  <div class="d-inline-block">
    <img
      v-if="imgSrc && !hasError"
      class="rounded bg-themed-icon-badge-bg p-1"
      :src="imgSrc"
      :width="size"
      @error="hasError = true"
    >
    <avatar
      v-else-if="name"
      class="rounded"
      :size="size"
      :username="username(name)"
      :rounded="false"
      :color="color"
      :background-color="backgroundColor"
    />
  </div>
</template>

<script>
  import { Avatar } from 'vue-avatar';

  export default {
    components: {
      Avatar,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      useName: {
        type: Boolean,
        default: true,
      },
      size: {
        type: [String, Number],
        default: 40,
      },
      backgroundColor: {
        type: String,
        default: "#adb5bd",
      },
      color: {
        type: String,
        default: "white",
      },
      url: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        hasError: false,
      };
    },
    computed: {
      defaultVendor() {
        return this.type === "DefaultVendor";
      },
      imgSrc() {
        this.clearError();
        let { name } = this;
        if (this.name) {
          name = this.name.toLowerCase();
        }

        if (name === "genuity") {
          name = "gogenuity";
        }

        if (!this.useName) {
          name = "";
        }
        // URL gets priority over name
        let logoUrl = null;
        if (this.url && this.url.length > 0) {
          logoUrl = this.sanitizeUrlForUrl(this.url);
        } else if (this.name) {
          logoUrl = this.sanitizeUrlForName(name);
        }

        return logoUrl;
      },
    },
    methods: {
      username(name) {
        if (name) {
          return name.split('(')[0];
        }
        return '';
      },
      clearError() {
        this.hasError = false;
      },
      sanitizeUrlForUrl(url) {
        let logoUrl;

        if (url.includes("gogenuity.com")) {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/genuity.png';
        } else if (url.includes("xfinity.com")) {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/xfinity.png';
        } else if (url.includes("att.com")) {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/att_logo.jpg';
        } else {
          logoUrl = `https://logo.clearbit.com/${url
              .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
              .replace(' ', '')
            }`;
        }

        return logoUrl;
      },
      sanitizeUrlForName(name) {
        const sanitizedName = name?.toLowerCase();
        if (!sanitizedName) {
          return null;
        }
        let logoUrl;
        if (sanitizedName === "google") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/google.png';
        } else if (sanitizedName === "microsoft") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/microsoft.png';
        } else if (sanitizedName === "amazon web services") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/aws.png';
        } else if (sanitizedName === "google cloud products") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/google-cloud.png';
        } else if (sanitizedName === "microsoft azure") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/azure.png';
        } else if (sanitizedName === "genuity" || sanitizedName === "gogenuity") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/genuity.png';
        } else if (sanitizedName === "xfinity.com") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/xfinity.png';
        } else if (sanitizedName === "at&t") {
          logoUrl = 'https://nulodgic-static-assets.s3.amazonaws.com/images/logos/products/att_logo.jpg';
        } else {
          logoUrl = `https://logo.clearbit.com/${sanitizedName
            .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
            .replace('.com','')
            .replace(/\s/g, '')
            .replace('ltd', '')
            .replace('llc','')
            .replace('corp.','')
            .replace('pte','')
            .replace('co.','')
            .replace('inc.','')
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .replace('attphone', 'att') // adding because many companies will have ATT show up on their transactions
          }.com`;
        }

        return logoUrl;
      },
    },
  };

</script>
