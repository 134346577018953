export const trackRouteChange = (to, from, next, module) => {
  const eventName = `Route ${module} ${to.name}`;

  mixpanel.track(eventName, {
    'name': to.name,
    'path': to.path,
    'full_path': to.fullPath,
    'params': to.params,
    'query': to.query
  })
  next();
};
