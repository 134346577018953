export default {
  data() {
    return {
      locationPalleteItems: {
        paletteItems: ["avatar"],
        smartPaletteItems: [],
      },
      location: {
        DISABLED_DRAG: ['name'],
        DISABLED_PRIVATE: ['name', 'address'],
        DISABLED_PERMISSIONS: ['name', 'address', 'city', 'zip'],
        DISABLED_DELETE: ['name', 'address', 'city', 'zip'],
        HEADER_SECTION: ['name'],
        COMPULSORY_FIELDS: ['name', 'address', 'city', 'zip'],
        DISABLED_POSITION: ['name', 'address', 'city', 'zip'],
        DISABLED_REQUIRED: ['name'],
      }
    }
  },
  computed: {
    islocationModule() {
      return this.companyModule == 'location';
    },
  },
};
