<template>
  <div class="form-group">
    <div
      class="d-inline-flex flex-grow-1 search-wrap help-center-search-bar "
      :class="{'search-dropdown-open': showCustomDropDown}"
    >
      <input
        v-model="searchTerms"
        type="text"
        class="form-control pl-5 py-4 border-0 shadow-base base-font-size form-control-lg"
        :placeholder="suggestedText"
        @input="fetchResults()"
        @keyup.enter="fetchResults(true)"
      >
      <i class="nulodgicon-ios-search-strong search-input-icon"/>
      <div class="box position-absolute search-dropdown-menu">
        <search-results
          v-if="faqSearchEnabled"
          :search-results="limitSearchItems(publicFaqs)"
          :entity-type="'FAQs'"
          icon="nulodgicon-information-circled"
        />
        <search-results
          v-if="articleSearchEnabled"
          :search-results="limitSearchItems(publicArticles)"
          :entity-type="'Knowledge Base Articles'"
          icon="genuicon-knowledge-base"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import _debounce from 'lodash/debounce';
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import SearchResults from './search_results.vue';

  export default {
    components: {
      SearchResults,
    },
    props: {
      faqSearchEnabled: {
        type: Boolean,
        default: false,
      },
      articleSearchEnabled: {
        type: Boolean,
        default: false,
      },
      suggestedText: {
        type: String,
        required: true,
      },
      companyWideSearch: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        searchTerms: '',
        selectedFaq: null,
        selectedWorkspace: null,
        selectedArticle: null,
        finishSearch: false,
      };
    },
    computed: {
      ...mapGetters([
        'publicFaqs',
        'publicArticles',
        'loading',
        'publicArticlesCategories',
      ]),
      showCustomDropDown() {
        return this.searchTerms.length && !this.finishSearch;
      },
    },
    methods: {
      ...mapActions([
        'fetchPublicArticles', 
        'fetchPublicFaqs',
        'fetchHelpCenterColors',
      ]),
      ...mapMutations([
        'setFaqs',
        'setFaqsPage',
        'setPublicArticlesPage',
        'setPublicArticlesPageCount',
        'setLoading',
      ]),
      fetchResults(closeDropdown) {
        if (this.faqSearchEnabled) {
          this.setLoading(true);
          this.loadFaqs();
        }
        if (this.articleSearchEnabled) {
          this.setLoading(true);
          this.loadArticles();
        }
        this.finishSearch = closeDropdown;
      },
      loadFaqs: _debounce( function loadSearchFaqs() {
        this.setFaqsPage(0);
        this.fetchPublicFaqs({searchTerms: this.searchTerms, helpCenter: true, companyWideSearch: this.companyWideSearch});
      }, 500),
      loadArticles: _debounce(function loadSearchArticles() {
        this.setPublicArticlesPage(0);
        this.fetchPublicArticles({ search: this.searchTerms, categories: this.publicArticlesCategories, companyWideSearch: this.companyWideSearch });
      }, 500),
      limitSearchItems(items) {
        return items ? items.slice(0, 5) : null;
      },
    },
  };
</script>

<style scoped>

  .help-center-search-bar {
    min-width: 40rem;
  }

</style>
