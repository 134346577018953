export default {
  methods: {
    getFileType(file) {
      const fileType = file.attachmentContentType || file.attachedFileContentType;
      const urlPath = "https://s3.amazonaws.com/nulodgic-static-assets/images/";

      if (!fileType) {
        return `${urlPath}file_type_none.png`;
      } else if (fileType.indexOf("pdf") !== -1) {
        return `${urlPath}file_type_pdf.png`;
      } else if (fileType.indexOf("png") !== -1) {
        return `${urlPath}file_type_png.png`;
      } else if (fileType.indexOf("csv") !== -1) {
        return `${urlPath}file_type_csv.png`;
      } else if (fileType.indexOf("jpeg") !== -1 || fileType.indexOf("jpg") !== -1) {
        return `${urlPath}file_type_jpg.png`;
      } else if (fileType.indexOf("msword") !== -1) {
        return `${urlPath}file_type_doc.png`;
      } else if (fileType.indexOf("excel") !== -1 || fileType.indexOf("sheet") !== -1 || fileType.indexOf("xls") !== -1 || fileType.indexOf("xlsx") !== -1) {
        return `${urlPath}file_type_xls.png`;
      }
      return `${urlPath}file_type_none.png`;
    },
    getFileIcon(file) {
      const fileType = file.attachmentContentType || file.attachedFileContentType;
      const urlPath = "https://s3.amazonaws.com/nulodgic-static-assets/images/";

      if (!fileType) {
        return `${urlPath}none_preview_icon.png`;
      } else if (fileType.indexOf("pdf") !== -1) {
        return `${urlPath}pdf_preview_icon.png`;
      } else if (fileType.indexOf("csv") !== -1) {
        return `${urlPath}csv_preview_icon.png`;
      } else if (fileType.indexOf("msword") !== -1) {
        return `${urlPath}doc_preview_icon.png`;
      } else if (fileType.indexOf("excel") !== -1 || fileType.indexOf("sheet") !== -1 || fileType.indexOf("xls") !== -1 || fileType.indexOf("xlsx") !== -1) {
        return `${urlPath}xls_preview_icon.png`;
      }
      return `${urlPath}none_preview_icon.png`;
    },
    getModuleType() {
      const { href } = window.location;
      if (href.includes("/help_tickets") || href.includes("/related_companies")) {
        return 'HelpTicket';
      } else if (href.includes("/managed_assets")) {
        return 'ManagedAsset';
      } else if (href.includes("/location")) {
        return 'Location';
      } else if (href.includes("/users") || href.includes("/profile")) {
        return 'CompanyUser';
      }
      return 'HelpTicket';
    },
  },
};
