<template>
  <div>
    <sweet-modal 
      ref="subscriptionModal"
      blocking
      hideCloseButton
      title="Subscription Required"
    >
      <div class="p-1">
        <div class="mx-auto">
          <h4 class="mt-1">Looks like you don't have a subscription.</h4>
          <h5 class="font-weight-normal mt-3 readable-line-height">
            Please contact your company's administrator to set up a Genuity subscription to continue using this feature.
          </h5>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';

export default {
  components: {
    SweetModal,
  },
  mounted() {
    this.$refs.subscriptionModal.open();
  },
};
</script>
