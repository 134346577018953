import _get from 'lodash/get';
import {COUNTRY_ADDRESS_POSTALS} from '../../common/countries-address-postalcode.js';

export default {
  methods: {
    checkFieldOptions(formField) {
      var options = _get(formField, 'options', null);
      if (!options || (options && options.length == 0)) {
        return false;
      }
      return true;
    },
    validateField(formField) {
      var cityRegex = /^[a-zA-Z\s]*$/;
      var label = _get(formField, 'label', "");
      if (label == "Add a label" || label == "") {
        return false;
      } else if (formField.name == "city" && !cityRegex.test(formField.defaultValue)) {
        return false;
      } else {
        return true;
      }
    },
    zipCodeValidation() {
      let {zipValue, countryValue, zipFieldId} = this.findCountryAndZipValue();
      if (zipValue && countryValue && zipFieldId) {
        const country = COUNTRY_ADDRESS_POSTALS.find(obj => obj.name == countryValue);
        let postal = new RegExp(country.postal, 'i')
        if (country && postal && postal.test(zipValue) == false) {
            let refField = this.$refs[`field${zipFieldId}`];
            if (refField) {
              refField[0].$children[0].setErrors("Invalid zip format.");
            }
        } else {
          let refField = this.$refs[`field${zipFieldId}`];
          if (refField) {
            refField[0].$children[0].setErrors("");
          }
        }
      }
    },
    findCountryAndZipValue() {
      let zipValue, countryValue, zipFieldId;

      let countryField = this.cloneCurrentForm.formFields.find((obj) => {
        if (obj.fieldAttributeType == "list" && obj.options[0].value == "country") {
          return obj;
        }
      });

      if (countryField && this.object.values.length > 0) {
        let fieldObj = this.object.values.find((f) => f.customFormFieldId == countryField.id);
        if (fieldObj) {
          countryValue = fieldObj.valueStr;
        }
      }

      let zipField = this.cloneCurrentForm.formFields.find((obj) => {
        if (obj.name == "zip") {
          return obj;
        }
      });

      if (zipField && this.object.values.length > 0) {
        let fieldObj = this.object.values.find((f) => f.customFormFieldId == zipField.id);
        if (fieldObj) {
          zipValue = fieldObj.valueStr;
          zipFieldId = zipField.id;
        }
      }

      return { zipValue, countryValue, zipFieldId };
    }
  }
}
