<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    class="mb-3"
    :class="{'border-bottom' : displayBorder }"
  >
    <h6 class="text-muted mx-4 mt-3">{{ displayMessage }}</h6>
    <ul v-if="searchResults && searchResults.length > 0">
      <li
        v-for="entity in searchResults"
        :key="entity.id"
        class="px-2 py-2 search-item d-flex flex-row"
        @click="selectEntity(entity)"
      >
        <i :class="icon + ' text-muted ml-4 mr-2'"/>
        <span v-html="sanitizeHTML(entityTitle(entity))"/>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import articles from 'mixins/articles';
  import strings from 'mixins/string';

  export default {
    mixins: [strings, articles],
    props: {
      searchResults: {
        type: Array,
        default: () => [],
      },
      entityType: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters([
        'loading',
      ]),
      displayMessage() {
        if (this.loading) {
          return `Searching ${this.entityType}...`;
        } else if (this.searchResults && this.searchResults.length > 0) {
          return `Results for ${this.entityType}`;
        }
        return `No matching ${this.entityType} found`;
      },
      displayBorder() {
        return this.entityType === 'FAQs';
      },
    },
    methods: {
      ...mapMutations([
        'setHelpCenterSelectedFaq',
        'setHelpCenterSelectedArticle',
      ]),
      selectEntity(entity) {
        if (this.entityType === 'FAQs') {
          this.setHelpCenterSelectedFaq(entity);
        } else if (this.entityType === 'Knowledge Base Articles') {
          this.fetchPublicArticle({ workspaceId: entity.workspaceId, slug: entity.slug });
        }
      },
      entityTitle(entity) {
        if (this.entityType === 'FAQs') {
          return entity.questionBody;
        } else if (this.entityType === 'Knowledge Base Articles') {
          return entity.title;
        }
        return null;
      },
    },
  };
</script>
