import permissionsHelper from "mixins/permissions_helper";
import _map from 'lodash/map';
import customForms from "../custom_forms";

export default {
  mixins: [permissionsHelper, customForms],
  methods: {
    isWritableFromCustomFormObject(obj) {
      if (this.isWrite) {
        return true;
      }
      const createdBy = _map(this.getValuesForName(obj, 'created_by'), 'valueInt');
      const assignedTo = _map(this.getValuesForName(obj, 'assigned_to'), 'valueInt');
      if ((this.isScoped || this.isBasicAccess) && (createdBy.includes(this.$currentContributorId) || assignedTo.includes(this.$currentContributorId))) {
        return true;
      }
      const groupMembers = obj.groupMemberIds;
      if (groupMembers) {
        const parsedIds = JSON.parse(groupMembers);

        if ((parsedIds.created_by?.includes(this.$currentContributorId)) ||
            (parsedIds.assigned_to?.includes(this.$currentContributorId))) {
          return true;
        }
      }
      return false;
    },
  },
};
