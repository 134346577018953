<template>
  <div>
    <sweet-modal
      ref="removeUserFromGroupModal"
      v-sweet-esc
      title="Before you remove"
    >
      <template v-if="selectedGroupForRemoval && selectedGroupForRemoval.id">
        <div>
          <h6>
            Are you absolutely sure you want to remove {{ currentCompanyUser.user.fullName }} from {{ selectedGroupForRemoval.name }}?
          </h6>
          <p class="text-secondary mt-3">
            <i>
              <strong>Note</strong>: This may result in {{ currentCompanyUser.user.fullName }} losing permissions granted to this group.
            </i>
          </p>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-text mr-2"
        @click.prevent="closeRemoveModal"
      >
        No, keep it
      </button>
      <button
        slot="button"
        class="btn btn-danger"
        :disabled="isRemoving"
        @click.prevent="removeFromGroup"
      >
        Yes, I'm sure
      </button>
    </sweet-modal>
    <div class="mt-5 form-group">
      <label class="mb-0">
        {{ groupFieldLabel }}
      </label>
      <user-groups
        :groups="checkGroups"
        :is-saving="isSaving"
        :can-manage="isWrite"
        :selected-group="selectedGroupForAdd"
        :is-staff-new-page="isStaffNewPage"
        @add-to-group="addToGroup"
        @open-remove-modal="openRemoveModal"
        @group-selected="groupSelected"
        @group-removed="groupRemoved"
      />
    </div>
  </div>
</template>

<script>
  import http from 'common/http';
  import UserGroups from 'components/shared/user_groups.vue';
  import { SweetModal } from 'sweet-modal-vue';
  import _findIndex from 'lodash/findIndex';
  import permissionsHelper from 'mixins/permissions_helper';
  import companyUserCustomFormHelper from "mixins/company_user_custom_form_helper";

  export default {
    components: {
      UserGroups,
      SweetModal,
    },
    mixins: [permissionsHelper, companyUserCustomFormHelper],
    data() {
      return {
        isSaving: false,
        isRemoving: false,
        isStaffNewPage: false,
        selectedGroupForAdd: {},
        selectedGroupForRemoval: {},
      }
    },
    computed: {
      checkGroups() {
        if (this.currentCompanyUser && this.$route.params.id) {
          this.isStaffNewPage = false;
          if (this.$route.name === 'users_edit') {
            return this.currentCompanyUser.groups.filter(op => op.name !== 'Everyone');
          }
          return this.currentCompanyUser.groups;
        }
        this.isStaffNewPage = true;
        return [];
      },
      groupFieldLabel() {
        return this.isStaffNewPage ? "Assign First Group" : "Assign Group";
      },
    },
    methods: {
      groupSelected(group) {
        this.selectedGroupForAdd = group;
      },
      groupRemoved() {
        this.selectedGroupForAdd = {};
      },
      openRemoveModal(group) {
        this.selectedGroupForRemoval = group;
        this.$refs.removeUserFromGroupModal.open();
      },
      checkContributorId(companyUser) {
        return this.currentCompanyUser ? this.currentCompanyUser.contributorId : companyUser.contributorId;
      },
      addToGroup(companyUser) {
        if ("contributorId" in this.selectedGroupForAdd) {
          const userContributorId = this.checkContributorId(companyUser);
          const params = { companyUserContributorId: userContributorId, id: this.selectedGroupForAdd.id };
          this.isSaving = true;
          http
            .post(`/group_members.json`, params)
            .then(({ data: { permissions, group }}) => {
              this.emitSuccess(`Teammate successfully added to ${this.selectedGroupForAdd.name}`);
              if (this.currentCompanyUser) {
                this.currentCompanyUser.groups.push(group);
                this.currentCompanyUser.permissions = permissions;
              }
            })
            .catch(() => {
              this.emitError(`Sorry, there was an error adding user to group ${this.selectedGroupForAdd.name}`);
            })
            .finally(() => {
              this.selectedGroupForAdd = {};
              this.isSaving = false;
            });
        }
      },
      removeFromGroup() {
        const groupIndex = _findIndex(this.currentCompanyUser.groups, { id: this.selectedGroupForRemoval.id });
        this.isRemoving = true;
        http
          .delete(`/group/${this.selectedGroupForRemoval.id}/group_members/${this.currentCompanyUser.contributorId}`)
          .then(({ data: { permissions }}) => {
            this.emitSuccess(`Teammate successfully removed from ${this.selectedGroupForRemoval.name}`);
            this.currentCompanyUser.groups.splice(groupIndex, 1);
            this.currentCompanyUser.permissions = permissions;
          })
          .catch((error) => {
            const message = error.response.data.message ? error.response.data.message : error.message;
            this.emitError(`Sorry, there was an error removing user from group ${this.selectedGroupForRemoval.name}. ${message}`);
          })
          .finally(() => {
            this.closeRemoveModal();
          });
      },
      closeRemoveModal() {
        this.isRemoving = false;
        this.selectedGroupForRemoval = {};
        this.$refs.removeUserFromGroupModal.close();
      },
    },
  };
</script>
