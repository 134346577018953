import { titleCase } from "title-case";
import DOMPurify from "dompurify";

export default {
  methods: {
    singularize(word) {
      const endings = {
        ves: 'fe',
        ies: 'y',
        es: 'e',
        ses: 's',
        zes: 'ze',
        s: '',
      };

      const foundEnding = Object.keys(endings).find(ending => word.endsWith(ending));
      return foundEnding ? word.slice(0, -foundEnding.length) + endings[foundEnding] : word;
    },
    stringifyNumber(n) {
      const special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
      const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

      if (n < 20) return special[n];
      if (n%10 === 0) return `${deca[Math.floor(n/10)-2]  }ieth`;
      return `${deca[Math.floor(n/10)-2]  }y-${  special[n%10]}`;
    },
    toSnakecase(str) {
      if (!str) {
        return '';
      }
      return str.toLowerCase().split(' ').join('_');
    },
    toHyphenCase(str) {
      if (!str) {
        return '';
      }
      return str.toLowerCase().split(/[ _]+/).join("-");
    },
    countCharacter(str, c) {
      let result = 0;
      for (let i = 0; i < str.length; i += 1) {
        if (str[i] === c) result += 1;
      }
      return result;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    currentFormatedDate() {
      return moment().format('YYYY-MM-DD');
    },
    toTitle(value) {
      let title = '';
      if (value && typeof value === 'string') {
        const parts = value.split('_');
        parts.forEach(part => {
          const p = `${part.charAt(0).toUpperCase()}${part.substring(1)}`;
          if (title.length > 0) {
            title += ` ${p}`;
          } else {
            title += p;
          }
        });
      }
      return title;
    },
    titleize(sentence) {
      return titleCase(sentence);
    },
    convertToCamelCase(str) {
      return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
    },
    truncate(value, length) {
      if (!value) {
        return "";
      }
      const strValue = typeof value === 'string' ? value : String(value);
      if (strValue.length <= length) {
        return strValue;
      }
      const spaceChar = /^\s+$/;
      const shortValue = strValue.substring(0, length);
      if (strValue[length].match(spaceChar)) {
        return shortValue.trim();
      }
      const spaceIdx = shortValue.lastIndexOf(" ");
      if (spaceIdx > 5) {
        return `${shortValue.substring(0, spaceIdx)}...`;
      }
      return `${shortValue}...`;
    },
    humanizeWord(word) {
      const result = word.replace(/([A-Z])/g,' $1');
      const humanizedString = result.charAt(0).toUpperCase() + result.slice(1);
      return humanizedString;
    },
    snakeToHumanize(string) {
      let humanString = string.replace(/_/g, ' ');
      humanString = humanString.charAt(0).toUpperCase() + humanString.slice(1);
      return humanString;
    },
    validateEmail(email) {
      const idx  = email.indexOf("@");
      if (idx === -1) {
        return false;
      }
      const userName = email.substring(0, idx);
      const domain = email.substring(idx + 1);
      return this.validateUserName(userName) && this.validateDomain(domain);
    },
    validateUserName(userName) {
      const re = /^([a-z0-9A-Z-+._']+)$/;
      return re.test(userName);
    },
    validateDomain(domain) {
      const re = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?$/;
      return re.test(domain);
    },
    decodeHTML(htmlString) {
      const parser = new DOMParser();
      const decodedDocument = parser.parseFromString(htmlString, 'text/html');
      return decodedDocument.body.textContent;
    },
    readableArray(arr, limit) {
      if (limit && arr.length > limit) {
        return `${arr.slice(0,limit).join(", ")}, +${arr.length - limit} more`;
      }
      return arr.join(", ");
    },
    sanitizeHTML(text) {
      return DOMPurify.sanitize(text);
    },
    validateCustomEmail(email) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      return emailRegex.test(email);
    },
    isUnderMaxLength(str, maxLength) {
      return str ? str.length <= maxLength : false;
    },
  },
};
