import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('GlobalStore', ['setNestedModalOpened']),
    nestedModalOpened() {
      this.$nextTick(() => {
        const elem = document.querySelector(".sweet-modal-overlay .is-visible");
        if (elem) {
          elem.scrollIntoView({behavior: "smooth", block: "start"});
        }
        this.setNestedModalOpened(true);
      })
    },
    nestedModalClosed() {
      this.$nextTick(() => {
        this.setNestedModalOpened(false);
      })
    }
  }
};
