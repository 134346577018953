<template>
  <smart-list
    ref="smartList"
    :field="field"
    :value="value"
    :options-url="optionsUrl"
    :required="required"
    :disabled="disabled"
    :is-survey-module="isSurveyModule"
    plural-name="vendors"
    single-name="a vendor"
    @input="emitInput"
    @open="openLink"
  >
    <template slot="label">
      <slot name="label" />
    </template>

    <template #image="{ option }">
      <vendor-logo :product="option" />
    </template>
  </smart-list>
</template>

<script>
import VendorLogo from 'components/shared/product_image.vue';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import SmartList from './smart_list.vue';

export default {
  components: {
    SmartList,
    VendorLogo,
  },
  mixins: [fieldViewerInput, permissionsHelper],
  computed: {
    optionsUrl() {
      if (this.field.customFormId) {
        return `/custom_forms/${this.field.customFormId}/vendor_options.json`;
      }
      return `/vendor_options.json`;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.emitInput(this.value);
    },
    emitInput(ids) {
      this.$emit('input', ids);
    },
    showErrors() {
      return this.$refs.smartList.showErrors();
    },
    openLink(resource) {
      window.open(`/vendors/${resource.id}`, "_blank");
    },
  },
};
</script>
