/* eslint-disable no-return-assign */
import http from "common/http";

export default ({
  strict: process.env.NODE_ENV === "development",

  state: {
    moduleName: "Articles",
    articles: null,
    article: {
      title: '',
      tags: [],
      category: '',
      locationId: '',
      body: '',
      document: [],
      companyId: '',
      companyUserId: '',
      reviewDate: '',
      articlePrivileges: [],
    },
    articlesPage: 0,
    articlesPerPage: 10,
    articlesPageCount: 0,
    articleLoadingStatus: false,
    pageCount: 0,
  },

  mutations: {
    setArticle: (state, article) => (state.article = article),
    setArticles: (state, articles) => (state.articles = articles),
    setArticlesPage(state, articlesPage) {
      state.articlesPage = articlesPage;
    },
    setArticlesPageCount: (state, articlesPageCount) =>
      (state.articlesPageCount = articlesPageCount),
    setPageCount: (state, pageCount) => (state.pageCount = pageCount),
    setArticleLoadingStatus: (state, value) => (state.articleLoadingStatus = value),
  },

  getters: {
    article: (state) => state.article,
    articles: (state) => state.articles,
    articlesPage: (state) => state.articlesPage,
    articlesPageCount: (state) => state.articlesPageCount,
    articleLoadingStatus: state => state.articleLoadingStatus,
    pageCount: (state) => state.pageCount,
  },
  actions: {
    fetchArticle({ commit }, id) {
      commit("setArticleLoadingStatus", true);
      return http
        .get(`/articles/${id}.json`)
        .then((res) => {
          commit("setArticle", res.data);
          commit("setArticleLoadingStatus", false);
        });
    },
    fetchArticles({ commit, state }, attrs = {}) {
      commit("setArticleLoadingStatus", true);
      const params = { page: state.articlesPage + 1, per_page: state.articlesPerPage };
      if (attrs.search) {
        params.search_terms = attrs.search;
      }
      if (attrs.category) {
        params.category = attrs.category;
      }

      if (attrs.isBasicAccess) {
        params.is_basic_read = attrs.isBasicAccess;
      }

      if (attrs.locationId) {
        params.location_id = attrs.locationId;
      }
      // eslint-disable-next-line no-use-before-define
      const url = this.companyId ?  `/companies/${this.companyId}${url}` :  '/articles.json';
      return http
          .get(url, { params })
          .then((res) => {
            commit("setArticles", res.data.articles);
            commit("setArticleLoadingStatus", false);
            commit("setArticlesPageCount", res.data.pageCount);
          });
    },
    createArticle({ commit }, attrs) {
      return http
        .post(`/articles.json`, {
          article: attrs,
        })
        .then((res) => {
          commit("setArticles", res.data.articles);
        });
    },
    saveArticle({ commit }, attrs) {
      if (!window.$company) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return http
        .put(`/articles/${attrs.slug}.json`, {
          article: attrs,
        })
        .then((res) => {
          commit("setArticles", res.data.articles);
        });
    },
  },
});
