export default {
  computed: {
    currentTz() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
  },
  methods: {
    toISOstring(date) {
      return moment(new Date(date).toISOString());
    },
    daydiff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    showDate(date) {
      if (date) {
        return moment(date.toString()).format('MMM DD, YYYY');
      }
      return null;
    },
    showFullDate(date) {
      if (!date) {
        return null;
      }
      return moment(date.toString()).format("MMMM DD, YYYY");
    },
    showDateCondensed(date) {
      return moment(date.toString()).format('MM/DD');
    },
    showDateTime(date) {
      return moment(date.toString()).format('h:mm a, MMM DD, YYYY');
    },
    daysUntilToday(futureDate) {
      return moment(futureDate).startOf('day').diff(moment().startOf('day'), 'days');
    },
    toDaysAndHours(hoursString) {
      const uptimeHours = parseInt(hoursString.replace(/ Hours/gi, ''), 10);
      const days = Math.floor(uptimeHours / 24);
      const hours = uptimeHours % 24;
      const dayPart = days > 0 ? `${days} Day(s)` : '';
      const hourPart = hours > 0 ? `${hours} Hour(s)` : '';

      return [dayPart, hourPart].filter(Boolean).join(', ') || '0 Hour(s)';
    },
  },
  monthsArr: {
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  },
};
