<template>
  <div class="w-100">
    <multi-select
      :id="id"
      label="name"
      class="w-100"
      :name="name"
      track-by="id"
      :placeholder="currentPlaceholder"
      :allow-empty="allowEmpty"      
      :multiple="false"
      :options="optionsWithAdd"
      :taggable="false"
      :value="value"
      :show-labels="false"
      :group-select="false"
      :disabled="disabled"
      @remove="removeGroup"
      @select="selectGroup"
      @open="openOptions"
      @search-change="searchQuery"
    >
      <template
        slot="singleLabel"
        slot-scope="props"
      >
        <div class="row">
          <div
            v-if="value && value.id"
            class="d-inline-block"
            :class="avatarCss"
          >
            <user-avatar
              :username="objName(props.option)"
              :src="props.option.avatarThumbUrl"
              :size="selectSize"
            />
          </div>
          <div
            class="pl-0 d-inline-block"
            :class="nameCss"
          >
            <span
              class="avatar-name d-inline-block not-as-small"
              :class="{'text-muted': objName(props.option) == 'Select Group'}"
            >
              {{ objName(props.option) }}
            </span>
            <span
              v-if="!compact"
              class="avatar-email d-inline-block not-as-small"
            >
              Group
            </span>
          </div>
        </div>
      </template>

      <template
        slot="option"
        slot-scope="props"
      >
        <div class="row mr-0">
          <div
            v-if="props.option.id"
            class="col-auto d-inline-block"
          >
            <user-avatar
              :username="objName(props.option)"
              :src="props.option.avatarThumbUrl"
              :size="selectSize"
              class="logo-outline"
            />
          </div>
          <div
            class="col pl-0 d-inline-block"
            :class="{'pl-4': !props.option.id}"
          >
            <span
              class="avatar-name d-block not-as-small"
            >
              {{ objName(props.option) }}
            </span>
            <span
              v-if="props.option.name && props.option.type && props.option.type == 'Group'"
              class="avatar-email d-block not-as-small"
            >
              Group
            </span>
          </div>
        </div>
      </template>

      <template
        v-if="displayShowMore"
        slot="afterList"
      >
        <div
          class="not-as-small py-3 cursor-pointer text-center"
          style="padding-left: .75rem !important;"
          @click="showMore"
        >
          <a href="#">+ Show More</a>
        </div>
      </template>

      <template
        v-if="loading"
        slot="caret"
      >
        <clip-loader
          loading
          class="position-absolute"
          color="#000"
          size="1.25rem"
          style="right: 10px; top: 10px;"
        />
      </template>
    </multi-select>
  </div>
</template>

<script>
import http from "common/http";
import _debounce from "lodash/debounce";
import clipLoader from 'vue-spinner/src/ClipLoader.vue';
import MultiSelect from 'vue-multiselect';
import { Avatar as UserAvatar } from 'vue-avatar';
import _get from 'lodash/get';

export default {
  components: {
    clipLoader,
    MultiSelect,
    UserAvatar,
  },
  props: {
    allowEmpty: {
      type: Boolean,
      required: false,
      default: true,
    },
    id: {
      type: String,
      required: false,
      default: 'groups',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select Group',
    },
    value: {
      type: Object,
      default: () => {},
      required: false,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    excludes: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    withPermissions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      query: null,
      offset: 0,
      disableShowMore: false,
      pageSize: 10,
      loading: false,
    };
  },
  computed: {
    optionsWithAdd() {
      return this.options;
    },
    currentPlaceholder() {
      if (this.loading) {
        return "Loading...";
      }
      return this.placeholder;
    },
    incompleteValues() {
      if (!this.value || (typeof(this.value) === Array && this.value.length === 0) || (typeof(this.value) === Object && !this.value.id)) {
        return [];
      }
      if (typeof(this.value) === Array) {
        return this.value.select((v) => !v.name);
      }
      return this.value.name ? [this.value] : [];
    },
    displayShowMore() {
      return !this.disableShowMore;
    },
    nameCss() {
      const classes = `${_get(this, 'value.id') ? "" : "pl-3"}`;
      if (this.compact) {
        return `col-10 ${classes}`;
      }
      return `col-9 ${classes}`;
    },
    avatarCss() {
      if (this.compact) {
        return "col-auto";
      }
      return "col-3";
    },
    selectSize() {
      if (this.compact) {
        return 20;
      }
        return 30;
    },
  },
  methods: {
    showMore() {
      if (!this.options || this.options.length === 0) {
        this.offset = 0;
      } else {
        this.offset += this.pageSize;
      }
      this.loadMore();
    },
    openOptions() {
      this.disableShowMore = true;
      this.options = [];
      this.offset = 0;
      this.loadMore();
    },
    objName(obj) {
      return obj.name || this.placeholder;
    },
    selectGroup(obj) {
      this.$emit('select', obj);
    },
    removeGroup(obj) {
      this.$emit('remove', obj);
    },
    loadMore() {
      const params = { offset: this.offset, limit: this.pageSize };
      if (this.query && this.query.length > 0) {
        params.query = this.query;
      }
      let ids = [];
      if (this.value) {
        for (let idx = 0; idx < this.value.length; idx += 1) {
          ids.push(this.value[idx].id);
        }
      }
      if (this.excludes && this.excludes.length > 0) {
        ids = ids.concat(this.excludes);
      }

      params.excludes = ids;
      params.with_permissions = this.withPermissions;
      this.fetchOptions(params);
    },
    fetchOptions(params) {
      this.loading = true;
      http
        .get("/group_options.json", { params })
        .then((res) => {
          this.handleOptionResults(this.offset, res.data);
        })
        .catch(() => {
          this.emitError(
            `Sorry, there was an error loading user/group options. Please try again later.`
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOptionResults(offset, data) {
      this.showMoreLoading = false;
      if (offset === 0) {
        this.options = data.groups;
      } else {
        this.options = this.options.concat(data.groups);
      }
      const userRouteNames = ['new-custom-forms', 'edit-custom-forms'];
      if (userRouteNames.includes(this.$route.name)) {
        const excludedNames = this.groups.map(exclude => exclude.name);
        this.options = this.options.filter(op => !excludedNames.includes(op.name));
      } else {
        this.options = this.options.filter(op => op.name !== 'Everyone');
      }
      if (data.groups.length < this.pageSize) {
        this.disableShowMore = true;
      } else {
        this.disableShowMore = false;
      }
    },
    searchQuery(query) {
      if (this.query !== query) {
        this.loading = true;
        this.asyncFind(query);
      }
    },
    asyncFind: _debounce(function (query) {
        this.query = query;
        this.offset = 0;
        this.loadMore();
    }, 700),
  },
};
</script>

<style lang="scss" scoped>
  .multiselect {
    :deep(.multiselect__tags) {
      border-radius: $border-radius;
      border-color: $themed-fair;
    }
  }

  .multiselect__tags {
    border-color: $themed-fair;
  }

  .multiselect__tag {
    overflow: inherit !important;
    white-space: unset;
  }

  .avatar-name {
    color: $themed-base;
    font-weight: 500;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .multiselect__option--highlight & {
      color: white;
    }
  }

  .avatar-email {
    color: $text-muted;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .multiselect__option--highlight & {
      color: white;
    }
  }

  .multiselect {
    :deep(.multiselect__single) {
      margin-bottom: 0px;
    }
  }
</style>
