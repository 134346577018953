<template>
  <div>
    <nice-datepicker
      v-model="valueStr"
      :name="field.label"
      :header-text="field.label"
      is-clearable
      :disabled="disabled"
      @input="emitValue"
    />
    <input
      ref="input"
      type="text"
      name="date"
      class="hidden"
      :required="required"
      :value="valueStr"
    >
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import NiceDatepicker from "components/shared/nice_datepicker.vue";
import _get from 'lodash/get';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';

export default {
  components: {
    NiceDatepicker
  },
  mixins: [fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
    }, 
    value: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      valueStr: _get(this, "value", ""),
    };
  },
  methods: {
    onWorkspaceChange() {
      this.setValueStr();
    },
    emitValue(val) {
      this.errors = null;
      this.$emit("input", val ? moment(val, this.currentTz).format("YYYY-MM-DD") : "");
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName != "automatedTasks") {
        return false;
      }
      if (!this.valueStr) {
        msg = `Please select a date`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>
