import http from 'common/http';
import { mapMutations, mapGetters } from 'vuex';

export const removeUnusedAttachments = {
  computed: {
    ...mapGetters([
      'haveUnsavedAttachments',
      'unsavedAttachmentIds',
      'attachmentsSaved'
    ]),
  },
  methods: {
    ...mapMutations([
      'setHaveUnsavedAttachments',
      'setUnsavedAttachmentIds',
      'setAttachmentsSaved'
    ]),
    removeAttachments() {
      const params = { attachment_ids: this.unsavedAttachmentIds };
      http
        .delete(`/attachment_uploads/bulk_delete_attachments.json`, { params })
    },
    resetValues() {
      this.setUnsavedAttachmentIds([]);
      this.setHaveUnsavedAttachments(false);
      this.setAttachmentsSaved(false);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.attachmentsSaved) {
      this.resetValues();
      next();
    } else if (this.haveUnsavedAttachments) {
      this.removeAttachments(this.unsavedAttachmentIds);
      this.resetValues();
      next(true);
    } else {
      this.resetValues();
      next();
    }
  },
}

export const watchUnusedAttachments = {
  watch: {
    attachmentIds: {
      deep: true,
      handler(newValue, oldValue) {
        this.$emit("check-unsaved-attachments", { attachmentIds: this.attachmentIds });
      }
    },
  }
}

export const checkUnusedAttachments = {
  methods: {
    ...mapMutations([
      'setHaveUnsavedAttachments',
      'setUnsavedAttachmentIds',
      'setAttachmentsSaved'
    ]),
    checkUnusedAttachments(obj) {
      if (obj.attachmentIds.length > 0) {
        this.setUnsavedAttachmentIds(obj.attachmentIds);
        this.setHaveUnsavedAttachments(true);
      } else {
        this.setHaveUnsavedAttachments(false);
      }
    },
  }
}
