<template>
  <div class="d-flex justify-content-center">
    <div class="verification-input-section">
      <input
        v-for="input in inputsArray"
        :key="input.id"
        :ref="input.name"
        type="text"
        placeholder=" "
        :value="input.value"
        @input="assignThenFocusNext($event, input)"
        @keydown.right="focusNext($event, input)"
        @keydown.left="focusPrev($event, input)"
        @keydown.backspace="deleteThenFocusPrev($event, input)"
        @keydown.delete="deleteThenFocusNext($event, input)"
      >
    </div>
  </div>
</template>

<script>
import permissionsHelper from 'mixins/permissions_helper';

export default {
  mixins: [permissionsHelper],
  data() {
    return {
      inputsArray: [
        { id: 0, name: "input1", value: null, next: "input2", prev: null },
        { id: 1, name: "input2", value: null, next: "input3", prev: "input1" },
        { id: 2, name: "input3", value: null, next: "input4", prev: "input2" },
        { id: 3, name: "input4", value: null, next: "input5", prev: "input3" },
        { id: 4, name: "input5", value: null, next: "input6", prev: "input4" },
        { id: 5, name: "input6", value: null, next: null, prev: "input5" }
      ]
    };
  },
  methods: {
    onWorkspaceChange() {
      this.focusFirstInput();
    },
    assignThenFocusNext(e, input) {
      if (e.target.value && !this.valueExist(input)) {
        if (e.target.value.length > 1) {
          let fullString = e.target.value;
          this.populateAllFields(fullString, input);
        } else if (!this.valueExist(input)) {
          this.setInputValue(input, e.target.value);
          if (this.$refs[input.next])
            this.$refs[input.next][0].focus();
          else
            this.focusSubmitButton();
        }
      } else {
        this.setInputValue(input, input.value);
        e.preventDefault();
      }
    },
    focusNext(e, input) {
      if (this.$refs[input.next])
        this.$refs[input.next][0].focus();
    },
    focusPrev(e, input) {
      if (this.$refs[input.prev])
        this.$refs[input.prev][0].focus();
    },
    populateAllFields(fullString, inputfield) {
      let input = inputfield;
      for (let index = 0; index < fullString.length; index++) {
        this.setInputValue(input, fullString[index]);
        if (this.$refs[input.next]) {
          this.$refs[input.next][0].focus();
          input = this.inputsArray[input.id + 1];
        } else {
          this.focusSubmitButton();
          break;
        }
      }
    },
    focusSubmitButton() {
      this.$emit("submit");
    },
    deleteThenFocusPrev(e, input) {
      e.preventDefault();
      this.setInputValue(input, null);
      if (this.$refs[input.prev])
        this.$refs[input.prev][0].focus();
    },
    getValue() {
      let value = "";
      this.inputsArray.forEach(input => {
        value = value.concat(this.$refs[input.name][0].value);
      });
      return value;
    },
    deleteThenFocusNext(e, input) {
      e.preventDefault();
      // @keydown.delete captures both “Delete” and “Backspace” keys
      if (e.key == "Delete") {
        this.setInputValue(input, null);
        if (this.$refs[input.next])
          this.$refs[input.next][0].focus();
      }
    },
    reset() {
      this.inputsArray.forEach(input => {
        this.setInputValue(input, null);
      });
      this.focusFirstInput();
    },
    focusFirstInput() {
      this.$refs[this.inputsArray[0].name][0].focus();
    },
    focusLastInput(e) {
      let lastField = this.inputsArray.length - 1;
      this.deleteThenFocusPrev(e, this.inputsArray[lastField]);
    },
    findInput(name) {
      return this.inputsArray.find(input => input.name == name);
    },
    valueExist(input) {
      return this.findInput(input.name).value;
    },
    setInputValue(input, value) {
      this.$refs[input.name][0].value = value;
      let field = this.findInput(input.name);
      field.value = value;
    },
  }
};
</script>

<style lang="scss" scoped>
.verification-input-section {
  input {
    text-align: center;
    line-height: 41px;
    font-size: 30px;
    color: #6d87a9;
    border: solid 1px $gray-500;
    box-shadow: 0 0 3px #ccc inset;
    outline: none;
    width: 41px;
    padding-top: 7px;
    transition: 0.2s;
    caret-color: transparent;
    &:nth-child(3) {
      margin-right: 30px !important;
    }
    &:nth-child(1),
    &:nth-child(4) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:nth-child(3),
    &:nth-child(6) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:nth-child(2),
    &:nth-child(5) {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    &::selection {
      background: transparent;
    }

    &:focus {
      border-color: $primary;

      &::placeholder {
        color: #6d87a9;
        background-image: linear-gradient($primary, $primary);
        transform: translateY(-4px);
      }

      &:not(:placeholder-shown) {
        caret-color: #6d87a9;
      }
    }

    &::placeholder {
      color: transparent;
      background-image: linear-gradient(transparent, transparent);
      background-size: 55% 1px;
      background-position: bottom;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in-out;
      transform: translateY(10px);
    }

    &:not(:placeholder-shown) {
      border-color: $primary;
    }
  }
}
</style>
