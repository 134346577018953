import permissions from "mixins/custom_forms/permissions";

export default {
  mixins: [permissions],
  computed: {
    isWritableObject() {
      // If the object is defined, then we treat this as though we are editing
      // the object
      if (typeof this.object !== 'undefined') {
        return this.isWritableFromCustomFormObject(this.object);
      }
      // Otherwise, we assume we are creating a new object
      return this.isWrite || this.isScoped || this.isBasicAccess;
    },
    canEdit() {
      return (!this.field.disabled && this.isWritableObject) || this.$router.options.base === '/help_center';
    },
  },
};
