import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').attributes.content.value,
    'X-Key-Inflection': 'camel',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const company = getCompanyFromStorage();
    const workspace = getWorkspaceFromStorage();
    let companyId; 
    let workspaceId;
    if (company) {
      companyId = company.id;
    } else if (Vue.prototype.$currentCompanyId) {
      companyId = Vue.prototype.$currentCompanyId;
    } else {
      const cookies = document.cookie.split(";");
      cookies.forEach((cookie) => {
        if (cookie.includes("company")) {
          const parts = cookie.split("=");
          companyId = parseInt(parts[1], 10);
        }
      });
    }

    if (!workspace) {
      const cookies = document.cookie.split("; ");
      const workspaceCookie = cookies.find((cookie) => cookie.startsWith("workspace"));
      if (workspaceCookie) {
        workspaceId = parseInt(workspaceCookie.split("=")[1], 10);
      }
    }

    if (companyId) {
      config.headers['X-Genuity-Company-Id'] = `${companyId}`;
    }
    if (workspace || workspaceId) {
      config.headers['X-Genuity-Workspace-Id'] = `${workspace?.id || workspaceId}`;
    }
    return config;
  }, (error) => Promise.reject(error));

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if ( error.response !== undefined && error.response.status === 401 && error.response.data?.error === "Your session expired. Please sign in again to continue.") {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const subdomain = url.hostname.split('.')[0]; 
    const cookieValue = `${path},${subdomain}`; 
    document.cookie = `last-visited-page=${encodeURIComponent(cookieValue)}; path=/;`;
    const modal = document.getElementById("sessionTimeoutModal");
    modal.style.display = "block";
    return new Promise(() => {});
  } else if (!error.response && error.message === "Network Error") {
    const modal = document.getElementById("networkErrorModal");
    modal.style.display = "block";
    return new Promise(() => {});
  } 
  return Promise.reject(error);
});

export default axiosInstance;
