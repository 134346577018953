<template>
  <div>
    {{ field.defaultValue }}
  </div>
</template>

<script>
import permissionsHelper from 'mixins/permissions_helper';

export default {
  mixins: [permissionsHelper],
  props: ['field', 'value'],
  methods: {
    onWorkspaceChange() {
      this.$emit("input", this.value);
    },
    showErrors() {
      return false;
    },
  },
}
</script>
