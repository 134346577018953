import Pusher from 'vue-pusher';
import http from './http';

export default http.get('/pusher_lookup').then((res) => {
  if (res.data.apiKey) {
    Vue.use(Pusher, {
      api_key: res.data.apiKey,
      options: {
        cluster: 'us2',
        encrypted: true
      }
    });

    const pusherInit = new CustomEvent("pusher-init", { detail: {} });
    document.dispatchEvent(pusherInit);
  }
});
