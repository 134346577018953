import _get from "lodash/get";

export default {
  data() {
    return {
      showHelpdesk: false,
      changeKey: null,
      forceMount: false,
      _isMounted: false,
      canAutomate: false,
      canReport: false,
      isHelpDesk: false,
      isVendors: false,
      isTelecom: false,
      isAssets: false,
      isBasicRead: false,
      isContracts: false,
      isCompany: false,
      isCompanyWrite: false,
      isRead: false,
      isScoped: false,
      isReadScoped: false,
      isWrite: false,
      onlyBasicRead: false,
      isSuperAdmin: false,
      permissions: null,
      moduleParams: {
        "HelpTicket": "helpdesk",
      },
      isPermissionsLoading: true,
    };
  },
  mounted() {
    this._isMounted = true;
    let company = getCompanyFromStorage();
    let onCompanyChange = () => {
      if (!this._isMounted || this._isBeingDestroyed) {
        document.removeEventListener("company-change", onCompanyChange);
        return;
      }
      this.triggerWorkspaceChange();
    };
    document.addEventListener('company-change', onCompanyChange);

    if (!company) {
      return;
    }
    this.initPermissionValues();

    this.triggerWorkspaceChange();
  },
  unmounted() {
    this._isMounted = false;
  },
  computed: {
    moduleName() {
      const moduleNames = {
        "help_tickets": "HelpTicket",
        "tickets": "HelpTicket",
        "vendors": "Vendor",
        "telecom_providers": "TelecomService",
        "managed_assets": 'ManagedAsset',
        "contracts": "Contract",
        "company/users": "CompanyUser",
        "company/locations": "Location",
        "discovered_users": "CompanyUser",
        "related_companies": "CompanyUser",
        "profile": "CompanyUser",
      };
      return moduleNames[this.modulePrefix];
    },
    modulePrefix() {
      let pathname = document.location.pathname;
      pathname = pathname.substring(1);
      const idx = pathname.indexOf("/");
      if (idx < 0) {
        return pathname;
      }
      if (pathname.startsWith("company/")) {
        const idy = pathname.lastIndexOf("/");
        return pathname.substring(0, idy);
      }
      return pathname.substring(0, idx);
    },
    isReadAny() {
      return this.isRead || this.isReadScoped;
    },
    isWriteAny() {
      return this.isScopedAny || this.isWrite;
    },
    isOnlyBasicRead() {
      return this.onlyBasicRead;
    },
    isScopedAny() {
      return this.isReadScoped || this.isScoped;
    },
    isBasicAccess() {
      return this.isBasicRead;
    },
  },
  methods: {
    initPermissionValues() {
      const workspace = getWorkspaceFromStorage();
      if (!$permissions || (companyModule() === 'HelpTicket' && !workspace)) {
        return;
      }
      this.isPermissionsLoading = true;

      this.isSuperAdmin = $permissions["isSuperAdmin"];
      this.isReadScoped = this.isSpecificPermission('readscoped');
      this.isCompanyWrite = this.isSpecificPermission("write", "CompanyUser");
      this.isRead = this.isSpecificPermission('read');
      this.isScoped = this.isSpecificPermission('scoped') || this.isReadScoped;
      this.isBasicRead = this.isSpecificPermission('basicread');
      this.isWrite = this.$superAdminUser || this.isSpecificPermission('write');
      this.isHelpDesk = permissionIdx === "helpTicket";
      this.isVendors = permissionIdx === "vendor";
      this.isTelecom = permissionIdx === "telecomProvider";
      this.isAssets = permissionIdx === "managedAsset";
      this.isContracts = permissionIdx === "contract";
      this.isCompany = permissionIdx === "company" || this.modulePrefix === "discovered_users" ;
      this.canAutomate = this.isHelpDesk && this.isWrite;
      this.canReport = this.isHelpDesk || this.isContracts || this.isVendors;

      this.isPermissionsLoading = false;
    },
    triggerWorkspaceChange() {
      const workspace = getWorkspaceFromStorage();
      const company = getCompanyFromStorage();
      const pathName = document.location.href;
      const idx = pathName.indexOf("?");
      let queryString = "";
      if (idx > -1) {
        queryString = pathName.substring(idx);
      }
      const newKey = `${_get(company, "id", "_blank")}:${_get(workspace, "id", "_blank")}${queryString}`;
      if (!this.forceMount && (!workspacesLoaded || newKey === this.changeKey)) {
        return;
      }
      this.changeKey = newKey;
      if ($permissions) {
        this.permissions =  { ...$permissions };
      };
      this.initPermissionValues();
      if (typeof this.onWorkspaceChange !== "function") {
        return;
      }

      // If we're not active, then don't do anything.
      if (!this._isMounted || this._isBeingDestroyed) {
        return;
      }

      // If we don't have access to a workspace and we're in help desk, then
      // redirect the user to the main dashboard.
      const pathname = document.location.pathname;
      if ($permissions && this.$router) {
        let req_btn = document.getElementById('req-permission-btn');
        if (!pathname.endsWith("payment_methods") && !pathname.endsWith("denied") && (!$permissions[permissionIdx]) && !req_btn) {
          this.$router.push("/denied");
          return;
        }
        else if ($permissions[permissionIdx] && pathname.endsWith("denied")) {
          this.$router.push("/");
          return;
        }
      }

      if ((company && (companyModule() !== "HelpTicket" || (workspace || (workspaceOptions && workspaceOptions.length === 0)))) || this.forceMount) {
        this.onWorkspaceChange();
      }
    },
    checkForBasicRead(htPermissions) {
      for (let perm in htPermissions) {
        if (htPermissions[perm][0] !== 'basicread') {
          this.onlyBasicRead = false;
          this.showHelpdesk = true;
          return;
        }
      }
      const cookieData = document.cookie.split('; ').find(row => row.startsWith('nav-theme'));
      if (cookieData && cookieData.split('=')[1] === 'vertical') {
        this.setCookie('nav-theme', 'horizontal', true);
        document.body.classList.remove('module-vertical-nav');
      }
      this.onlyBasicRead = true;
      this.showHelpdesk = true;
    },
    setCookie(name, val, useRootDomain) {
      const shouldSetRootDomainWithSubdomains = useRootDomain && this.$rootDomain !== "localhost";
      const rootDomain = shouldSetRootDomainWithSubdomains ? `; domain=.${this.$rootDomain}` : '';

      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);

      const toSet = `${name}=${val}${rootDomain}; expires=${expirationDate.toUTCString()}; path=/`;
      document.cookie = toSet;
    },
    isSpecificPermission(permissionType, givenName = null, linkableWorkspaceId = null) {
      let givenModuleName = null;

      if (!$permissions) {
        return false;
      }
      if (!givenName) {
        givenModuleName = permissionIdx;
      } else {
        givenModuleName = givenName.charAt(0).toLowerCase() + givenName.substr(1);
      }
      if (!givenModuleName || !$permissions[givenModuleName]) {
        return false;
      }
      let derivedPerms = [];
      let modPerms;
      if (givenModuleName !== 'helpTicket') {
        modPerms = $permissions[givenModuleName]['root'];
      } else {
        const id = getWorkspaceFromStorage()?.id || linkableWorkspaceId;
        modPerms = $permissions[givenModuleName][id];
        if (permissionType === 'basicread') {
          this.checkForBasicRead($permissions.helpTicket);
        }
      }
      if (Array.isArray(modPerms)) {
        derivedPerms = modPerms;
      } else {
        return false;
      }
      return derivedPerms.includes(permissionType);
    },
    redirectOnWorkspaceChange(workspaceId, path) {
      const currentWorkspace = getWorkspaceFromStorage();
      if (currentWorkspace && workspaceId !== currentWorkspace.id) {
        this.$router.push(path);
      }
    },
  },
};
