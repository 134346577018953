<template>
  <div>
    <div data-tc-dropdown="select category">
      <input
        ref="input"
        class="hidden"
        type="text"
        name="category"
        :required="required"
        :value="value"
      >
      <multi-select
        :value="selectedCategory"
        tag-placeholder="Select Category"
        track-by="id"
        label="name"
        :multiple="false"
        :options="categories"
        taggable
        :allow-empty="!required"
        @select="emitValue"
        @remove="removeCategory"
        @open="fetchCategories"
      />
      <span
        v-if="errors"
        class="form-text small text-danger"
        :data-tc-article-error="errors"
      >
        {{ errors }}
      </span>
    </div>
    <div
      v-if="isWrite && !slaFields"
      class="text-muted smallest pt-1"
    >
      Want to add a new category?
      <a
        href="#"
        data-tc-link="add new category"
        @click.prevent.stop="openCategoryModal"
      >
        Add new
      </a>
    </div>
    <create-category-modal
      v-if="showCategoryModal"
      ref="createCategoryModal"
      @set-category="setNewCategory"
      @fetch-categories="fetchCategories"
      @close="closeCategoryModal"
    />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import http from "common/http";
import CreateCategoryModal from "../../create_category_modal.vue";

export default {
  components: {
    MultiSelect,
    CreateCategoryModal,
  },
  mixins: [fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    value: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    slaFields: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      categories: [],
      selectedCategory: null,
      showCategoryModal: false,
    };
  },
  watch: {
    value() {
      this.fetchCategories();
    },
  },
  mounted() {
    if (this.value) {
      this.fetchCategories();
    }
  },
  methods: {
    emitValue(value) {
      this.errors = null;
      this.$emit("input", value.name);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.selectedCategory) {
        msg = `Please select a ${this.field.label}`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
    fetchCategories() {
      if (this.categories && this.categories.length > 0) {
        this.setCategory();
        return;
      }
      http
        .get('/helpdesk_categories.json')
        .then(res => {
          this.categories = res.data;
          this.setCategory();
        })
        .catch(() => {
          this.emitError(`Sorry, there was an error fetching company categories. Please refresh the page and try again.`);
        });
    },
    setCategory() {
      if (this.value) {
        this.selectedCategory = this.categories.find((c) => c.name === this.value);
      }
    },
    setNewCategory(value) {
      this.selectedCategory = value;
      this.emitValue(value);
    },
    openCategoryModal() {
      this.showCategoryModal = true;
      this.$nextTick(() => {
        if (this.$refs.createCategoryModal) {
          this.$refs.createCategoryModal.open();
        }
      });
    },
    closeCategoryModal() {
      this.showCategoryModal = false;
    },
    removeCategory() {
      if (this.value) {
        this.selectedCategory = null;
        this.$emit('input', null);
      }
    },
  },
};
</script>
