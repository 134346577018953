  export const metrics = [
    {
      id: 1,
      order: 1,
      type: "priority",
      title: "Priority",
      friendlyName: "Priority",
      description: "Total number of tickets for the selected priority",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 2,
      order: 2,
      type: "status",
      title: "Status",
      friendlyName: "Status",
      description: "Total number of tickets for the selected status",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 3,
      order: 3,
      type: "source",
      title: "Source",
      friendlyName: "Source",
      description: "Total number of tickets for the selected source",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 4,
      order: 4,
      type: "category",
      title: "Category",
      friendlyName: "Category",
      description: "Total number of tickets for the selected category",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 5,
      order: 5,
      type: "ticketsFirstResponse",
      title: "Ticket First Response",
      friendlyName: "Ticket First Response",
      description: "Response status of the tickets",
      filters: [],
      group: 'sla',
      default: true,
    },
  ];

  export const allMetrics = [
    {
      id: 1,
      order: 1,
      type: "priority",
      title: "Priority",
      friendlyName: "Priority",
      description: "Total number of tickets for the selected priority",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 2,
      order: 2,
      type: "status",
      title: "Status",
      friendlyName: "Status",
      description: "Total number of tickets for the selected status",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 3,
      order: 3,
      type: "source",
      title: "Source",
      friendlyName: "Source",
      description: "Total number of tickets for the selected source",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 4,
      order: 4,
      type: "category",
      title: "Category",
      friendlyName: "Category",
      description: "Total number of tickets for the selected category",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 5,
      order: 5,
      type: "ticketsFirstResponse",
      title: "Ticket First Response",
      friendlyName: "Ticket First Response",
      description: "Response status of the tickets",
      filters: [],
      group: 'sla',
      default: true,
    },
    {
      id: 6,
      order: 6,
      type: "firstResponse",
      title: "SLA First Response",
      friendlyName: "SLA First Response",
      description: "Average response time",
      filters: [],
      group: 'sla',
      default: true,
    },
    {
      id: 7,
      order: 7,
      type: "timeOpen",
      title: "Time Open",
      friendlyName: "Time Open",
      description: "Duration for which the tickets remained opened",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 8,
      order: 8,
      type: "agent",
      title: "Agent",
      friendlyName: "Agent",
      description: "Tickets grouped by Agent",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 9,
      order: 9,
      type: "requester",
      title: "Requester",
      friendlyName: "Requester",
      description: "Tickets grouped by Requester",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 10,
      order: 10,
      type: "resolution",
      title: "Resolution",
      friendlyName: "Resolution",
      description: "Resolved status for tickets",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 11,
      order: 11,
      type: "survey",
      title: "Survey",
      friendlyName: "Survey",
      description: "Survey feedback for tickets",
      filters: [],
      group: 'common',
      default: true,
    },
    {
      id: 12,
      order: 12,
      type: "ticketsResponseTime",
      title: "Ticket Response Time",
      friendlyName: "Ticket Response Time",
      description: "Response time in responding to a ticket",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 13,
      order: 13,
      type: "responseTime",
      title: "SLA Response Time",
      friendlyName: "SLA Response Time",
      description: "SLA Response time in responding to a ticket",
      filters: [],
      group: 'sla',
      default: true,
    },
    {
      id: 14,
      order: 14,
      type: "ticketsAvgResponse",
      title: "Ticket Avg Response",
      friendlyName: "Ticket Avg Response",
      description: "Avg Response time in responding to a ticket",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 15,
      order: 15,
      type: "avgResponse",
      title: "SLA Avg Response",
      friendlyName: "SLA Avg Response",
      description: "SLA Avg Response time in responding to a ticket",
      filters: [],
      group: 'sla',
      default: true,
    },
    {
      id: 16,
      order: 16,
      type: "timeSpent",
      title: "Time Spent",
      friendlyName: "Time Spent",
      description: "Total time spent by an agent on tickets",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 17,
      order: 17,
      type: "resolvedWithinSla",
      title: "Resolved Within SLA",
      friendlyName: "Resolved Within SLA",
      description: "Whether or not the ticket has been resolved within SLA",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 18,
      order: 18,
      type: "firstResponseDue",
      title: "First Response Due By",
      friendlyName: "First Response Due By",
      description: "Tickets for which the response is due",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 19,
      order: 19,
      type: "ticketAge",
      title: "Ticket Age",
      friendlyName: "Ticket Age",
      description: "Time taken for a ticket to be resolved",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 20,
      order: 20,
      type: "timesReopened",
      title: "No. of Times Reopened",
      friendlyName: "No. of Times Reopened",
      description: "The number of times a ticket has been reopened",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 21,
      order: 21,
      type: "ticketsCreated",
      title: "Tickets Created",
      friendlyName: "Tickets Created",
      description: "The number of tickets created",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 22,
      order: 22,
      type: "ticketsClosed",
      title: "Tickets Closed",
      friendlyName: "Tickets Closed",
      description: "The number of tickets closed",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 23,
      order: 23,
      type: "ticketsReopened",
      title: "Tickets Reopened",
      friendlyName: "Tickets Reopened",
      description: "The number of tickets reopened",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 24,
      order: 24,
      type: "lastUpdated",
      title: "Tickets last Updated",
      friendlyName: "Tickets last Updated",
      description: "The number of tickets last updated",
      filters: [],
      group: 'timeframe',
      default: true,
    },
    {
      id: 25,
      order: 25,
      type: "firstAssignTime",
      title: "First Assign Time",
      friendlyName: "First Assign Time",
      description: "Time taken for the ticket to be first assigned",
      filters: [],
      group: 'timeframe',
      default: true,
    },
  ];

  export const groups = [
    {
      label: 'All Metrics',
      typeName: 'all',
    },
    {
      label: 'Common',
      typeName: 'common',
    },
    {
      label: 'Timeframe',
      typeName: 'timeframe',
    },
    {
      label: 'SLA',
      typeName: 'sla',
    },
  ];
  
  export const filters = [
    {
      name: "status",
      type: "string",
      values: [],
      displayName: "Status",
      description: "Total number of tickets for the selected status",
    },
    {
      name: "priority",
      type: "string",
      values: [],
      displayName: "Priority",
      description: "Total number of tickets for the selected priority",
    },
    {
      name: "comment_count",
      type: "integer",
      values: [],
      displayName: "Comment Count",
      description: "Total number of tickets with comment count between the provided range",
    },
    {
      name: "days_opened",
      type: "integer",
      values: [],
      displayName: "Days Opened",
      description: "Total number tickets opened for specific number of days",
    },
    {
      name: "subject",
      type: "string",
      values: [],
      displayName: "Subject",
      description: "Total number tickets with the provided subject",
    },
    {
      name: "date_range",
      type: "timestamp",
      values: [],
      displayName: "Date Range",
      description: "Total number tickets created within the provided range",
    },
  ];
