import http from 'common/http';

const mixinState = () => {
  return {
    // These are all of the open close flags
    documents: null,
    error: null,
    page: 1,
    sortBy: 'name_asc',
    searchTerm: null,
    perPage: 25,
    documentCount: 0,
    maxFileSize: 26214400,
    documentComp: false,
    isLoading: false,
  }
}

const mixinMutations = {
  setSortBy(state, sortBy) {
    state.sortBy = sortBy;
  },
  setDocuments(state, documents) {
    state.documents = documents
  },
  setDocumentCount(state, documentCount) {
    state.documentCount = documentCount
  },
  setError(state, error) {
    state.error = error;
  },
  setPage(state, page) {
    state.page = page;
  },
  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm;
  },
  setDocumentComp(state, documentComp) {
    state.documentComp = documentComp;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  }
}

const mixinGetters = {
  sortBy: state => state.sortBy,
  documents: state => state.documents,
  error: state => state.error,
  searchTerm: state => state.searchTerm,
  page: state => state.page,
  perPage: state => state.perPage,
  documentCount: state => state.documentCount,
  maxFileSize: state => state.maxFileSize,
  documentComp: state => state.documentComp,
  isLoading: state => state.isLoading,
}

const mixinActions = {
  fetchDocuments({ commit, getters, rootState }, options = {}) {
    const { offset, limit } = options;
    const params = {
      per_page: getters.perPage,
      page: getters.page,
    };
    if (getters.sortBy) {
      params.sort_by = getters.sortBy;
    }
    if (getters.searchTerm) {
      params.search_term = getters.searchTerm;
    }
    if (limit) {
      params.offset = offset;
      params.limit = limit;
    }
    if (!offset) {
      commit('setDocuments', null);
    }
    commit("setLoading", true);

    return http
      .get('/library_documents.json', { params: params })
      .then(res => {
        const existingDocuments = getters.documents || [];
        const updatedDocuments = existingDocuments.concat(res.data.documents);
        commit('setDocuments', updatedDocuments);
        commit('setDocumentCount', res.data.documentCount);
        commit('setLoading', false);
        if (res.data.documentCount > 0) {
          commit('setDocumentComp', true);
        }
        rootState.loadingStatus = false;
      })
      .catch(error => {
        commit('setError', `Sorry, there was an error gathering library documents. Please refresh the page and try again.`);
        rootState.loadingStatus = false;
        commit('setLoading', false);
      });
  },
  fetchMoreDocuments({ dispatch }, options = {}) {
    const { offset, limit } = options;
    dispatch('fetchDocuments', { offset, limit });
  },
};

export const libraryDocs = {
  state: () => mixinState(),
  getters: mixinGetters,
  mutations: mixinMutations,
  actions: mixinActions,
}
