<template>
  <div
    class="image-input text-center"
    :style="widthStyle"
  >
    <div :class="helpCenterImg ? 'area-wrap-hc' : 'area-wrap'" >
      <div :class="helpCenterImg ? 'area-hc' : 'area'" >
        <div
          v-if="isLoaded"
          class="drop-area"
          :class="{'drop-area--active': isDragActive}"
          :data-tc-attachment-image="modulePrefix"
          @click="onChange"
          @dragleave="onDragLeave"
          @dragover.prevent="onDragEnter"
          @drop="onDrop"
        >
          <label
            v-if="!src"
            class="drop__label text-center m-0"
          >
            Drop files here or <span class="text-underline">click to upload</span>.
            <span class="drop__sub-label mt-2 d-block">Recommend a 160x160 or larger square jpg or png.</span>
          </label>
          <input
            v-if="isWrite"
            ref="fileInput"
            accept="image/png, image/jpeg"
            class="drop__file-input"
            name="image"
            type="file"
            data-tc-input-file
            @click="$event.target.value=null"
            @change="onFileChange"
          >
          <img
            v-if="src"
            :src="src"
            alt=""
            class="drop__img"
            :style="{ maxWidth: helpCenterImg ? '150px' : '' }"
            >
        </div>
        <div
          v-else
          class="loader-area"
        >
          <clip-loader
            class="mx-auto"
            color="#0d6efd"
            size="2.5rem"
          />
        </div>
      </div>
    </div>
    <div
      v-if="inputError"
      class="text-center p-0 mx-0 mt-3"
    >
      <div class="text-danger">
        <i class="nulodgicon-alert-circled mr-2" />{{ inputError }}
      </div>
    </div>
    <div
      v-if="isWrite"
      class="mt-2"
    >
      <span
        v-tooltip="{
          content: 'You cannot remove default image',
          show: hoverIndex,
          trigger: 'manual',
          placement: 'bottom',
        }"
        @mouseover="hoverIndex = defaultImage"
        @mouseleave="hoverIndex = false"
      >
        <button
          v-if="isImage && !defaultEmailFormat"
          class="btn btn-sm btn-link"
          data-tc-remove
          :disabled="defaultImage"
          :data-tc-disable-remove="defaultImage"
          @click.prevent="removeFile"
        >
          Remove image
        </button>
      </span>
      <button
        v-if="isImage && allowCustomisation && !defaultEmailFormat"
        class="btn btn-sm btn-link"
        @click.prevent="customizeFile"
      >
        Customize
      </button>
      <button
        v-if="imageButtons"
        :class="{'mt-2': isImage}"
        class="btn btn-primary"
        @click="onChange"
      >
        <i class="nulodgicon-plus-round" /> Add a new image
      </button>
      <button
        v-if="imageButtons"
        class="btn btn-outline-primary mt-2 px-4"
        @click="onChange"
      >
        Choose an image
      </button>
    </div>
    <div v-if="allowCustomisation && dimensions">
      <sweet-modal
        ref="imageCustomizeModal"
        class="text-left"
        v-sweet-esc
        title="Customize Image"
        blocking
        width="30%"
        @close="imageCustomizeModalClose"
      >
        <template slot="default">
          <div>
            <h6 class="d-inline">Resize</h6>
            <span class="ml-1 text-muted small">
              (Recommended size is 100x100 pixels)
            </span>
            <div class="row">
              <div class="col">
                <input
                  class="form-control"
                  v-model="dimensions['width']"
                  type="number"
                  min="0"
                  placeholder="Width"
                />
              </div>
              <i class="nulodgicon-android-close my-2"/>
              <div class="col">
                <input
                  class="form-control"
                  v-model="dimensions['height']"
                  type="number"
                  min="0"
                  placeholder="Height"
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6>Reposition</h6>
            <div class="row">
              <div class="col-12">
                <select
                  class="form-control"
                  v-model="dimensions['imageAlignment']"
                >
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>
            </div>
          </div>
        </template>
        <button
          slot="button"
          class="btn btn-link text-secondary mr-2"
          @click.stop.prevent="imageCustomizeModalClose"
        >
          Cancel
        </button>
        <button
          slot="button"
          class="btn btn-primary"
          :disabled="false"
          @click.stop.prevent="saveDimensions"
        >
          Save
        </button>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
  import clipLoader from 'vue-spinner/src/ClipLoader.vue';
  import { SweetModal } from 'sweet-modal-vue';

  export default {
    components: {
      clipLoader,
      SweetModal,
    },
    props: {
      src: {},
      width: {},
      imageButtons: {},
      isLoaded: {
        default: true,
      },
      asFile: {
        type: Boolean,
        default: false,
      },
      defaultImage: {
        default: false,
      },
      defaultEmailFormat: {
        type: Boolean,
        default: false,
      },
      isWrite: {
        default: true,
      },
      modulePrefix: {
        default: "",
      },
      allowCustomisation: {
        type: Boolean,
        default: false,
      },
      dimensions: {
        type: Object,
        default: () => {},
      },
      helpCenterImg: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fileName: null,
        inputError: null,
        isDragActive: false,
        hoverIndex: false,
      };
    },
    computed: {
      isImage() {
        return !!this.src;
      },
      widthStyle() {
        return this.width ? `width: ${this.width};` : "";
      },
    },
    methods: {
      removeFile() {
        this.$emit('input', null, null);
        this.inputError = null;
        this.fileName = null;
      },
      customizeFile() {
        this.$refs.imageCustomizeModal.open();
      },
      onDrop(e) {
        e.stopPropagation();
        e.preventDefault();

        this.isDragActive = false;
        const file = e.dataTransfer.files[0];
        this.onFileChange({ currentTarget: { files: [file] } });
      },
      onChange() {
        this.$refs.fileInput.click();
        this.inputError = null;
      },
      saveDimensions() {
        this.$emit('save-dimensions', this.dimensions);
        this.imageCustomizeModalClose();
      },
      onFileChange(e) {
        if (!e.currentTarget) { return; }

        const file = e.currentTarget.files[0];
        if (file) {
          const size = file.size / 1000000;
          if ((file.type === "image/png" || file.type === "image/jpeg") && size <= 10) {
            const reader = new FileReader();
            this.fileName = file.name;
            reader.onload = () => {
              const dataURL = reader.result;
              if (this.asFile) {
                this.$emit("input", dataURL, this.fileName, file);
              } else {
                this.$emit("input", dataURL, this.fileName);
              }
            };
            reader.readAsDataURL(file);
          } else {
            this.inputError = "File must be a valid jpg or png and less than 10 mb.";
          }
        }
      },
      onDragEnter() {
        this.isDragActive = true;
      },
      onDragLeave() {
        this.isDragActive = false;
      },
      imageCustomizeModalClose() {
        if (this.$refs.imageCustomizeModal.visible) {
          this.$refs.imageCustomizeModal.close();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .image-input {
    max-width: 100%;
  }

  #dropzone {
    span {
      cursor: pointer;
      color: $cyan;
    }
  }

  .dz-details {
    background: $themed-box-bg;
    border: 1px dotted #e7e7e7;
    border-radius: $border-radius;
    font-style: italic;
    padding: 5px;
  }

  .drop__file-input {
    visibility: hidden;
  }

  .drop__label {
    color: $themed-muted;
    cursor: pointer;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 85%;

    > a {
      text-decoration: underline;
    }
  }

  .drop__sub-label {
    font-size: 80%;
    text-transform: none;
  }

  .drop__img {
    height: 100%;
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: auto;
  }

  .drop-area {
    background-color: $themed-box-bg;
    border: 2px dashed $themed-very-fair;
    border-radius: $border-radius-sm;
    color: #FFF;
    cursor: pointer;
    height: inherit;
    width: inherit;
    margin: auto;
    overflow: hidden;
    position: relative;
    transition: $transition-base;
    transition-property: border-color;
    vertical-align: middle;

    &:hover {
      border-color: $gray-500;
    }
  }

  .drop-area--active {
    border-color: $themed-link;
  }

  /* To ensure a square and responsive box, we're using absolute positioning and padding percents */
  .area-wrap {
    height: 0;
    position: relative;
    width: 100%;
    padding-bottom: 144px;
  }

  .area {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .loader-area {
    border: 2px dashed $themed-fair;
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
  }

  .nulodgicon-android-close:before {
    color: $gray-900 !important;
  }
  .area-wrap-hc {
    height: 6.25rem;
    width: 6.25rem;
    position: relative;
    width: 100%;
  }
  .area-hc {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 105%;
  }
</style>
