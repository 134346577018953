import http from "common/http";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      formOptions: null,
    };
  },
  methods: {
    ...mapMutations(["setSubmitRequestRoute"]),
    fetchFormOptions() {
      const params = {
        company_module: this.companyModule,
        active: true,
      };
      return http
        .get("/custom_form_options.json", { params })
        .then((res) => {
          this.formOptions = res.data;
        })
        .catch(() => {
          this.emitError(`Sorry, there was an error loading form options.`);
        });
    },
  },
};
