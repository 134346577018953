import dates from 'mixins/dates';
import http from 'common/http';
import { mapMutations } from 'vuex';

export default {
  mixins: [dates],
  methods: {
    ...mapMutations(['setHelpCenterSelectedArticle']),
    isWriteAnyArticle(article) {
      if (this.isSuperAdmin) {
        return true;
      }
      for (let index = 0; index < article.articleExpandedPrivileges?.length; index += 1) {
        const articleExpandedPrivilege = article.articleExpandedPrivileges[index];
        if (Number(this.$currentContributorId) === articleExpandedPrivilege.contributorId) {
          if(articleExpandedPrivilege.permissionType !== "read") {
            return articleExpandedPrivilege.permissionType;
          }
        }
      }
      return false;
    },
    updatedAt(article) {
      return article && article.updatedAt ? this.showDate(article.updatedAt) : null;
    },
    fetchPublicArticle(attrs = {}) {
      return http
        .get(`/knowledge_base/${attrs.slug}/${attrs.workspaceId}.json`)
        .then((res) => {
          this.setHelpCenterSelectedArticle(res.data);
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error fetching the article. ${error.response.data.message}`);
        });
    },
  },
};
