import { mapGetters } from "vuex";
import http from "common/http";
import _get from "lodash/get";
import _map from "lodash/map";
import customFormActions from "./custom_forms/actions.js";

export default {
  computed: {
    ...mapGetters("customForms", ["customForms"]),
    ids() {
      return _map(_get(this, "value", []), "valueInt");
    },
  },
  mixins: [customFormActions],
  methods: {
    addValue(item) {
      return this.addValueBase(item);
    },
    removeValue(item) {
      return this.addValueBase(item);
    },
    getField(object, name) {
      if (object.customForm) {
        return object.customForm.formFields.find(
          (field) => field.name === name
        );
      }
      return null;
    },
    getFieldsByType(object, type) {
      if (object.customForm) {
        return object.customForm.formFields.filter(
          (field) => field.fieldAttributeType === type
        );
      }
      return [];
    },
    getValuesForName(object, name) {
      const field = this.getField(object, name);
      if (!field) {
        return [];
      }
      return object.values.filter(
        (value) => value && value.customFormFieldId === field.id
      );
    },
    getValuesForField(object, field) {
      return object.values.filter(
        (value) => value && value.customFormFieldId === field.id
      );
      return [];
    },
    fetchField(formId, name, companyId) {
      const company = companyId ? `&company_id=${companyId}` : '';
      http
        .get(
          `/custom_form_fields/search.json?custom_form_id=${formId}&name=${name}${company}`
        )
        .then((res) => {
          this.field = res.data;
        })
        .catch((error) => {
          this.emitError("Sorry, there was an error loading field information.");
        });
    },
    updateField(field) {
      return http
        .put(
          `/custom_form_fields/${field.id}.json`, { field: field }
        )
        .catch((error) => {
          this.emitError(`Sorry, there was an error updating in ${field.name}.`);
        });
    },
  },
};
