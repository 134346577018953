<template>
  <div
    v-click-outside="onBlur"
    @click.prevent.stop="toggleList"
  >
    <vue-phone-number-input
      :id="id"
      ref="phoneNumberInput"
      v-model="phoneData.phoneNumber"
      valid-color="#ced4da"
      :default-country-code="countryCode"
      :disabled="disabled"
      :no-example="noExample"
      data-tc-phone-number-input-field
      @update="onUpdate"
    />
  </div>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  import PhoneFormatter from 'mixins/phone_number_formatter';
  import PhoneHelper from 'mixins/phone_number_input_helper';
  import permissionsHelper from 'mixins/permissions_helper';

  export default {
    components: {
      VuePhoneNumberInput,
    },
    mixins: [PhoneFormatter, PhoneHelper, permissionsHelper],
    props: ['model', 'id', 'customAttr', 'customId', 'disabled', 'noExample'],
    data() {
      return {
        phoneData: {},
      };
    },
    computed: {
      phone() {
        return this.phoneData.phoneNumber ? this.phoneData.formatNational : this.model.phone;
      },
      countryCode() {
        return this.phoneData.phoneNumber ? this.phoneData.countryCode : this.model.countryCode;
      },
    },
    mounted() {
      if (this.model.phone) {
        this.phoneData.phoneNumber = this.model.phone;
        this.phoneData.countryCode = this.model.countryCode;
      }
    },
    methods: {
      onWorkspaceChange() {
        this.phoneData = { ...this.model };
      },
      onUpdate(data) {
        this.hasListOpen = false;
        this.phoneData = { ...this.phoneData, ...data };
        const updatedModel = {
          ...this.model,
          phone: data.phoneNumber ? data.phoneNumber : "",
          countryCode: data.countryCode,
        };
        if (data.formatInternational) {
          updatedModel.countryCodeNo = parsePhoneNumberFromString(data.formatInternational).countryCallingCode;
        }
        if (this.customAttr) {
          this.$emit('updated-model', updatedModel, this.customId);
        } else {
          this.$emit('updated-model', updatedModel);
        }
      },
      clearPhoneNumberField() {
        this.phoneData.phoneNumber = "";
      },
    },
  };
</script>
