<template>
  <submit-request
    v-if="helpCenterModernDesignEnabled"
    :is-valid-workspace="isValidWorkspace"
    :help-center-workspaces="workspaces"
    :is-basic-user-helpdesk="isBasicUserHelpdesk"
  />
  <div v-else>
    <div
      class="row justify-content-center"
      :style="{'background-color': helpCenterPortalBGColor}"
    >
      <section
        v-if="form"
        class="pb-5 col-md-8 col-lg-6 mt-6"
      >
        <div class="box px-5">
          <div class="box__inner">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column jusify-items-center pl-0 mb-4">
                <div class="d-flex align-items-center mb-1 mb-1">
                  <img
                    src="~images/company-icon.svg"
                    class="company-select__icon mr-1"
                    alt="Company Icon"
                    height="16"
                    width="16"
                  >
                  <span class="not-as-small text-muted d-inline-block">Company:</span>
                  <span class="text-secondary font-weight-semi-bold not-as-small ml-1">{{ $companyName }}</span>
                </div>
                <div class="d-flex align-items-center mb-1">
                  <img
                    src="~images/workspaces-icon.svg"
                    class="workspace-header__label--image d-inline-block workspace-select__icon mr-1"
                    alt="Workspaces Icon"
                    height="16"
                    width="16"
                  >
                  <span class="not-as-small text-muted d-inline-block">Workspace:</span>
                  <span
                    class="text-secondary font-weight-semi-bold not-as-small ml-1"
                    :data-tc-view-select-space="workspace.name"
                  >
                    {{ workspace.name }}
                  </span>
                </div>
                <div class="d-flex align-items-center">
                  <img
                    src="~images/asset_manual_entry_icon.svg"
                    class="workspace-header__label--image d-inline-block form-select__icon mr-1 pl-0.5"
                    alt="Workspaces Icon"
                    height="16"
                    width="16"
                  >
                  <span class="not-as-small text-muted d-inline-block">Form:</span>
                  <span
                    class="text-secondary font-weight-semi-bold not-as-small ml-1"
                    :data-tc-view-selected-form="form.name"
                  >
                    {{ form.name }}
                  </span>
                </div>
              </div>
              <router-link
                v-if="showBack && workspace"
                :to="`/workspaces/${workspace.id}`"
                class="text-secondary mr-0"
                data-tc-back
              >
                <i class="nulodgicon-arrow-left-c white mr-2" />
                <span>Back to <strong>{{ backLabel }}</strong></span>
              </router-link>
            </div>

            <custom-form-viewer
              is-ticket-portal
              :form="form"
              :company-module="'helpdesk'"
              class="mt-3"
            />
          </div>
        </div>
      </section>
      <section
        v-else-if="optionsLoading"
        class="mx-auto pb-5 single-column-form-wrap mt-6"
      >
        <pulse-loader
          loading
          class="mt-3 mx-auto"
          color="#0d6efd"
          size="0.5rem"
        />
      </section>
      <section
        v-else
        class="mx-auto pb-5 single-column-form-wrap mt-5"
      >
        <div class="box px-5">
          <div class="box__inner">
            <router-link
              :to="resetPath"
              class="text-secondary mr-4 float-right"
              data-tc-back
            >
              <i class="nulodgicon-arrow-left-c white mr-2" />
              <span>Back to <strong>Ticket Portal</strong></span>
            </router-link>
            <div class="mt-5">
              <p>I'm sorry but this form is not available any longer.</p>
              <p class="not-as-small">
                In order to make a form available in your Help Center, log in and go to: <br> <span class="bg-light rounded">Help Desk</span> > <span class="bg-light rounded">Settings</span> > <span class="bg-light rounded">Custom Forms</span>.
              </p>
              <p class="not-as-small">
                Select a form, toggle 'Help Center' and check <span class="bg-light rounded">Allow this form in the Help Center Portal</span>. Save and voilà!
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import http from 'common/http';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import CustomFormViewer from "components/shared/custom_forms/custom_form_viewer.vue";
  import _get from 'lodash/get';
  import { ignoreErrors } from 'common/ignore_error';
  import strings from 'mixins/string';
  import permissionsHelper from 'mixins/permissions_helper';
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import SubmitRequest from './submit_request.vue';

  export default {
    components: {
      PulseLoader,
      CustomFormViewer,
      SubmitRequest,
    },
    mixins: [strings, permissionsHelper, helpCenterPortalHelper],
    props: {
      workspaces: {
        type: Array,
        default: () => ([]),
      },
      isBasicUserHelpdesk: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: null,
        requestEmail: "",
        formOptions: null,
        helpCenterPortalBGColor: null,
        optionsLoading: true,
        isValidWorkspace: false,
      };
    },
    computed: {
      ...mapGetters(['helpCenterModernDesignEnabled']),
      resetPath() {
        const workspaceId = _get(this, 'formOptions[0].workspace.id');
        if (workspaceId) {
          return `/workspaces/${workspaceId}`;
        } 
        return "/";
      },
      backLabel() {
        if (this.workspaces && this.workspaces.length === 1) {
          return "Forms";
        }
        return this.workspace.name;
      },
      showBack() {
        return !( this.workspaces &&
                  this.workspaces.length === 1 &&
                  this.formOptions &&
                  this.formOptions.length === 1 );
      },
      logoPresent() {
        return !!this.$companyLogoUrl && this.$companyLogoUrl.length > 0;
      },
      companyNamePresent() {
        return this.companyName && this.companyName.length > 0;
      },
      backgroundImageCss() {
        return {"background-image": `url("${this.$companyLogoUrl}")`};
      },
    },
    watch: {
      workspaces() {
        if (this.workspaces.length && this.isBasicUserHelpdesk) {
          this.setValues();
        }
      },
    },
    mounted() {
      this.setValues();
    },
    methods: {
      setValues() {
        if (this.helpCenterModernDesignEnabled) {
          const workspace = this.workspaces.find(w => w.id === parseInt(this.$route.params.workspace_id, 10));
          if (!workspace?.id) {
            this.form = null;
            this.optionsLoading = false;
            return;
          }
          this.isValidWorkspace = true;
        } else {
          const workspace = getWorkspaceFromStorage();
          this.fetchFormOptions();
          ignoreErrors();
          emitCompanyChange();
          if (workspace.id) {
            this.workspace = workspace;
          }
        }
      },
      fetchFormOptions() {
        const workspace = getWorkspaceFromStorage();
        const params = {
          company_module: 'helpdesk',
          portal: true,
          active: true,
        };
        this.optionsLoading = true;

        if (!workspace) {
          this.$router.push("/workspaces");
          return;
        }
        http
          .get(`/custom_form_options.json`, { params })
          .then((res) => {
            this.formOptions = res.data;
            const formId = parseInt(this.$route.params.id,10);
            let myForm = null;
            if (formId) {
              myForm = this.formOptions.find(f => f.id === formId);
            }
            if (myForm) {
              this.form = myForm;
              if (!workspace || !workspace.id) {
                this.workspace = this.form.workspace;
                setWorkspaceToStorage(this.form.workspace);
              }
              this.fetchBGColor();
            }
            this.optionsLoading = false;
          })
          .catch(() => {
            this.emitError('Sorry, there was an error in loading company info. Please try again later.');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .helpcenter-menu {
    padding-left: 5.5rem;
  }
</style>
