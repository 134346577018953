<template>
  <div>
    <div 
      v-for="(opt, index) in options"
      :key="index"
      v-tooltip.right="{
        content: opt,
        boundariesElement: 'body',
        show: (opt && opt.length > 20 && hoveredIndex == index),
        trigger: 'manual',
        classes: 'tooltip-width',
      }"
      class="d-flex"
      @mouseover="hoveredIndex = index"
      @mouseleave="hoveredIndex = null"
    >
      <input 
        :id="`radiobtn-${index}`"
        ref="input"
        v-model="valueStr"
        type="radio"
        :value="opt"
        @change="emitValue"
      >
      <label
        class="readable-length--x-small ml-1 mb-0 truncate"
        :for="`radiobtn-${index}`"
      >{{ opt }}</label>
    </div>
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span> 
  </div>
</template>

<script>
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';

export default {
  mixins: [fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
      default: null,
    }, 
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      valueStr: this.value,
      hoveredIndex: null,
    };
  },
  computed: {
    options() {
      if (typeof this.field.options === "string") {
        return JSON.parse(this.field.options);
      }
      return this.field.options;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.setValueStr();
    },
    emitValue() {
      this.errors = null;
      this.$emit("input", this.valueStr);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.valueStr || this.valueStr.length === 0) {
        msg = `Please enter a ${this.field.label}.`;
        this.$refs.input[this.$refs.input.length - 1].setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input[this.$refs.input.length - 1].setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>

<style>
.tooltip-width {
  width: 11.25rem;
}
</style>
