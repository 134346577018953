<template>
  <div>
    <sweet-modal
      ref="modal"
      v-sweet-esc
      modal-theme="right theme-wide"
      @close="closeArticlce"
      @open="verticalContainerClass"
    >
      <template slot="title">
        <div class="d-flex align-items-center">
          <img
            src="~/images/article.png"
            height="40"
            class="mr-2 ml-n3"
          >
          <h4 class="mb-0">Knowledge Base Article</h4>
        </div>
      </template>

      <template slot="box-action">
        <div
          class="sweet-action-close"
          @click="toggleFullscreen" 
        >
          <i :class="fullScreenIconClass"/>
        </div>
      </template>

      <div class="row">
        <div class="col-12">
          <article-item
            :article-obj="article"
            :truncate-title="false"
            :truncate-body="false"
          />
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue';
  import multiCompany from 'mixins/multi_company';
  import ArticleItem from "components/knowledge_base/article_help_center_portal.vue";
  import { mapMutations } from 'vuex';

  export default {
    components: {
      SweetModal,
      ArticleItem,
    },
    mixins:  [ multiCompany ],
    props: {
      article: {
        type: Object,
        default: Boolean,
      },
      isArticleSelected: {
        type: Boolean,
        default: false,
      },
    },
     data() {
      return {
        isFullscreen: false,
      };
    },
    computed: {
      modalTitle() {
        return this.article?.title || 'Article';
      },
      fullScreenIconClass() {
        return {
          'genuicon-resize-down': this.isFullscreen,
          'genuicon-expand': !this.isFullscreen,
        };
      },
    },
    methods: {
      ...mapMutations(['setHelpCenterSelectedArticle']),
      open() {
        this.$refs.modal.open();
        this.isFullscreen = false;
      },
      closeArticlce() {
        this.verticalContainerClass();
        if (!this.isArticleSelected) {
          this.setHelpCenterSelectedArticle(null);
        }
      },
      toggleFullscreen() {
        const {classList} = this.$refs.modal.$el;
        this.isFullscreen = !this.isFullscreen;
        if (this.isFullscreen) {
          classList.add('theme-full');
        } else {
          classList.remove('theme-full');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.sweet-modal) {
    .sweet-title {
      align-items: center;
      display: flex;
    }
  }
</style>
