<template>
  <div class="dropdown d-inline-block align-middle">
    <div>
      <input
        ref="input"
        type="hidden"
      >
      <a
        href="#"
        class="dropdown-toggle btn btn-sm btn-text text-secondary p-0 pl-1 d-flex align-items-center"
        data-tc-ticket-status-dropdown
        @click.stop.prevent="toggleStatusDropdown"
      >
        <span
          v-if="value"
          class="readable-length--xx-small truncate"
        >
          <i
            v-tooltip="statusTooltip"
            class="status-icon mr-1"
            :style="{background: color}"
          />
          {{ value }}
        </span>
        <span
          v-else
          class="readable-length--xx-small truncate"
        >
          Select Status
        </span>
        <i class="nulodgicon nulodgicon-arrow-down" />
      </a>
      <div
        v-if="field.options"
        class="dropdown-menu not-as-small row dissmissible-menu"
        :class="{ hidden: !showstatusDropdown }"
        data-tc-status-options
      >
        <a
          v-for="(option, index) in field.options"
          :key="index"
          v-tooltip.left="{
            content: option.name,
            boundariesElement: 'body',
            show: (option.name && option.name.length > 40 && hoveredIndex == index),
            trigger: 'manual',
          }"
          href="#"
          class="dropdown-item clickable pl-3  truncate"
          data-tc-status-select-option
          @mouseover="hoveredIndex = index"
          @mouseleave="hoveredIndex = null"
          @click.stop.prevent="statusChanged(option.name)"
        >
          <i
            class="status-icon mr-1"
            :style="{background: option.color}"
          />
          {{ option.name }}
        </a>
      </div>
    </div>
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
  import strings from 'mixins/string';
  import customForms from 'mixins/custom_forms';
  import fieldViewerInput from 'mixins/field_viewer_input';
  import permissionsHelper from 'mixins/permissions_helper';
  import customFormHelper from "mixins/custom_form_helper.js";

  export default {
    mixins: [strings, customForms, fieldViewerInput, permissionsHelper, customFormHelper],
    props: {
      field: {
        type: Object,
        default: () => {},
      },
      value: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showstatusDropdown: false,
        errors: null,
        hoveredIndex: null,
      };
    },
    computed: {
      statusTooltip() {
        if (this.value) {
          return `${this.value} Status`;
        }
        return "";
      },
      color() {
        if (this.value) {
          const option = this.field.options?.find(obj => obj.name === this.value);
          if (option) {
            return option.color;
          }
        }
        return "";
      },
    },
    methods: {
      onWorkspaceChange() {
        if (!this.value && this.isStatusField()) { 
          this.$emit("input", this.field.options[0].name);
        } else {
          this.$emit("input", this.value);
        }
      },
      toggleStatusDropdown() {
        this.showstatusDropdown = !this.showstatusDropdown;
      },
      statusChanged(status) {
        this.toggleStatusDropdown();
        this.valueStr = status;
        this.errors = null;
        this.$emit("input", status);
      },
      showErrors() {
        let msg = null;
        if (!this.required && this.moduleName !== "automatedTasks") {
          return false;
        }
        if (!this.value || this.value.length === 0) {
          msg = `Select a status`;
          this.$refs.input.setCustomValidity(msg);
          this.errors = msg;
        } else {
          this.$refs.input.setCustomValidity('');
          this.errors = null;
        }
        return !this.errors;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dropdown-toggle {
    margin-top: -10px;
  }

  .dropdown-menu {
    display: block;
    left: 0;
    right: auto;
  }
  
  .dropdown-item {
    max-width: 25rem;
  }
</style>
