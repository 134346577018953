<template>
  <div
    class="d-inline-flex align-items-center position-relative"
    data-tc-btn="parent"
  >
    <button
      :disabled="disabledButton"
      class="btn btn-primary"
      :class="[btnClasses, conditionalClass]"
      :data-tc-save-form-btn="btnContent"
      @click.prevent.stop="$emit('submit')"
    >
      <i
        v-if="displayIcon == 'checkmark'"
        class="nulodgicon-checkmark mr-1"
      />
      <i
        v-if="displayIcon == 'copy'"
        class="genuicon-duplicate-contract mr-1"
      />
      <span :class="{ 'mr-4': isSaving }"> {{ isSaving ? savingContent : btnContent }} </span>
    </button>
    <clip-loader
      v-if="displayLoader"
      :loading="isSaving"
      class="mt-1 position-absolute v-spinner-right"
      color="#0d6efd"
      size="1.1875rem"
    />
  </div>
</template>

<script>
  import ClipLoader from "./clip_loader_smooth.vue";

  export default {
    components: {
      ClipLoader,
    },
    props: {
      isValidated: {
        type: Boolean,
        default: true,
      },
      isSaving: {
        type: Boolean,
        default: false,
      },
      savingContent: {
        type: String,
      },
      btnContent: {
        type: String,
      },
      btnClasses: {
        type: String,
        default: "",
      },
      conditionalClass: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      displayIcon: {
        type: String,
        default: '',
      },
      displayLoader: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      disabledButton() {
        return this.isValidated || !this.disabled ? this.isSaving : true;
      },
    },
  };
</script>
