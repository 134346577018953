<template>
  <div>
    <div class="col-6 pl-0">
      <image-input
        as-file
        :src="imageSrc"
        :is-loaded="!isUploading"
        @input="addFile"
      />
    </div>
    <input
      ref="input"
      type="text"
      class="hidden"
      name="attachment"
      :required="required"
      :value="values"
    >
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
  import http from 'common/http';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import ImageInput from 'components/shared/image_input.vue';
  import fieldViewerInput from 'mixins/field_viewer_input';
  import attachment_helper from "mixins/attachment_helper.js";
  import permissionsHelper from 'mixins/permissions_helper';
  import _get from 'lodash/get';

  export default {
    components: {
      ImageInput,
      ClipLoader,
    },
    mixins: [fieldViewerInput, attachment_helper, permissionsHelper],
    props: ['field', 'value'],
    data() {
      return {
        isUploading: false,
        errors: null,
        imgSrc: null,
        values: [],
      }
    },
    computed: {
      imageSrc() {
        return this.imgSrc;
      }
    },
    methods: {
      onWorkspaceChange() {
        this.setAvatar();
      },
      uploadFile(file) {
        let serializedForm = new FormData();
        if (file) {
          serializedForm.append('custom_form_attachment_attribute[attachment]', file, file.filename);
        }
        this.isUploading = true;
        this.updateUploadingStatus(true);
        http
          .post(`/custom_form_attachments.json`, serializedForm)
          .then((res) => {
            this.values = [];
            this.values.push(res.data.file);
            this.field.files = this.values;
            this.$emit("input", this.values);
          })
          .catch((error) => {
            this.imgSrc = null;
            let errorMessage = 'Sorry, there was an error uploading this image.';
            if (error.response?.data?.message) {
              errorMessage = `${errorMessage} ${error.response.data.message}`;
            }
            this.emitError(errorMessage);
          })
          .finally(() => {
            this.isUploading = false;
            this.updateUploadingStatus(false);
          });
      },
      addFile(url, name, file) {
        if (url && name && file) {
          if (file.size < 15000000) {
            this.imgSrc = url;
            this.uploadFile(file);
          } else {
            this.emitError('Sorry, the image is too large. Please upload image under 15MB.');
          }
        } else if (_get(this, 'values[0].id', false)) {
          this.delete(this.values[0].id);
        }
      },
      delete(fileId) {
        this.updateUploadingStatus(true);
        http
          .delete(`/custom_form_attachments/${fileId}.json`)
          .then((res)=> {
            this.imgSrc = null;
            this.values = [];
            this.field.files = this.values;
            this.$emit("input", this.values);
            this.updateUploadingStatus(false);
          })
          .catch(error => {
            this.emitError(`Sorry, there was an error deleting this image.`);
          });
      },
      showErrors() {
        let msg = null;
        if (!this.required && this.moduleName != "automatedTasks") {
          return false;
        }
        if (!this.values.length) {
          msg = `Please upload an image`;
          this.$refs.input.setCustomValidity(msg);
          this.errors = msg;
        } else {
          this.$refs.input.setCustomValidity('');
          this.errors = null;
        }
        return !this.errors;
      },
      updateUploadingStatus(status) {
        this.errors = null;
        this.$emit('attachment-uploading-status', status);
      },
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep .v-sync {
    background-color: #8a9197 !important;
    height: 0.3rem !important;
    width: 0.3rem !important;
  }
</style>
