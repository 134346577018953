<template>
  <div>
    <subscription-modal v-if="$freeTrialEndedAndUnsubscribed || !$canAccessHelpCenter"/>
    <help-center-header
      :selected-workspace="selectedWorkspaceItem"
      :is-submit-request-route-disabled="hasNoFormInWorkspace"
      @header-loaded="onHeaderLoaded"
    >
      <div class="position-relative hc-text-header">
        <h1 class="mt-5 font-weight-bold help-center-title">
          How can we help, today?
        </h1>
        <div 
          v-if="workspaces && workspaces.length > 1 && selectedWorkspace"
          class="mx-auto help-center-custom-pills-container d-flex flex-wrap justify-content-center"
        >
          <label
            v-for="workspace in workspaces"
            :key="workspace.id"
            class="py-2 px-3 mx-2 cursor-pointer help-center-custom-pill truncate"
            :class="[isSelectedWorkspace(workspace) ? 'hc-bg-button' : 'bg-white text-dark']"
            @click="selectWorkspace(workspace)"
          >
            {{ workspace.name }}
          </label>
        </div>
        <search-bar
          class="mb-3 mt-4"
          faq-search-enabled
          article-search-enabled
          company-wide-search
          suggested-text="Search our knowledge base articles and FAQs..."
        />
        <div 
          class="not-as-big mb-6"
          :class="{ 'text-muted': hasNoFormInWorkspace }"
          :style="{ visibility: hasNoFormInWorkspace ? 'hidden' : 'visible' }"
        >
          Not finding the help you need?
          <router-link
            class="open-ticket-link hc-text-header hc-text-header--hover ml-1 "
            :to="helpCenterSubmitRequestRoute"
          >
            Open a Help Desk Request
          </router-link>
        </div>
      </div>
    </help-center-header>
    <div v-if="headerLoaded">
      <div class="pb-7 readable-length--large mx-auto text-center">
        <div class="row">
          <action-tile
            image="request"
            :path="helpCenterSubmitRequestRoute"
            :is-disabled="hasNoFormInWorkspace"
            :selected-workspace-name="selectedWorkspaceItem.name"
            title="Report an Issue"
            description="Open a help desk request for any acute issue you're facing."
          />
          <action-tile
            image="article"
            :path="helpCenterArticlesRoute"
            title="Knowledge Base"
            description="Check our internal guides for detailed help and info."
          />
          <action-tile
            image="faq"
            :path="helpCenterFaqsRoute"
            title="FAQs"
            description="Quickly find answers to questions we've all asked."
          />
        </div>
      </div>
      <div v-if="!loadingRecentItems">
        <div class="readable-length--large mx-auto pb-4 pt-6">
          <div class="row">
            <div class="col-3">
              <h4 class="font-weight-bold">Recent Knowledge Base Articles</h4>
            </div>
            <div class="col-9">
              <div
                v-if="recentArticles && recentArticles.length > 0"
                class="row ml-0 mr-n4"
              >
                <div 
                  v-for="article in recentArticles"
                  :key="article.id"
                  class="col-6 pl-0 pr-4 mb-4"
                >
                  <div 
                    class="box box--flat bg-themed-footer-bg box--with-hover align-items-start pb-4"
                    @click="selectArticle(article)"
                  >
                    <article-item-grid
                      :article-obj="article"
                      hide-admin-elements
                      class="w-100"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <p class="font-weight-semi-bold">No Articles to show.</p>
              </div>
              <div 
                v-if="recentArticles && recentArticles.length > 0"
                class="text-right"
              >
                <router-link 
                  class="btn btn-link hc-text-links"
                  :to="helpCenterArticlesRoute"
                >
                  See all articles
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <hr class="mx-auto my-0 readable-length--large">
        <div class="pt-6 pb-7 mb-7 readable-length--large mx-auto">
          <div class="row">
            <div class="col-3 pr-5">
              <h4 class="font-weight-bold">Recent FAQs</h4>
            </div>
            <div class="col-9">
              <div 
                v-if="recentFaqs && recentFaqs.length > 0"
                class="row"
              >
                <div
                  v-for="faq in recentFaqs" 
                  :key="faq.id"
                  class="faq-preview col-6 mb-5"
                >
                  <!-- eslint-disable vue/no-v-html -->
                  <a
                    class="text-underline"
                    @click="selectFaq(faq)"
                    v-html="sanitizeHTML(faq.questionBody)"
                  />
                </div>
              </div>
              <div v-else>
                <p class="font-weight-semi-bold">No FAQs to show.</p>
              </div>
              <div 
                v-if="recentFaqs && recentFaqs.length > 0"
                class="text-right"
              >
                <router-link
                  :to="helpCenterFaqsRoute"
                  class="btn btn-link hc-text-links"
                >
                  See all FAQs
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <pulse-loader
        v-else
        size="0.5rem"
        :color="helpCenterHyperlinksColor"
        :loading="true"
      />
    </div>

    <faq-modal
      ref="faqShowModal"
      :faq="helpCenterSelectedFaq"
    />

    <article-modal
      ref="articleShowModal"
      :article="helpCenterSelectedArticle"
      :is-article-selected="isArticleSelected"
    />

    <notifications position="bottom right" />
  </div>
</template>

<script>
  import http from 'common/http';
  import strings from 'mixins/string';
  import permissionsHelper from 'mixins/permissions_helper';
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import { mapMutations, mapGetters, mapActions} from 'vuex';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import customForms  from 'mixins/options/custom_forms';
  import FaqModal from '../help_tickets/faqs/faq_modal.vue';
  import ArticleItemGrid from "../knowledge_base/article_item_grid.vue";
  import ArticleModal from './article_modal.vue';
  import ActionTile from './action_tile.vue';
  import HelpCenterHeader from './help_center_header.vue';
  import SearchBar from './search_bar.vue';
  import SubscriptionModal from './subscription_modal.vue';

  export default {
    components: {
      FaqModal,
      ArticleModal,
      ArticleItemGrid,
      ActionTile,
      HelpCenterHeader,
      SearchBar,
      PulseLoader,
      SubscriptionModal,
    },
    mixins: [strings, permissionsHelper, helpCenterPortalHelper, customForms],
    props: ['workspaces'],
    data() {
      return {
        searchString: '',
        recentFaqs:[],
        recentArticles: [],
        loadingRecentItems: false,
        isWorkspaceChanged: false,
        isArticleSelected: true,
        headerLoaded: false,
        selectedWorkspace: getWorkspaceFromStorage(),
      };
    },
    computed: {
      ...mapGetters([
        'publicArticles',
        'faqs',
        'loading',
        'helpCenterHyperlinksColor',
        'helpCenterSelectedFaq',
        'helpCenterSelectedArticle',
      ]),
      helpCenterFaqsRoute() {
        return `/faqs/workspaces/${this.selectedWorkspace?.id}`;
      },
      helpCenterArticlesRoute() {
        return `/knowledge_base/workspaces/${this.selectedWorkspace?.id}`;
      },
    },
    watch: {
      helpCenterSelectedArticle() {
        this.openArticleModal();
      },
      helpCenterSelectedFaq() {
        this.openFaqModal();
      },
    },
    mounted() {
      this.fetchRecentItems();
      this.isWorkspaceChanged = true;
    },
    methods: {
      ...mapActions([
        'fetchPublicArticles', 
        'fetchHelpCenterColors',
      ]),
      ...mapMutations([
        'setPublicArticlesPage',
        'setPublicArticlesPageCount',
        'setHelpCenterSelectedFaq',
        'setHelpCenterSelectedArticle',
      ]),
      onHeaderLoaded() {
        this.headerLoaded = true;
      },
      fetchRecentFaqs() {
        this.loadingRecentItems = true;
        const params = { recent_faqs: true , help_center: true };
        http
        .get(`/faqs.json`, { params })
        .then((res) => {
          this.recentFaqs = res.data.questions;
          this.loadingRecentItems = false;
        })
        .catch((error) => {
          this.loadingRecentItems = false;
          this.emitError(`Sorry, there was an error getting recent FAQs. ${error.response.data.message}.`);
        });
      },
      fetchRecentArticles() {
        this.loadingRecentItems = true;
        const params = { recent_articles: true };
        http
        .get(`/knowledge_base.json`, { params })
        .then((res) => {
          this.recentArticles = res.data.articles;
          this.loadingRecentItems = false;
        })
        .catch((error) => {
          this.loadingRecentItems = false;
          this.emitError(`Sorry, there was an error getting recent Articles. ${error.response.data.message}.`);
        });
      },
      selectFaq(faq) {
        this.setHelpCenterSelectedFaq(faq);
      },
      selectArticle(article) {
        this.setHelpCenterSelectedArticle(article);
      },
      openFaqModal() {
        this.$refs.faqShowModal.open();
      },
      openArticleModal() {
        this.$refs.articleShowModal.open();
      },
      selectWorkspace(workspace) {
        if (workspace.id !== this.selectedWorkspace.id) {
          setWorkspaceToStorage(workspace);
          this.selectedWorkspace = workspace;
          this.$router.push(`/workspaces/${workspace.id}`);
          this.fetchHelpCenterColors().then(() => {
            this.fetchRecentItems();
          });
        }
      },
      fetchRecentItems() {
        if (!this.$freeTrialEndedAndUnsubscribed && this.$canAccessHelpCenter) {
          this.fetchRecentArticles();
          this.fetchRecentFaqs();
        }
      },
      isSelectedWorkspace(workspace) {
        return workspace.id === this.selectedWorkspace.id;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .open-ticket-link {
    padding: 0.5rem 0.25rem;
    position: relative;
    
    &:after {
      background-color: var(--hc-header-text, white);
      border-radius: 1px;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0.25rem;
      position: absolute;
      transition: $transition-base;
      width: calc(100% - 0.5rem);
    }

    &:hover:after {
      background-color: var(--hc-button-bg, $primary);
    }
  }
</style>
